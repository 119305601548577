import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputSelect, InputSwitch, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseView from '../base/base-view.component';
import Model from './coa-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class CoaCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private historyState: any = this.props.history.location.state;

    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };
    
    private getDataRowParent = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'parent': data?.id || null, 'parentCaption': data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..       
            <>     
            {this.historyState?.id>0 &&
            <BaseView history={this.props.history} caption=''
                model={Model} getDataRow={this.getDataRowParent}/>
            }              
            <BaseCreate history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange}>
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                }
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} error={this.state.dataError?.type} onChange={this.onChange} readOnly={this.state.dataRow.parent? true : false}/>
                {this.historyState?.id>0 &&
                <Input name='parentCaption' label='Parent' value={this.state.dataRow.parentCaption} readOnly/> 
                }            
            </BaseCreate>
            </>
        );
    }     
     
}