import * as React from 'react';
import { History } from 'history';
import Model from './questionoption-model';
import BaseChecklistCard from '../base/base-checklistcard.component';

interface IProps { 
  history: History;
  caption?: string;
  questionId: number;
  readOnly?: boolean
  currentId?: any;
  getDataRow: (data: any) => void;
  cardCount?: number;
  cardBorderRadius?: number;
  cardAlign?: 'left'|'center'|'right';
}

interface IState {
}

export default class QuestionoptionChecklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render(): React.ReactNode {
    return (
      <BaseChecklistCard history={this.props.history} caption={this.props.caption} endPointFilter={Model.endPointFilter+this.props.questionId} model={Model} readOnly={this.props.readOnly} currentId={this.props.currentId} getDataRow={this.props.getDataRow} cardCount={this.props.cardCount} cardBorderRadius={this.props.cardBorderRadius} cardBoxShadow={5} cardAlign={this.props.cardAlign} cardTextAlign='center' disableBackButton/>
    );
  }
}
