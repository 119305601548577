import * as React from 'react';
import { CircleNumber, Text, TextToHtml } from '.';

interface Props {
  id?: string;
  data: any;
  start?: number;
  size?: number;
  spacing?: number;
  textAlign?: 'left'|'center'|'right'|'justify'
  disableNumber?: boolean;
}

export const Numbering: React.FunctionComponent<Props> = (props) => {
  
  return (
    <>
      {props.data?.map((d: any, i: number) => {
        var label = !props.disableNumber ? undefined : ((props.start||1)+i)
        return (
            <Text key={i} textAlign={props.textAlign} sx={{display: 'flex', marginBottom: props.spacing || 2}}>
                <CircleNumber color='primary.light' size={props.size} label={label}/>
                <TextToHtml content={d}/>
            </Text>
        )
      })}
    </>
  );
  
};
