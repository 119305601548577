import OwnerModel from '../owner/owner-model';

export default class TransactiontypeModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    type: string;
    typeCaption: string;
    pricetype: string;
    reportgroup: any;
    reportgroupCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.type = dataRow.attributes.type || 'general';
        this.typeCaption = TransactiontypeModel.optionType.filter((d: any) => d.name === this.type)?.[0]?.caption || 'General';
        this.pricetype = dataRow.attributes.pricetype || 'pricebase';
        this.reportgroup = dataRow.attributes.reportgroup?.data?.id || null;
        this.reportgroupCaption = dataRow.attributes.reportgroup?.data?.attributes.name || dataRow.attributes.reportgroup?.data?.id;
        
    } 

    public static endPoint: string = 'transaction-types';
    public static endPointFilter = (type?: string)=>{
       const owner = OwnerModel.getOwner();
        return ( '?populate=*&sort=name&filters[owner]='+owner+(type? '&filters[type]='+type : ''));
    } 
    public static url: string = '/transactiontype';
    public static caption: string = 'Transaction Type';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),         
        type: 'general',
        typeCaption: 'General',
        pricetype: 'pricebase',
        reportgroup: null,
        reportgroupCaption: '',
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionType = [
        { name: 'general', caption: 'General' },
        { name: 'purchase', caption: 'Purchase' },
        { name: 'sales', caption: 'Sales' },
    ]

    public static optionPricetype = [
        { name: 'pricebase', caption: 'Price Base' },
        { name: 'pricesale', caption: 'Price Sale' },
    ]
}