import ApiService from '../../services/api.service';
import OwnerModel from '../owner/owner-model';

export default class ProductModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    producttype: any;
    producttypeCaption: string;
    producttypeType: string;
    unit: string;
    qty: number;
    qtymin: number;
    qtymax: number;
    pricebase: number;
    pricesale: number;
    margin: number;
    totalamount: number;
    image: any;
    imageId: number;
    imageUrl: string;    
    product: any;
    productCaption: string;
    amounttype: any;
    amounttypeCaption: string;
    journalgroup: any;
    journalgroupCaption: string;
    event: any;
    eventCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.producttype = dataRow.attributes.producttype?.data?.id || null;
        this.producttypeCaption = dataRow.attributes.producttype?.data?.attributes.name || dataRow.attributes.producttype?.data?.id;
        this.producttypeType = dataRow.attributes.producttype?.data?.attributes.type || '';
        this.unit = dataRow.attributes.unit || '';
        this.qty = dataRow.attributes.qty || 0;
        this.qtymin = dataRow.attributes.qtymin || 0;
        this.qtymax = dataRow.attributes.qtymax || 0;
        this.pricebase = dataRow.attributes.pricebase || 0;
        this.pricesale = dataRow.attributes.pricesale || 0;
        this.margin = dataRow.attributes.margin || 0;
        this.totalamount = dataRow.attributes.totalamount || 0;
        this.image = dataRow.attributes.image?.data?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ((this.image.attributes.url.includes('http')? '' : ApiService.svrUrl)+this.image.attributes.url) : '';
        this.product = dataRow.attributes.product?.data || null;
        this.productCaption = this.product?.length===0? '' : this.product?.[0]?.attributes.name + (this.product?.length>1? ', ...': '');  
        this.amounttype = dataRow.attributes.amounttype?.data || null;
        this.amounttypeCaption = this.amounttype?.length===0? '' : this.amounttype?.[0]?.attributes.name + (this.amounttype?.length>1? ', ...': '');  
        this.journalgroup = dataRow.attributes.journalgroup?.data?.id || null;
        this.journalgroupCaption = dataRow.attributes.journalgroup?.data?.attributes.name || dataRow.attributes.journalgroup?.data?.id;
        this.event = dataRow.attributes.event?.data?.id || null;
        this.eventCaption = dataRow.attributes.event?.data?.attributes.name || dataRow.attributes.event?.data?.id;
    } 

    public static endPoint: string = 'products' 
    public static endPointMedia: string = 'api::product.product';
    public static endPointFilter = (parentId?: number, producttypeType?: string, productgroupId?: number)=>{
       const owner = OwnerModel.getOwner();
       return ( '?populate=*&sort=name&filters[owner]='+owner+ (parentId? '&filters[parent]='+parentId : '')+ (producttypeType? '&filters[producttype][type]='+producttypeType : '') + (productgroupId? '&filters[productgroup]='+productgroupId : ''));
    } 
    public static url: string = '/product';
    public static caption: string = 'Product';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),         
        producttype: null,
        producttypeCaption: '',
        producttypeType: '',
        unit: '',
        qty: 0,
        qtymin: 0,
        qtymax: 0,
        pricebase: 0,
        pricesale: 0,
        margin: 0,
        totalamount: 0,
        image: null,
        imageId: 0,
        imageUrl: '',
        product: [],
        productCaption: '',   
        amounttype: [],
        amounttypeCaption: '',
        journalgroup: null,
        journalgroupCaption: '',
        event: null,
        eventCaption: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
        { name: 'pricebase', caption: 'Price Base', type: 'money' },
        { name: 'pricesale', caption: 'Price Sale', type: 'money' },
        { name: 'qty', caption: 'Qty', type: 'number' },
    ]

    public static columnPicklistCard = [
        { name: 'imageUrl', caption: ' ', type: 'media' },
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
        { name: 'pricebase', caption: 'Price Base', type: 'money' },
        { name: 'pricesale', caption: 'Price Sale', type: 'money' },
    ]

}