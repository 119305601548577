import * as React from 'react';
import { Grid, SwipeableDrawer } from '@mui/material';

interface Props {
  id?: string;
  variant?: 'bottom' | 'top';
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

export const SwipeDrawer: React.FunctionComponent<Props> = (props) => {
  const [open, setOpen] = React.useState(props.open);
  
  const onClose = () => {    
    setOpen(false)
    props.onClose?.();
  };

  const onOpen = () => {    
  };
  
  const  puller = () =>{
    return <Grid alignSelf='center' height={5} width={50} bgcolor='silver' borderRadius={50} m={2}/>
  }

  React.useEffect(()=>{
    setOpen(props.open)
  }, [props])

  return (
    <SwipeableDrawer
      id={props.id}
      PaperProps={{
        sx: {
          borderTopLeftRadius: props.variant==='bottom'? 20 : 0,
          borderTopRightRadius: props.variant==='bottom'? 20 : 0,
          borderBottomLeftRadius: props.variant==='top'? 20 : 0,
          borderBottomRightRadius: props.variant==='top'? 20 : 0
        }
      }}
      anchor={props.variant}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      {props.variant==='bottom'? puller():''}
      <Grid maxHeight={500} overflow='auto' ml={2} mr={2}> {props.children} </Grid>
      {props.variant==='top'? puller():''}
    </SwipeableDrawer>
      
  )
};



