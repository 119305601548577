import React from 'react';
import { History } from 'history';
import { InputMoney, InputMultiline, Picklist } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './transactionpayment-model';  //editable..
import PaymenttypePicklist from '../paymenttype/paymenttype-picklist.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactionpaymentEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        
        this.onChange = this.onChange.bind(this);
    }
    
    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowPaymenttype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'paymenttype': data?.id || null, 'paymenttypeCaption': data?.name || '', 'value': data.defaultvalue }  });
    };


    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Picklist name='paymenttype' label='Type' value={this.state.dataRow.paymenttype} caption={this.state.dataRow.paymenttypeCaption} error={this.state.dataError?.paymenttype} onChange={this.onChange} >
                    <PaymenttypePicklist currentId={this.state.dataRow.paymenttype} getDataRow={this.getDataRowPaymenttype}/>
                </Picklist>                 
                <InputMoney name='value' label='Payment' value={this.state.dataRow.value} error={this.state.dataError?.value} onChange={this.onChange} />
                <InputMultiline name='note' label='Note' value={this.state.dataRow.note} error={this.state.dataError?.note} onChange={this.onChange} />
                      
            </BaseEdit>
        );
    }
}