import OwnerModel from '../owner/owner-model';
import UserModel from '../user/user-model';

export default class EventsectionModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    parent: any;
    parentCaption: string;
    eventsectionLength: number; //eventsection section length
    questionLength: number; 
    event: any; 
    eventLength: number; 
    eventLengthEnddate: number; 
    eventEnddate: string;
    eventquestion: any;
    eventquestionLength: number; 
    eventquestionLengthAnswered: number;
    title: string;
    content: string;
    seq: number;
    viewstyle: string;
    timelimit: number;
    questiontolerance: number;
    tag: string;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.parent = dataRow.attributes.parent?.data?.id || null;
        this.parentCaption = dataRow.attributes.parent?.data?.attributes.name || dataRow.attributes.parent?.data?.id;
        this.eventsectionLength = dataRow.attributes.eventsection?.data?.length || 0;
        this.questionLength = dataRow.attributes.question?.data?.length || 0;
        this.event = dataRow.attributes.event?.data;
        this.eventLength = this.event?.[0]?.attributes.event?.data?.length || 0;
        this.eventLengthEnddate = this.event?.[0]?.attributes.event?.data?.filter((d: any) => d.attributes.enddate !== null).length|| 0;
        this.eventEnddate = this.event?.[0]?.attributes.enddate || null;
        this.eventquestion = this.event?.[0]?.attributes.eventquestion?.data || null;
        this.eventquestionLength = this.eventquestion?.length || 0;
        this.eventquestionLengthAnswered =  this.eventquestion?.filter((d: any) => d.attributes.answer || d.attributes.questionoption).length|| 0;
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.seq = dataRow.attributes.seq;
        this.viewstyle = dataRow.attributes.viewstyle;
        this.timelimit = dataRow.attributes.timelimit;
        this.questiontolerance = dataRow.attributes.questiontolerance;
        this.tag = dataRow.attributes.tag;
    } 

    public static endPoint: string = 'event-sections' 
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&sort=name&filters[owner]='+owner+'&filters[parent][id][$null]=true');
    } 
    public static endPointFilter2: string = '?populate=*&sort=name&filters[parent]=';
    public static endPointFilter3: string = '?populate=*&sort=seq&filters[event]=';
    public static endPointFilter4 = (eventId: number) => {
        const user = UserModel.getUser();
        return ('?populate[owner][sort]=id'+
                '&populate[parent][sort]=id'+
                '&populate[product][sort]=id'+
                '&populate[question][filters][seq][$gte]=0'+
                '&populate[eventsection][sort]=id'+
                '&populate[event][populate]=eventquestion'+
                '&populate[event][filters][participant][user]='+user+
                '&populate[event][filters][parent][id]='+eventId+
                '&filters[parent]='
        );
    }
    public static endPointFilter5 = (eventId: number) => {
        const user = UserModel.getUser();
        return ('?populate[owner][sort]=id'+
                '&populate[parent][sort]=id'+
                '&populate[product][sort]=id'+
                '&populate[question][filters][seq][$gte]=0'+    
                '&populate[eventsection][filters][question][seq][$gt]=0'+
                '&populate[event][populate][parent][sort]=id'+
                '&populate[event][populate][eventquestion][sort]=id'+
                '&populate[event][populate][event][filters][eventquestion][id][$null]=false'+
                '&populate[event][filters][participant][user]='+user+
                '&populate[event][filters][parent][id]='+eventId+
                '&sort=seq'+
                '&filters[event]='
        );
    }
    public static url: string = '/eventsection';
    public static caption: string = 'Event Section';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        parent: null,
        parentCaption: '',
        eventsectionLength: 0,
        questionLength: 0,
        event: null,
        eventLength: 0,
        eventLengthEnddate: 0,
        eventEnddate: '',
        eventquestion: [],
        eventquestionLength: 0,
        eventquestionLengthAnswered: 0,
        title: '',
        content: '',
        seq: 0,
        viewstyle: 'onebyone',
        timelimit: 0,
        questiontolerance: 0,
        tag: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'title', caption: 'Title' },
    ]
    
    public static optionViewstyle = [
        { name: 'onebyone', caption: 'Show one by one' },
        { name: 'all', caption: 'Show all' },
        { name: 'onebyone-canback', caption: 'Show one by one - Can back' },
        { name: 'all-canback', caption: 'Show all - Can back' },
        { name: 'onebyone-canback-editable', caption: 'Show one by one - Can back - Editable' },
        { name: 'all-canback-editable', caption: 'Show all - Can back - Editable' },
    ]

}