import React from 'react';
import BaseView from './base-view.component';
import BaseCreate from './base-create.component';
import BaseEdit from './base-edit.component';
import BaseDelete from './base-delete.component';

interface IProps { 
    history?: any;
    id?: number;
    caption?: string;
    captionColor?: string;
    buttonCaption?: string;
    captionThin?: boolean;
    endPoint?: string;
    endPointFilter?: string;
    model: any;
    dataRow: any;
    validations?: any;
    getDataRow?: (data: any) => void;
    getDataError?: (data: any) => void;
    onSaveBefore?: () => void;
    onSave?: (data: any) => void;
    save?: boolean;
    delete?: boolean;
    buttonOnBottom?: boolean;
    disableAutoBack?: boolean;
    disableBackButton?: boolean;
    disableCard?: boolean;
    children?: React.ReactNode;
}


interface IState {
    dataRow: any;
    dataError: any;
}

export default class BaseCreateEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: this.props.model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.props.getDataRow?.(data);
        if (data) this.setState({dataRow: data});
    }

    private getDataError = (data: any) => {
        this.props.getDataError?.(data);
        this.setState({dataError: data});
    }

    private onSaveBefore = () => {
        this.props.onSaveBefore?.();
    }

    private onSave = (data:any) => {
        this.props.onSave?.(data);
        this.setState({ dataRow: {id: data?.id}})
    }

    private onDelete = (data: any) => {
        this.setState({ dataRow: {...this.state.dataRow, id: 0}})
    };

    render() {

        return (
        <BaseView history={this.props.history} id={this.props.id} caption={this.props.caption} captionColor={this.props.captionColor} captionThin={this.props.captionThin}
            endPoint={this.props.endPoint} endPointFilter={this.props.endPointFilter} model={this.props.model} getDataRow={this.getDataRow} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>
            
            {!this.state.dataRow.id?
            <BaseCreate caption=' ' buttonCaption={this.props.buttonCaption}
                model={this.props.model} dataRow={this.props.dataRow}validations={this.props.validations} save={this.props.save} onSaveBefore={this.onSaveBefore} onSave={this.onSave} buttonOnBottom={this.props.buttonOnBottom} disableAutoBack={this.props.disableAutoBack} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableBackButton}>
                {this.props.children}
            </BaseCreate>
            :
            <BaseEdit id={this.state.dataRow.id} caption=' ' buttonCaption={this.props.buttonCaption}
                model={this.props.model} dataRow={this.props.dataRow} validations={this.props.validations} save={this.props.save} getDataRow={this.getDataRow} getDataError={this.getDataError} onSave={this.onSave} buttonOnBottom={this.props.buttonOnBottom} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableBackButton}>                
                {this.props.children}
            </BaseEdit>
            }
            
            <BaseDelete id={this.state.dataRow.id} caption=' ' model={this.props.model} delete={this.state.dataRow.id>0 && this.props.delete} onDelete={this.onDelete} disableAutoBack disableBackButton />

        </BaseView>
        );
    }
}