import OwnerModel from '../owner/owner-model';

export default class CoaModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    type: string;
    typeCaption: string;
    owner: any;
    ownerCaption: string;
    parent: any;
    parentCaption: string;
    coabalancevalue: number;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.type = dataRow.attributes.type || 'asset';
        this.typeCaption = CoaModel.optionType.filter((d: any) => d.name === this.type)?.[0]?.caption || 'Asset';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id; 
        this.parent = dataRow.attributes.parent?.data?.id || null;
        this.parentCaption = dataRow.attributes.parent?.data?.attributes.name || dataRow.attributes.parent?.data?.id; 
        this.coabalancevalue = dataRow.attributes.coabalance?.data[dataRow.attributes.coabalance?.data?.length-1]?.attributes.value || 0;
        
    } 

    public static endPoint: string = 'coas';
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&sort=name&filters[owner]='+owner+'&filters[parent][id][$null]=true');
    } 
    public static endPointFilter2: string = '?populate=*&sort=name&filters[parent]=';
    public static url: string = '/coa';
    public static caption: string = 'Chart of Account';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        type: 'asset',
        typeCaption: 'Asset',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),         
        parent: null,
        parentCaption: '',
        coabalancevalue: 0,
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'coabalancevalue', caption: 'Last Balance', type: 'money' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionType = [
        { name: 'asset', caption: 'Asset' },
        { name: 'liability', caption: 'Liability' },
        { name: 'equity', caption: 'Equity' },
        { name: 'income', caption: 'Income' },
        { name: 'expense', caption: 'Expense' },
    ]
}