import * as React from 'react';
import { Button} from '@mui/material';
import { Dialog, Icon, Text } from '.';

interface Props {
  id?: string;
  name: string;
  label?: string;
  fileType?: string;
  separator?: string;
  getDataSet?: (data: any) => void;
  buttonView?: boolean;
  readOnly?: boolean;
  error?: string;
  children?: React.ReactNode;
}

export const InputBulkUpload: React.FunctionComponent<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  
  const onClose = () => {setOpen(false)};
  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    if (e.target.files?.length===1) {
      const reader = new FileReader();
      reader.readAsText(e.target.files[0]);
      reader.onload = () => {
        props.getDataSet?.(parseData(reader.result, props.separator)); 
        setOpen(true)
      }
    }
  };

  const parseData = (data: any, separator?: string) => {
    separator = separator || ',';
    const result = [];
    const rows = data.split('\n');
    const header = rows[0].trim().split(separator);

    for (let i = 1; i < rows.length; i++) {        
      if (!rows[i]) continue;
      var row: any = {};
      const currentRow = rows[i].split(',');
      for (let j = 0; j < header.length; j++) 
        row[header[j].trim()] = currentRow[j].trim();
      result.push(row);
    }
    return result;
  }

  return (
          
    <Text id={props.id || props.name} size='extrasmall' color='text.secondary' >
      {props.label && props.children? props.label : ''}
      <Button component='label' variant={props.buttonView? 'outlined' : 'text'} startIcon={props.buttonView && <Icon name='folder'/>} disabled={props.readOnly}>
        <input  hidden accept={'.'+(props.fileType || 'csv')} type='file' value='' onChange={onChange(props)}/>
        {props.buttonView? <>&nbsp;{props.label}</> : <Icon name='cloud_upload'/>}
      </Button>    
      {props.children && <Dialog open={open} onClose={onClose}>{open && props.children}</Dialog>}
    </Text>
  )
};


