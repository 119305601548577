import * as React from 'react';
import { History } from 'history';
import Model from './menugroup-model';
import BasePicklistCard from '../base/base-picklistcard.component';
import AppService from '../../services/app.service';

interface IProps {
  history: History;
  root?: string;
  caption?: string;
  currentId?: number;
  cardCount?: number;
  cardIconOnly?: boolean;
  autoLoad?: boolean;
  getDataRow?: (data: any) => void;
}

interface IState {
  dataRow: Model;
  currentId: number;
}

export default class MenugroupPicklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { 
      dataRow: Model.defaultValue(),
      currentId : this.props.currentId || 0,
    }
  }

  private historyState: any = this.props.history.location.state;
  private isMobile: boolean = AppService.isMobile();
  
  private getDataRow = (data: any) => {
    this.historyState = AppService.historyState();
    this.props.history.push(this.props.history.location.pathname, {...this.historyState, menugroupId: data.id});
    this.setState({dataRow: data, currentId: data.id})
    this.props.getDataRow?.(data);
  };

  public componentDidMount() {
    this.setState({currentId: 0});
  }

  public render(): React.ReactNode {
    return (
      <BasePicklistCard caption={this.props.caption} endPointFilter={Model.endPointFilter2()} model={Model} currentId={this.state.currentId} getDataRow={this.getDataRow} pageSize={this.isMobile? 6: 10} cardCount={this.props.cardCount || 2} cardBoxShadow={5} swipe={this.isMobile? 'vertical-full' : ''} cardIconOnly={this.props.cardIconOnly}  autoLoad={this.isMobile || this.props.autoLoad} disableBackButton disableCard/>
    );
  }
}
