import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import RegionList from './region-list.component';
import RegionCreate from './region-create.component';
import RegionView from './region-view.component';
import RegionEdit from './region-edit.component';
import RegionCopy from './region-copy.component';
import RegionDelete from './region-delete.component';

interface Props {
  root?: string;
}

export const RegionRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/region/create'} component={RegionCreate} />
      <Route path={`${props.root||''}/region/view`} render={(params)=> <RegionView {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/region/edit`} render={(params)=> <RegionEdit {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/region/copy`} component={RegionCopy} />
      <Route path={`${props.root||''}/region/delete`} render={(params)=> <RegionDelete {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={(props.root||'')+'/region'} render={(params)=> <RegionList {...params} root={props.root} />} /> 
   </Switch>
  );
};
