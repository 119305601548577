import * as React from 'react';
import { CircularProgress, circularProgressClasses, Grid } from '@mui/material';
import { Icon, Text } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  caption?: string;
  value: number;
  totalValue: number;
  color?: any;
  bgColor?: any;
  showValue?: boolean;
  floating?: boolean;
}

export const ProgressBar: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const bgColor = props.bgColor || '#e3f0d3' || 'white';
  const color = props.color || '#357a38';
  var progressValue : number =  Math.floor((props.value/props.totalValue)*100) || 0;
  progressValue = progressValue>100? 100 : progressValue;

  return (  
    <Grid container position={props.floating? 'fixed' : 'unset'} alignItems='center' justifyContent='center' bgcolor={bgColor} sx={{borderRadius: '50%', height:  {xs: '70px', md: '100px'}, width: {xs: '70px', md: '100px'}}} m={1}>
      <CircularProgress variant='determinate' size={isMobile? 70: 100} value={100} thickness={3} sx={{ color: color, position: 'absolute', opacity: 0.3}}/>
      <CircularProgress variant='determinate' size={isMobile? 70: 100} value={progressValue} thickness={progressValue===100? 3 : 4} sx={{ color: color, position: 'absolute', [`& .${circularProgressClasses.circle}`]: {strokeLinecap: 'round'}}}/>     
      
      <Text color={color} size='extralarge' icon={progressValue===100? 'done_outline' : ''} iconSize='large' sx={{position: 'absolute'}}>
        <b>{progressValue<100 && props.showValue? props.value : <>{progressValue.toString()}{!props.caption && <Icon name='percent' size={isMobile? 'extrasmall' : 'small'}/>}</> }</b>
      </Text>
      <Text label={props.caption}color={color} size='small' marginTop={5}/>
    </Grid>
  )
};


