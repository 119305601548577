import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { Icon, TextToHtml, Transition } from '.';

interface Props {
  id?: string;
  label?: string;
  size?: 'supersmall'|'extrasmall'|'small'|'medium'|'large'|'extralarge'|'superlarge'|'superextralarge'|'extralarge3';
  color?: string;
  textAlign?: 'left'|'center'|'right'|'justify';
  lineHeight?: number;
  bold?: boolean;
  textToHtml?: boolean;
  displayBlock?: boolean;
  icon?: string;
  iconColor?: string;
  iconSize?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  transition?: 'hideOnScroll'|'slide'|'fade'|'zoom'|'grow'|'growLeft';
  transitionParentid?: string;
  transitionTime?: number;
  marginTop?: number;
  sx?: any;
  children?: React.ReactNode;
}

export const Text: React.FunctionComponent<Props> = (props) => {
  const size = {'supersmall': {xs: 'caption', md: 'caption'}, 'extrasmall': {xs: 'caption', md: 'body2'}, 'small': {xs: 'body2', md: 'subtitle1'}, 'medium': {xs: 'subtitle2', md: 'h6'}, 'large': {xs: 'h6', md: 'h5'}, 'extralarge': {xs: 'h5', md: 'h4'}, 'superlarge': {xs: 'h4', md: 'h3'}, 'superextralarge': {xs: 'h3', md: 'h2'}, 'extralarge3': {xs: 'h2', md: 'h1'}};
  const sxTexToHtml = props.textToHtml && {whiteSpace: 'pre-line', overflow: 'hidden'};

  return (
    <Transition parentId={props.transitionParentid} variant={props.transition} addTime={props.transitionTime}>
      <Typography component='div' sx={{typography: size[props.size || 'medium'], lineHeight: {xs: (props.lineHeight || 1.5)-0.1, md: props.lineHeight || 1.5}, ...sxTexToHtml, ...props.sx}} color={props.color} textAlign={props.textAlign || 'left'} mt={props.marginTop} dangerouslySetInnerHTML={props.textToHtml? { __html: props.label || ''}: undefined}>
        {props.textToHtml? undefined :
        <>
        <Grid container alignItems='center' justifyContent={props.textAlign}>
          {props.icon && <Icon name={props.icon || ''} size={props.iconSize} color={props.iconColor} marginRight={!!props.label}/>}
          {props.label && <Grid item xs md >{props.bold? <b>{props.label}</b> : props.textToHtml? <TextToHtml content={props.label || ''} /> : props.label} {props.displayBlock && props.children} </Grid>}
        </Grid>
        {!props.children || props.displayBlock? '' : <><br/>{props.children}</>}
        </>
        }
      </Typography>
    </Transition>
  )

}