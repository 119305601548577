import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputNumber, InputSelect, InputSwitch, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseView from '../base/base-view.component';
import Model from './address-model';  //editable..
import PersonalinfoModel from '../personalinfo/personalinfo-model';
import RegionPicklist from '../region/region-picklist.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class AddressCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private historyState: any = this.props.history.location.state;

    private getDataRowPersonalinfo = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'personalinfo': data?.id || null, 'personalinfoCaption': data?.name || ''  }  });
    };
    
    private getDataRowRegion = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'region': data?.id || null, 'regionCaption': data?.description || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(): void {    
        this.getDataRowPersonalinfo({'id': this.historyState?.id});
    }

    render() {
        return (
            //editable..     
            <>
            {this.historyState?.id>0 &&
            <BaseView history={this.props.history} caption=''
                model={PersonalinfoModel} getDataRow={this.getDataRowPersonalinfo}/>
            }         
            <BaseCreate history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} error={this.state.dataError?.type} onChange={this.onChange} />
                <InputMultiline name='street' label='Street, RT, RW, No.' value={this.state.dataRow.street} onChange={this.onChange} />
                <Picklist name='region' label='Kel/Desa, Kec, Kota, Provinsi' value={this.state.dataRow.region} caption={this.state.dataRow.regionCaption} onChange={this.onChange} >
                    <RegionPicklist history={this.props.history} currentId={this.state.dataRow.region} regionType='village' getDataRow={this.getDataRowRegion}/>
                </Picklist> 
                <Input name='map' label='Map' value={this.state.dataRow.map} onChange={this.onChange} />
                <InputNumber name='seq' label='Sequence' value={this.state.dataRow.seq} error={this.state.dataError?.seq} onChange={this.onChange} />
                       
            </BaseCreate>
            </>
        );
    }     
     
}