import React from 'react';
import { History } from 'history';
import { InputMoney, InputMultiline, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseView from '../base/base-view.component';
import Model from './transactionpayment-model';  //editable..
import TransactionModel from '../transaction/transaction-model';
import PaymenttypePicklist from '../paymenttype/paymenttype-picklist.component';
                
interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactionpaymentCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private historyState: any = this.props.history.location.state;
    
    private getDataRowTransaction = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'transaction': data?.id || null, 'transactionCaption': data?.name || ''  }  });
    };
   
    private getDataRowPaymenttype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'paymenttype': data?.id || null, 'paymenttypeCaption': data?.name || '', 'value': data.defaultvalue }  });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, 
            }
        };
        this.setState(nextState);

    }

    public componentDidMount(): void {        
        this.getDataRowTransaction({'id': this.historyState?.id});
    }

    render() {
        return (
            //editable..
            <>
            {this.historyState?.id>0 &&
            <BaseView history={this.props.history} caption=''
                model={TransactionModel} getDataRow={this.getDataRowTransaction}/>
            }  
            <BaseCreate history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Picklist name='paymenttype' label='Type' value={this.state.dataRow.paymenttype} caption={this.state.dataRow.paymenttypeCaption} error={this.state.dataError?.paymenttype} onChange={this.onChange} >
                    <PaymenttypePicklist currentId={this.state.dataRow.paymenttype} getDataRow={this.getDataRowPaymenttype}/>
                </Picklist>                 
                <InputMoney name='value' label='Payment' value={this.state.dataRow.value} error={this.state.dataError?.value} onChange={this.onChange} />
                <InputMultiline name='note' label='Note' value={this.state.dataRow.note} error={this.state.dataError?.note} onChange={this.onChange} />
                      
            </BaseCreate>
            </>
        );
    }     
     
}