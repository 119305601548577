import React from 'react';
import * as toastr from 'toastr';
import { Form, PrintPDF } from '../../components';
import ApiService from '../../services/api.service';

interface IProps { 
    history?: any;
    id?: number;
    caption?: string;
    captionColor?: string;
    captionThin?: boolean;
    buttonCaption?: string;
    proccessCaption?: string;
    endPointFilter?: string;
    model: any;
    getDataRow?: (data: any) => void;
    printOptions?: any;
    htmlId: string;
    save?: boolean;
    onProgess?: (data: any) => void;
    getFiles?: (data: any) => void;
    disableBackButton?: boolean;
    disableCard?: boolean;
    children?: React.ReactNode;
}

interface IState {
}

export default class BasePreview extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    private id = this.props.id?.toString() || this.props.history?.location.state?.id || '';
    private isLoading: boolean = false;

    public componentDidMount() { 
        
        if (this.isLoading) return;
        this.isLoading = true;

        ApiService.get<typeof this.props.model>(this.props.model.endPoint+(this.props.endPointFilter||'?populate=*&filters[id]='), this.id).then(
            (rp) => {
                if (rp.Status) { if (rp.Data.length>0) var data = new this.props.model(rp.Data[0]); this.props.getDataRow?.(data);} 
                else { toastr.error(rp.Messages); }
                this.isLoading = false;
            }
        );
    }

    render() {
        
        return (
            <Form history={this.props.history} caption={this.props.caption || 'Preview '+ this.props.model.caption} captionColor={this.props.captionColor} captionThin={this.props.captionThin} buttonCaption=' ' disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>              
                {this.props.printOptions?.type==='pdf' && 
                    <PrintPDF htmlId={this.props.htmlId} printOptions={this.props.printOptions} caption={this.props.proccessCaption} buttonCaption={this.props.buttonCaption ||'Save PDF'} save={this.props.save} getFiles={this.props.getFiles} onProgress={this.props.onProgess}/>
                }
            </Form> 
        );
    }
}