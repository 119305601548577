import * as React from 'react';
import { History } from 'history';
import Model from './schedule-model';
import BaseChecklist from '../base/base-checklist.component';

interface IProps { 
  history: History;
  readOnly?: boolean
  disableBackButton? : boolean
  currentId: any;
  getDataRow?: (data: any) => void;
}

interface IState {
}

export default class ScheduleChecklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }



  public render(): React.ReactNode {
    return (
      <BaseChecklist history={this.props.history} endPointFilter={Model.endPointFilter()} model={Model} currentId={this.props.currentId} getDataRow={this.props.getDataRow} readOnly={this.props.readOnly} disableBackButton={this.props.disableBackButton}/>
    );
  }
}
