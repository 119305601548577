import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputFile, InputMultiline, InputSwitch } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './owner-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class OwnerCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    }
  
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataError={this.getDataError}>               
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                <Input name='phone' label='Phone' value={this.state.dataRow.phone} error={this.state.dataError?.phone} onChange={this.onChange} />
                <InputEmail name='email' label='Email' value={this.state.dataRow.email} error={this.state.dataError?.email} onChange={this.onChange} />
                <InputMultiline name='address' label='Address' value={this.state.dataRow.address} error={this.state.dataError?.address} onChange={this.onChange} />
                <InputFile name='imageUrl' label='Logo' value={this.state.dataRow.imageUrl} type='image' getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onChange} />
           </BaseCreate>
        );
    }     
     
}