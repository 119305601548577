import React from 'react';
import { History } from 'history';
import { InputEmail } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class UserAuthForgotPassword extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onSave = (data: any) => {
    };

    private onChange(fieldName: string, value: string) { 
        var email = this.state.dataRow.email;
        if (fieldName === 'useremail') email = Model.getEmailApp(value);

        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, email: email,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(){
        const dataRowTemp: any = Model.defaultValueForgotPassword;
        this.setState({dataRow: dataRowTemp})
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption='Send Verification Code For Reset Your Password' buttonCaption='Send Email'
                endPoint={Model.endPointAuth+'/forgot-password'} model={Model} dataRow={this.state.dataRow} validations={Model.validationForgotPassword} getDataError={this.getDataError} buttonOnBottom onSave={this.onSave}>              
                
                <InputEmail name='useremail' label='Your registered email' value={this.state.dataRow.useremail} error={this.state.dataError?.useremail} onChange={this.onChange} />
            </BaseCreate>
        );
    }     
     
}