import * as React from 'react';
import { Grid, MenuItem, TextField } from '@mui/material';
import { Button, Chip, Text } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  name: string;
  label?: string;
  placeHolder?: string;
  value: string;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  box?: boolean;
  color?: string;
  options: any;
  buttonStyle?: boolean;
  readOnly?: boolean;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
}

export const InputSelect: React.FunctionComponent<Props> = (props) => {
  const fontSize = () => {
    const sizes = {'extrasmall': 16, 'small': 18, 'medium': 24, 'large': 36, 'extralarge': 48};
    const size = sizes[props.size || 'small'];
    return {xs: size-2, md: size}
  }
  
  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, e.target.value);
  };
  const onClick = (value: string) => (e: React.MouseEvent<HTMLButtonElement>)=> {
    props.onChange?.(props.name, value);
  }
  const getCaption = () => {
    return props.options.filter((d: any) => d.name === props.value)?.[0]?.caption || '';
  }

  return (
    <>
    {props.readOnly?
      <>
      <Text label={props.label} size='extrasmall' color='text.secondary' marginTop={1} sx={{mb: 1}} />
      <Chip label={getCaption()} /> 
      </>
    :
    (props.buttonStyle || props.options.length<=3)?
      <>
      <Text label={props.label} size='extrasmall' color='text.secondary' marginTop={1} sx={{mb: 1}} />
      <Grid marginBottom={2}>
      {props.options.map((d: any, i: number) => (
        <Button key={i} label={d.caption} variant={props.value === d.name? 'contained' : 'outlined'}  sx={{margin: 0.5, padding: 1}} textCapitalize onClick={onClick(d.name)}/>
      ))}
      </Grid>
      </>
    :
      <TextField
        id={props.id || props.name}
        name={props.name}
        label={props.label}
        value={props.readOnly? getCaption() : (props.value || '')}
        placeholder={props.placeHolder}
        inputProps={{spellCheck: false}}
        InputProps={{
          readOnly: props.readOnly || false,
          sx: {fontSize: fontSize(), bgcolor: props.color, borderRadius: 3},
        }}
        InputLabelProps={{
          sx: {fontSize: fontSize()}
        }}
        sx= {{
          '& .MuiSvgIcon-root': {
            color: 'primary.main',
          }
        }}
        select={!props.readOnly}
        margin='normal'
        variant={props.box? undefined : 'standard'}
        fullWidth
        helperText={props.error}
        error= {props.error? true: false}
        onChange={onChange(props)}
      >
      {props.options.map((d: any, i: number) => (
        <MenuItem key={i} value={d.name}>
          {d.caption}
        </MenuItem>
      ))}
      </TextField>
    }
    </>
  )
};