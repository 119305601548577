import React from 'react';
import { History } from 'history';
import { Chip, InputDate, Picklist } from '../../components';
import { Grid } from '@mui/material';
import BaseCreate from '../base/base-create.component';
import BaseView from '../base/base-view.component'; 
import BaseList from '../base/base-list.component';
import Model from './transaction-model';  //editable..
import ScheduleModel from '../schedule/schedule-model';
import UserModel from '../user/user-model';
import TransactiontypeModel from '../transactiontype/transactiontype-model';
import PersonalinfoPicklist from '../personalinfo/personalinfo-picklist.component';

interface IProps { 
    history: History;
    root?: string;
    caption?: string;
    transactiontypeType?: string;
    transactionType?: string;
    disableFields?: any;
}

interface IState {
    dataRow: Model;
    dataError: any;
    disableDay: any;
}

export default class TransactionCreateByuserSchedule extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            disableDay: [0,1,2,3,4,5,6],
        } 
        this.onChange = this.onChange.bind(this);
    }
    
    private historyState: any = this.props.history.location.state;

    private getDataRowEmployee = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'employee': data?.id || null, 'employeeCaption': data?.name || ''  }  });
    };
        
    private getDataRowSchedule = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'name': 'Book '+data?.divisionCaption+' '+data?.timeAll, 'description': 'Book '+data?.divisionCaption+' '+data?.timeAll, 'schedule': data?.id || null, 'scheduleCaption': data?.timeAll || '', 'division': data?.division || null, 'divisionCaption': data?.divisionCaption || null }, disableDay: this.state.disableDay.filter((d: any)=>{return d !== data?.dayNo})  });
    };

    private getDataRowUser = (data:any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'customer': data?.personalinfo} });
    }

    private getDataSetTransactiontype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'transactiontype': data?.[0]?.id || null, 'transactiontypeCaption': data?.[0]?.name || ''  }  });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable.. 
            <>       
            {this.historyState?.id>0 &&
                <>
                <BaseView id={this.historyState?.id} caption=''
                    model={ScheduleModel} getDataRow={this.getDataRowSchedule}/>
                <BaseView id={+UserModel.getUser()} caption='' 
                    model={UserModel} getDataRow={this.getDataRowUser}/>
                </>
            }  
            <BaseCreate history={this.props.history} caption={this.props.caption} buttonCaption={this.state.dataRow.transactiontype? '' : ' '}
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} >
                
                <BaseList history={this.props.history} caption=' ' endPointFilter={TransactiontypeModel.endPointFilter(this.props.transactiontypeType||'')} model={TransactiontypeModel} getDataSet={this.getDataSetTransactiontype} disableView/>
          
                {this.props.disableFields?.includes('transactionType') && !this.state.dataRow.transactiontype?
                <Grid container justifyContent='center'><Chip label='Transaction is not allowed, possible transaction type is missing' /></Grid>
                :
                <>
                <Picklist name='division' label='Division' value={this.state.dataRow.division} caption={this.state.dataRow.divisionCaption} readOnly/>
                <Picklist name='schedule' label='Schedule' value={this.state.dataRow.schedule} caption={this.state.dataRow.scheduleCaption} readOnly/>
                <InputDate name='transactiondate' label='Date' value={this.state.dataRow.transactiondate} error={this.state.dataError?.transactiondate} disablePast disableTime disableDay={this.state.disableDay} onChange={this.onChange}/>            
                {!this.props.disableFields?.includes('employee') &&
                <Picklist name='employee' label='Employee' value={this.state.dataRow.employee} caption={this.state.dataRow.employeeCaption} clearValue={this.getDataRowEmployee} error={this.state.dataError?.employee} onChange={this.onChange} >
                    <PersonalinfoPicklist personalinfotypeType='employee' currentId={this.state.dataRow.employee} scheduleId={this.state.dataRow.schedule} getDataRow={this.getDataRowEmployee}/>
                </Picklist>
                }
                </>
                }
                           
            </BaseCreate>
            </>
        );
    }     
     
}