import React from 'react';
import { History } from 'history';
import { InputFile, InputSelect } from '../../components';
import BaseShare from '../base/base-share.component';
import BaseView from '../base/base-view.component';
import Model from './event-model';  //editable..
import EventtypeModel from '../eventtype/eventtype-model';
import MessageModel from '../message/message-model';
import UserAuthGetToken from '../user/user-auth-gettoken.component';
import PersonalinfoChecklist from '../personalinfo/personalinfo-checklist.component';
import MessageCopy from '../message/message-copy.component';
import ReportPreview from '../report/report-preview.component';
import { Chip, Grid } from '@mui/material';

interface IProps { 
    history: History;
    root?: string;
    id?: number;
    caption?: string;
    buttonCaption?: string;
    reportProcessCaption?: string;
    participantId?: number;
    reportId?: number;
    dataSource?: any;
    to?: string;
    astemplate?: string;
    save?: boolean;
    onSave?: (data: any) => void;
    disableView?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowEventtype: EventtypeModel;
    dataRowMessage: MessageModel;
    messageType: string;
    attachments: any;
    attachmentUrl: string;
    needToken: boolean;
    saveOrder: number;
    saveOrderPrev: number;
    job: string;
}

export default class EventShare extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowEventtype: EventtypeModel.defaultValue(),
            dataRowMessage: MessageModel.defaultValue(),
            messageType: 'email',
            attachments: null,
            attachmentUrl: '',
            needToken: false,
            saveOrder: -1,
            saveOrderPrev: -1,
            job: '',
        }
        
        this.onChange = this.onChange.bind(this);
    }

    private dataRowParticipant: any = null;
    
    private getDataRow = (data:any) => {
        this.setState({dataRow: data});
    }

    private getDataRowEventtype = (data:any) => {
        var id = this.props.reportId? data.messageresult : data.messageinvitation;
        this.setState({dataRowEventtype: data, dataRowMessage: {...this.state.dataRowMessage, id: id}});
    }

    private getDataRowMessage = (data:any) => {
        var text = data.text.replace('[event]', this.state.dataRow.name);
        this.setState({dataRowMessage: {...data, astemplate: 'no', sent: 'yes', text: text}, messageType: data.type, needToken: data.text.includes('[link]')});
    }

    private getDataRowParticipant = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'participant': data || [], 'participantLength': data.length} });
    };

    private getFiles = async(data:any) => {
        this.setState({ dataRowMessage: {...this.state.dataRowMessage, attachments : data}});
        if (this.props.reportId) await this.setState({ job : this.state.needToken? 'token' : 'message'});
    }

    private getDataRowToken = async(data: any) => { 
        var link = window.location.origin+'/user/link/'+ data.code +'?event='+this.state.dataRow.id;
        var text = this.state.dataRowMessage.text.replace('[link]', link).replace('[name]', this.dataRowParticipant.name);
        this.setState({ dataRowMessage: {...this.state.dataRowMessage, text : text, html: text.replaceAll('\n','<br/>')}});
        
        await this.setState({job : 'message'});
    }
    
    private getDataErrorToken = (data: any) => {  
        if (data) this.onSaveMessage(data);
    }

    private getDataErrorMessage = (data: any) => { 
        if (data) this.onSaveMessage(data);
    }

    private onSaveMessage = async(data: any) => { 
        var saveOrder = this.state.saveOrder+1;
        if (saveOrder >= this.state.dataRow.participantLength) {
            saveOrder = -1;
            this.props.onSave?.(null);
        }
        this.setState({ dataRowMessage: {...this.state.dataRowMessage, id: data.id}})
        await this.setState({ saveOrder: saveOrder, job: saveOrder < 0 ? '' : this.props.reportId? 'report' : this.state.needToken? 'token' : 'message'});
    }

    private onSave = (data: any) => {
        this.setState({dataRowMessage: {...this.state.dataRowMessage, type: this.state.messageType}, 
            saveOrder: 0, job: this.props.reportId? 'report' : this.state.needToken? 'token' : 'message'});
    }
    
    private onChange(fieldName: string, value: string) {
        const nextState = {
            ...this.state, [fieldName]: value
        };
        this.setState(nextState);
    }

    public componentDidUpdate() {
        if (this.state.saveOrder !== this.state.saveOrderPrev && this.state.saveOrder>=0) {
            this.dataRowParticipant = this.state.dataRow?.participant?.[this.state.saveOrder];
            if (this.dataRowParticipant) {
                var to = this.state.messageType==='email'? (this.props.to || this.dataRowParticipant.email) : (this.props.to || this.dataRowParticipant.phone);            
                this.setState({ dataRowMessage: {...this.state.dataRowMessage, to: to}, saveOrderPrev: this.state.saveOrder});
            }
        }
                
    }

    render() {
        return(
            //editable..
            <>
            <BaseShare history={this.props.history} id={this.props.id} caption={this.props.disableView? ' ' : ''} buttonCaption={this.state.saveOrder>=0 && this.props.disableView? 'Proccessing ...' : this.props.buttonCaption || (this.props.disableView || this.state.dataRow.participantLength===0? ' ' : '')}
                endPointFilter={Model.endPointFilter8(this.props.participantId)} model={Model} getDataRow={this.getDataRow} save={this.props.save && this.state.saveOrder<0} onSave={this.onSave} disableAutoBack disableBackButton={this.props.disableView} disableCard={this.props.disableView}>  
                {this.state.dataRow.eventtype &&
                <BaseView id={this.state.dataRow.eventtype} caption='' 
                    model={EventtypeModel} getDataRow={this.getDataRowEventtype}/>
                }

                {this.state.dataRowMessage.id>0?
                <>
                    <BaseView id={this.state.dataRowMessage.id} caption='' 
                        model={MessageModel} getDataRow={this.getDataRowMessage}/>
                        
                    {!this.props.disableView &&
                    <>
                    <InputSelect name='messageType' label='Send By' value={this.state.messageType} options={MessageModel.optionType} error={this.state.dataError?.type} onChange={this.onChange} />
                    <InputFile name='attachmentUrl' label='Attachment' value={this.state.attachmentUrl} getFiles={this.getFiles} encode error={this.state.dataError?.attachments} onChange={this.onChange} />
                    <PersonalinfoChecklist history={this.props.history} caption=' ' currentId={this.state.dataRow.participant} getDataRow={this.getDataRowParticipant} selectedOnly disableBackButton/>
                    </>
                    }
                </>
                :
                !this.props.disableView && <Grid container justifyContent='center'><Chip label='Invalid message template, please check on event type setting.' /></Grid>
                }
            </BaseShare>

            {this.state.dataRowMessage.id>0 && this.state.dataRow.participantLength>0 && this.dataRowParticipant && 
            <>
            {this.state.job==='report' && <ReportPreview history={this.props.history} id={this.props.reportId} proccessCaption={this.props.reportProcessCaption} getFiles={this.getFiles} save disableView/>}
            {this.state.job==='token' && <UserAuthGetToken history={this.props.history} useremail={this.dataRowParticipant.userEmail} successCaption='Link Created' getDataRow={this.getDataRowToken} getDataError={this.getDataErrorToken} save/>}
            {this.state.job==='message' && <MessageCopy history={this.props.history} id={this.state.dataRowMessage.id} successCaption='Message Sent' dataRow={this.state.dataRowMessage} getDataError={this.getDataErrorMessage} save onSave={this.onSaveMessage} disableView/>}
            </>
            }
        </>
        )
    }
}