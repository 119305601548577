import * as React from 'react';
import { darken, lighten, rgbToHex, createTheme, CssBaseline, ThemeProvider } from '@mui/material';

//https://imagecolorpicker.com/ --get color code
//https://uicolors.app/ --ui coloring recomendation

interface Props {
  id?: string;
  colorPrimary: string;
  colorSecondary?: string;
  children?: React.ReactNode;
}

export const Themes: React.FunctionComponent<Props> = (props) => { 
  const getTheme = (colorPrimary: string, colorSecondary?: string) => {
    const getPalette = (color: string) => {
      var colors: any = {}; 
      colors[500] = color;
      for (let j = 1; j < 10; j++) {
        colors[(10-j)*50] = rgbToHex(lighten(color, j*0.1));
        colors[500+j*50] = rgbToHex(darken(color, j*0.1));
      }
      return colors;
    }
    const colorDefault = '#34b1fd';
    const palette = {primary: getPalette(colorPrimary || colorDefault), secondary: getPalette(colorSecondary || colorDefault), info: getPalette('#fff387'), success: getPalette('#aeea00'), warning: getPalette('#f2780c'), error: getPalette('#ff0000')};
    
    return createTheme({
      palette: {
        background: {
          default: palette.primary['50'],
          paper: palette.primary['100'],
        },
        text: {
          primary: palette.primary['800'],
          secondary: palette.primary['600'],
        },
        primary: {
          ...palette.primary,
          light: palette.primary['300'],
          main: palette.primary['500'],
          dark: palette.primary['700'],
          contrastText: palette.primary['900'],
        },
        secondary: {
          ...palette.secondary,
          light: palette.secondary['300'],
          main: palette.secondary['500'],
          dark: palette.secondary['700'],
          contrastText: palette.secondary['900'],
        },
        info: {
          ...palette.info,
          light: palette.info['300'],
          main: palette.info['500'],
          dark: palette.info['700'],
          contrastText: palette.info['900'],
        },
        success: {
          ...palette.success,
          light: palette.success['300'],
          main: palette.success['500'],
          dark: palette.success['700'],
          contrastText: palette.success['900'],
        },
        warning: {
          ...palette.warning,
          light: palette.warning['300'],
          main: palette.warning['500'],
          dark: palette.warning['700'],
          contrastText: palette.warning['900'],
        },
        error: {
          ...palette.warning,
          light: palette.error['300'],
          main: palette.error['500'],
          dark: palette.error['700'],
          contrastText: palette.error['900'],
        },
      },
      /*
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            action: {
            active: '#357a38',
              hover: '#6fbf73',
              hoverOpacity: 0.7,
              focus: '#357a38',
              focusOpacity: 1,
              selected: '#6fbf73',
              selectedOpacity: 1
            }
          },
        },
      },
      */
    });
  };

  const theme = getTheme(props.colorPrimary, props.colorSecondary);

  return (
    <ThemeProvider theme={theme}> 
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
};