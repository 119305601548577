
export default class RegionModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    type: string;
    typeCaption: string;
    zipcode: number;
    code: string;
    latitude: number;
    longitude: number;
    elevation: number;
    parent: any;
    parentCaption: string;
    regionLength: number;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.type = dataRow.attributes.type || 'village';
        this.typeCaption = RegionModel.optionType.filter((d: any) => d.name === this.type)?.[0]?.caption || 'Village';
        this.zipcode = dataRow.attributes.zipcode;
        this.code = dataRow.attributes.code;
        this.latitude = dataRow.attributes.latitude;
        this.longitude = dataRow.attributes.longitude;
        this.elevation = dataRow.attributes.elevation;
        this.parent = dataRow.attributes.parent?.data?.id || null;
        this.parentCaption = dataRow.attributes.parent?.data?.attributes.name || dataRow.attributes.parent?.data?.id; 
        this.regionLength = dataRow.attributes.region?.data?.length || 0;
    } 

    public static endPoint: string = 'regions';
    public static endPointFilter: string = '?populate=*&sort=name&filters[parent][id][$null]=true';
    public static endPointFilter2: string = '?populate=*&sort=name&filters[parent]=';
    public static endPointFilter3: string = '?populate=*&sort=name&filters[type]=';
    public static url: string = '/region';
    public static caption: string = 'Region';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        type: 'village',
        typeCaption: 'Village',
        zipcode:  0,
        code:  '',
        latitude:  0,
        longitude:  0,
        elevation: 0,     
        parent: null,
        parentCaption: '',
        regionLength: 0,  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionType = [
        { name: 'province', caption: 'Propinsi' },
        { name: 'city', caption: 'Kota/Kabupaten' },
        { name: 'subdistrict', caption: 'Kecamatan' },
        { name: 'village', caption: 'Kelurahan/Desa' },
    ]
}