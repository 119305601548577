import * as React from 'react';
import { History } from 'history';
import { Button, Card, FormHeader } from '.';

interface Props {
  history?: History;
  id?: string;
  caption: string;
  captionColor?: string;
  captionThin?: boolean;
  buttonCaption?: string;
  buttonColor?: string;
  buttonOnBottom?: boolean;
  onSubmit?: (data: any) => void;
  disableBackButton?: boolean;
  disableCard?: boolean;
  children?: React.ReactNode;
}

export const Form: React.FunctionComponent<Props> = (props) => {

  const displayButton = (marginTop: number) => {
    const buttonCaption = props.buttonCaption || 'submit';
    if (props.buttonCaption?.trim()!=='')
        return <Button variant='contained' label={buttonCaption} color={props.buttonColor} sx={{marginTop: marginTop}} onClick={props.onSubmit} />      
  }

  const child = () => {
    return (        
      <>
      <FormHeader history={props.history} label={props.caption} color={props.captionColor} thin={props.captionThin} disableBackButton={props.disableBackButton}>  
        {!props.buttonOnBottom && displayButton(0)}
      </FormHeader>
      {props.disableCard? props.children: <Card>{props.children}</Card>}
      {props.buttonOnBottom && displayButton(3)}
      </>
    );
  }
  
  const childWithForm = () => {
    return (         
      <form id={props.id} onSubmit={e => e.preventDefault()}> {child()} </form>
    )
  }

  return (        
    <>
      {props.children && (props.buttonCaption?.trim()!=='' || props.onSubmit? childWithForm() : child()) }
    </>
  );
};
