import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionList from './transaction-list.component';
import TransactionCreate from './transaction-create.component';
import TransactionCreateByuserEvent from './transaction-create-byuser-event.component';
import TransactionCreateByuserSchedule from './transaction-create-byuser-schedule.component';
import TransactionView from './transaction-view.component';
import TransactionEdit from './transaction-edit.component';
import TransactionCopy from './transaction-copy.component';
import TransactionDelete from './transaction-delete.component';
import TransactionEditStatus from './transaction-edit-status.component';

interface Props {
  root?: string;
}

export const TransactionRouteSales: React.FunctionComponent<Props> = (props) => {
  const disableFields = ['owner','transactionType','vendor','journal'];
  
  return (
    <Switch>
        <Route path={(props.root||'')+'/transactionsales/create'} render={(params)=> <TransactionCreate {...params} key={params.history.location.key} root={props.root} caption='Create Sales Transaction' transactiontypeType='sales' disableFields={disableFields} />} />        
        <Route path={(props.root||'')+'/transactionsales/createbyuserevent'} render={(params)=> <TransactionCreateByuserEvent {...params} key={params.history.location.key} root={props.root} caption='Create Transaction' transactiontypeType='sales' disableFields={disableFields} />} />        
        <Route path={(props.root||'')+'/transactionsales/createbyuserschedule'} render={(params)=> <TransactionCreateByuserSchedule {...params} key={params.history.location.key} root={props.root} caption='Create Transaction' transactiontypeType='sales' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionsales/view`} render={(params)=> <TransactionView {...params} key={params.history.location.key} root={props.root} caption='View Transaction' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionsales/edit`} render={(params)=> <TransactionEdit {...params} key={params.history.location.key} root={props.root} caption='Edit Sales Transaction' transactiontypeType='sales' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionsales/copy`} render={(params)=> <TransactionCopy {...params} key={params.history.location.key} root={props.root} caption='Duplicate Sales Transaction' transactiontypeType='sales' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionsales/delete`} render={(params)=> <TransactionDelete {...params} key={params.history.location.key} root={props.root} caption='Delete Sales Transaction' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionsales/status`} render={(params)=> <TransactionEditStatus {...params} key={params.history.location.key} root={props.root} caption='Change Status Sales Transaction' transactiontypeType='sales' disableFields={disableFields} />} />        
        <Route path={(props.root||'')+'/transactionsales'} render={(params)=> <TransactionList {...params} key={params.history.location.key} root={props.root}  caption='Sales Transaction' transactiontypeType='sales' actions={['...','status']} url='/transactionsales'/>} /> 
    </Switch>
  );
};
