import * as React from 'react';
import { Form, Table } from '../../components';
import ApiService from '../../services/api.service';
import AppService from '../../services/app.service';

interface IProps {
  history?: any;
  id?: any;
  caption?: string;
  captionColor?: string;
  captionThin?: boolean;
  endPointFilter?: string;
  model: any;
  header?: any;
  currentId?: any;
  getDataRow?: (data: any) => void;
  page?: number;
  pageSize?: number;
  searchField?: string;
  onChangePage?: (e: React.ChangeEvent<unknown>, value: number) => void;
  selectedOnly?: boolean;
  readOnly?: boolean;
  disableBackButton?: boolean;
  disableCard?: boolean;
  disableView?: boolean;
}

interface IState {
  dataSet: Array<any>;
  header: Array<any>;
  currentId: any;
  endPointFilter: string;
  page: number;
  pageSize: number;
  pageCount: number;
  pageName: string;
  filter: string;
  searchText: string;
  isSearch: boolean;
  isReady: boolean;
  isError: boolean;
}

export default class BaseCheckList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataSet: this.props.model,
      header: this.props.header || this.props.model.columnPicklist,
      currentId: this.props.currentId?.map((d: any) => d.attributes? new props.model(d) : d),
      endPointFilter: this.props.endPointFilter || '',
      page: this.props.page || 1,
      pageSize: this.props.pageSize || 10,
      pageCount: 0,
      pageName: this.props.model.url.replace('/','') + (this.props.id||'') + 'Page',
      filter: '',
      searchText: '',
      isSearch: this.props.model.columnPicklist.findIndex((d: { name: string; }) => d.name === (this.props.searchField || 'name'))>=0,
      isReady: false,
      isError: false,
    };
    
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onEndTypingSearch = this.onEndTypingSearch.bind(this);
  }
  
  private historyState: any = this.props.history?.location.state;
  private isLoading: boolean = false;

  private loadData (page: number, searchText?: string, filter?: string) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.setState({ isReady: false});

    const paginationPage = '&pagination[page]='+page;
    const paginationPageSize = '&pagination[pageSize]='+this.state.pageSize;
    const search = searchText? '&filters['+(this.props.searchField || 'name')+'][$contains]='+searchText : paginationPage;
    ApiService.getAll<typeof this.props.model>(this.props.model.endPoint+(this.props.endPointFilter||'?populate=*')+filter+search+paginationPageSize).then((rp) => {
      if (rp.Status) {
        const pageCount = Math.ceil((rp.Meta?.pagination?.total||1)/this.state.pageSize);
        const data = rp.Data;
        const dataSet = new Array<typeof this.props.model>();
        (data || []).forEach((dataRow: any, i:number) => {dataSet.push(new this.props.model(dataRow)); });
        this.setState({ dataSet: dataSet, pageCount:pageCount, isReady: true });
      } 
      else { this.setState({ isReady: true, isError: true }); }
      this.isLoading = false;
    });
  }

  private onChangePage (e: React.ChangeEvent<unknown>, value: number) {
    AppService.historyState(this.state.pageName, value);
    this.setState({page: value});
    this.loadData(value, this.state.searchText, this.state.filter);
    this.props.onChangePage?.(e,value)
  };
  
  private onChangeSearch (fieldName: string, value: string) { 
    this.setState({searchText: value});
  }
  
  private onClickSearch() { 
    this.loadData(this.state.page, this.state.searchText, this.state.filter);
  }

  private onEndTypingSearch(fieldName?: string, value?: string) {
    this.setState({searchText: value || ''});
    this.loadData(this.state.page, value);
  }

  private currentIds() {
    if (this.props.readOnly || this.props.selectedOnly){
      if (this.state.currentId?.length===0)
        return '&filters[id][$in]=0'
      else
        return this.state.currentId?.map((d: any) =>  '&filters[id][$in]='+d.id );
    }
    else return '';
  }

  private table(){
    return <Table header={this.state.header} dataSet={this.state.dataSet} currentId={this.state.currentId} getDataRow={this.props.getDataRow} page={this.state.page} pageCount={this.state.pageCount} searchText={this.state.searchText} isSearch={this.state.isSearch} isLoading={this.isLoading} isReady={this.state.isReady} isError={this.state.isError} onChangePage={this.onChangePage} onChangeSearch={this.onChangeSearch} onClickSearch={this.onClickSearch} onEndTypingSearch={this.onEndTypingSearch} readOnly={this.props.readOnly} checklistView/>
  }

  public componentDidMount() {
    var page = this.historyState?.[this.state.pageName] || this.state.page;
    var filter = this.currentIds();
    this.loadData(page, '', filter);
    this.setState({page: page, filter: filter});
  }
  
  public componentDidUpdate() {
    if (!this.isLoading)
      if (this.props.page && (this.props.page !== this.state.page)) {
        var filter = this.currentIds();
        this.loadData(this.props.page, '', filter);
        this.setState({page: this.props.page, filter: filter});
      }
      else if (this.props.endPointFilter && (this.props.endPointFilter !== this.state.endPointFilter)) {
        this.setState({endPointFilter: this.props.endPointFilter})
        this.loadData(1);
    }
  }

  public render(): React.ReactNode {    
    var caption = this.props.caption || 'Select'+ (this.props.readOnly? 'ed ': ' ')+  AppService.strPluralize(this.props.model.caption);
   
    return (
      <Form history={this.props.history} caption={caption} captionColor={this.props.captionColor} captionThin={this.props.captionThin} buttonCaption=' ' disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>              
          {!this.props.disableView && this.table()}
      </Form> 
    );
  }
}
