import React from 'react';
import { History } from 'history';
import BaseDelete from '../base/base-delete.component'; 
import Model from './eventquestion-model';  //editable..
import QuestionModel from '../question/question-model';
import BaseView from '../base/base-view.component';
import EventquestionViewQuestionoption from './eventquestion-view-questionoption.component';

interface IProps { 
    history: History;
    id?: number;
    caption?: string;
    delete?: boolean;
    onDelete?: (data: any) => void;
    getDataError?: (data: any) => void;
    disableView?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowQuestion: QuestionModel;
}

export default class EventquestionDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowQuestion: QuestionModel.defaultValue(),
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowQuestion = (data: any) => {
        this.setState({dataRowQuestion: data });
    }

    private onDelete = (data: any) =>{  
    }

    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} id={this.props.id} caption={this.props.disableView? ' ' : ''} buttonCaption={this.props.disableView? ' ' : ''}
                endPointFilter={Model.endPointFilter} model={Model} getDataRow={this.getDataRow} delete={this.props.delete} onDelete={this.onDelete} disableAutoBack={this.props.disableView} disableBackButton={this.props.disableView} disableCard={this.props.disableView}>            
                
                {!this.props.disableView && 
                <>
                {this.state.dataRow.question>0 &&
                <BaseView id={this.state.dataRow.question} caption=' ' 
                    model={QuestionModel} getDataRow={this.getDataRowQuestion}/>
                }
                {this.state.dataRowQuestion.id>0  && this.props.history &&
                <EventquestionViewQuestionoption history={this.props.history} eventId={this.state.dataRow.id} dataRowQuestion={this.state.dataRowQuestion} readOnly/>
                }
                </>
                }
            </BaseDelete>
        );
    }
}