import * as React from 'react';
import { Dialog as DialogMui, IconButton } from '@mui/material';
import { Icon, SwipeDrawer } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  open: boolean;
  maxWidth?: 'xs'|'md'|'lg';
  onClose?: () => void;
  children?: React.ReactNode;
}

export const Dialog: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();

  return (
    <>
    {isMobile?
      <SwipeDrawer variant='bottom' open={props.open || false} onClose={props.onClose}>
        {props.children}
      </SwipeDrawer>
    :
      <DialogMui open={props.open || false} fullWidth={true} maxWidth={props.maxWidth || 'md'} PaperProps={{sx: {backgroundColor: 'white', borderRadius: 5, overflow: 'hidden', padding:3 } }}> 
        <IconButton sx={{ position: 'absolute', right: '2%', top: 15}} onClick={props.onClose}> 
          <Icon name='close' color='primary.dark'/>
        </IconButton>
        {props.children}
      </DialogMui>
    }
    </>
  );
};

export default Dialog;
