import OwnerModel from '../owner/owner-model';

export default class EventtypeModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    messageinvitation: any;
    messageinvitationCaption: string;
    messageresult: any;
    messageresultCaption: string;
    reportgroup: any;
    reportgroupCaption: string;
    viewstyle: string;
    camera: string;
    extratime: string;
    type: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.messageinvitation = dataRow.attributes.messageinvitation?.data?.id || null;
        this.messageinvitationCaption = dataRow.attributes.messageinvitation?.data?.attributes.name || dataRow.attributes.messageinvitation?.data?.id;
        this.messageresult = dataRow.attributes.messageresult?.data?.id || null;
        this.messageresultCaption = dataRow.attributes.messageresult?.data?.attributes.name || dataRow.attributes.messageresult?.data?.id;
        this.reportgroup = dataRow.attributes.reportgroup?.data?.id || null;
        this.reportgroupCaption = dataRow.attributes.reportgroup?.data?.attributes.name || dataRow.attributes.reportgroup?.data?.id;
        this.viewstyle = dataRow.attributes.viewstyle || 'onebyone';
        this.camera = dataRow.attributes.camera || 'yes';
        this.extratime = dataRow.attributes.extratime || 'yes';
        this.type = dataRow.attributes.type || 'quiz';
    } 

    public static endPoint: string = 'event-types' 
    public static endPointFilter = ()=>{
       const owner = OwnerModel.getOwner();
        return ( '?populate=*&sort=name&filters[owner]='+owner);
    } 
    public static url: string = '/eventtype';
    public static caption: string = 'Event Type';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        messageinvitation: null,
        messageinvitationCaption: '',
        messageresult: null,
        messageresultCaption: '',
        reportgroup: null,
        reportgroupCaption: '',
        viewstyle: 'onebyone',
        camera: 'yes',
        extratime: 'yes',
        type: 'quiz',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionType = [
        { name: 'onlinemeeting', caption: 'Online Meeting' },
        { name: 'quiz', caption: 'Quiz' },
        { name: 'wedding', caption: 'Wedding' },
    ]

    public static optionViewstyle = [
        { name: 'onebyone', caption: 'Show one by one' },
        { name: 'all', caption: 'Show all' },
        { name: 'onebyone-canback', caption: 'Show one by one - Can back' },
        { name: 'all-canback', caption: 'Show all - Can back' },
        { name: 'onebyone-canback-editable', caption: 'Show one by one - Can back - Editable' },
        { name: 'all-canback-editable', caption: 'Show all - Can back - Editable' },
    ]

    public static optionCamera = [
        { name: 'yes', caption: 'Yes' },
        { name: 'no', caption: 'No' },
    ]

    public static optionExtratime = [
        { name: 'yes', caption: 'Yes' },
        { name: 'no', caption: 'No' },
    ]

}