import * as React from 'react';
import { Button as ButtonMui } from '@mui/material';
import { Icon, Text } from '.';

interface Props {
  id?: string;
  label?: string;
  variant?: any;
  color?: any;
  size?: 'small'|'medium'|'large';
  sx?: any;
  startIcon?: string;
  endIcon?: string;
  textCapitalize?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button: React.FunctionComponent<Props> = (props) => {
  const color = props.variant==='contained'? 'white':'';

  return (
    <ButtonMui 
      id={props.id}
      variant={props.variant}
      color={props.color || 'primary'}
      size={props.size}
      sx={{borderRadius: 30, padding: 2, ...props.sx}}
      startIcon={props.startIcon? <Icon name= {props.startIcon} color={color}/> : ''}
      endIcon={props.endIcon? <Icon name= {props.endIcon} color={color} /> : ''}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Text label={props.label} color={color} size='extrasmall' sx={{textTransform: props.textCapitalize? 'capitalize' : ''}} />
    </ButtonMui>
  );
};
