import * as React from 'react';
import { TextField } from '@mui/material';

interface Props {
  id?: string;
  name: string;
  label?: string;
  placeHolder?: string;
  value: string;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  autoSelect?: boolean;
  box?: boolean;
  color?: string;
  readOnly?: boolean;
  onBlur?: (fieldName?: string, value?: string) => void;
  onChange?: (fieldName: string, value: string) => void;
  onEndTyping?: (fieldName?: string, value?: string) => void;
  error?: string;
}

export const Input: React.FunctionComponent<Props> = (props) => {
  const [endTyping, setEndTyping] = React.useState<any>(null);

  const fontSize = () => {
    const sizes = {'extrasmall': 16, 'small': 18, 'medium': 24, 'large': 36, 'extralarge': 48};
    const size = sizes[props.size || 'small'];
    return ({xs: size-2, md: size})
  }

  const onBlur = (props: Props) => (e: React.FocusEvent<HTMLInputElement>) => { 
    props.onBlur?.(e.target.name, e.target.value);
  };

  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, e.target.value);
    
    if (props.onEndTyping) {
      clearTimeout(endTyping);
      setEndTyping(setTimeout(() => props.onEndTyping?.(e.target.name, e.target.value), 500));
    }
  };
  
  return (
    <TextField
      id={props.id || props.name}
      name={props.name}
      label={props.label}
      type='input'
      value={props.value || ''}
      placeholder={props.placeHolder}
      inputProps={{spellCheck: false}}
      InputProps={{
        readOnly: props.readOnly || false,
        sx: {fontSize: fontSize(), bgcolor: props.color, borderRadius: 3},
      }}
      InputLabelProps={{
        sx: {fontSize: fontSize()}
      }}
      margin='normal'
      variant={props.box? undefined : 'standard'}
      fullWidth
      helperText={props.error}
      error= {props.error? true: false}
      onBlur={onBlur(props)}
      onChange={onChange(props)}
      onFocus={e => {props.autoSelect && e.target.select();}}
    />
  )
};