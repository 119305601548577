import * as React from 'react';
import { History } from 'history';
import { Menu, List, ListItem, ListItemButton, ListItemIcon, Grid } from '@mui/material';
import { Icon, SwipeDrawer } from '.';
import AppService from '../services/app.service';

interface Props {
  history?: History;
  id?: string;
  anchorEl: HTMLElement | null,  
  action?: {
    caption: string, 
    icon?: string,
    color?: string,
    url?: string,
    state?: any,
    readOnly?: boolean,
  }[];
  onClose: () => void;
}

export const ActionButton: React.FunctionComponent<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(props.anchorEl);
  const [historyState, setHistoryState] = React.useState<any>(props.history?.location.state);
  
  const onClose = () => {    
    setAnchorEl(null);
    props.onClose();
  };

  const onClick = (action: any) => {
    props.history?.push(action.url, {...historyState, ...action.state});
  }

  const actions = () => {
    return (
      <List>
      {props.action?.map((d, i) => {
        if (d.url)
        return(
          <Grid key={i} item sx={{cursor: 'pointer', pointerEvents: (d.readOnly? 'none' : 'auto')}} onClick={()=>onClick(d)}>
            <ListItem disablePadding> <ListItemButton><ListItemIcon>{d.icon && <Icon name={d.icon} size='small' color={d.color || 'primary.dark'}/>}</ListItemIcon> {d.caption} </ListItemButton></ListItem>
          </Grid>
        );
      })}
      </List>
    );
  }
    
  React.useEffect(()=>{
    setAnchorEl(props.anchorEl)
  }, [props.anchorEl])

  return (
    <>
    {AppService.isMobile()?
      <SwipeDrawer variant='bottom' open={anchorEl? true : false}>
        {actions()}   
      </SwipeDrawer>
      
    : //Web view
      <Menu anchorEl={anchorEl} open={anchorEl? true : false} onClose={onClose}>
        {actions()}
      </Menu>
    }  
    </>
  )
};



