import * as React from 'react';
import { Container, Grid } from '@mui/material';
import { ParallaxScroll, Text, Transition, Trapezoid } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  section: {
    id?: string,
    seq?: number,
    color?: string,
    fullWidth?: boolean,
    disableMargin?: boolean,
    hide?: boolean,
    transition?: 'hideOnScroll'|'slide'|'fade'|'zoom'|'grow'|'growLeft',
    transitionTime?: number,
    transitionTrigger?: boolean,
    trapezoid?: 'topLeft'|'topRight'|'bottomRight'|'bottomLeft',
    background?: {
      image?: string,
      size?: string,
      position?: string,
      element?: React.ReactNode,
    },
    content?: {
      id?: string,
      seq?: number,
      caption?: {
        id?: string,
        seq?: number,
        text: string, 
        size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge'|'superlarge'|'superextralarge'|'extralarge3',
        color?: string,
        align?: 'left'|'center'|'right',
        bold?: boolean,
        textToHtml?: boolean,
        lineHeight?: number
        marginTop?: number,
        hide?: boolean,
      }[],
      captionPosition?: 'top'|'left'|'right'|'bottom',
      columnSize?: number,
      hide?: boolean,
      child?: React.ReactNode,
    }[],
  }[]
}

export const PageSection: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const [section, setSection] = React.useState<any>();
  var addTime: number = 0;
  
  const contentCaption = (section: any, content: any) => {
    return (
      <> 
      {content?.caption?.map((d: any, i: number) => { 
        addTime += (section.transitionTime || 500); 
        const textAlign = d.align || content.captionPosition === 'left'? 'right' : 'left';
        if (!d.hide)
        return ( 
          <Text key={i} label={d.text} size={d.size || 'extralarge'} color={d.color} textAlign={textAlign} bold={d.bold} textToHtml={d.textToHtml} lineHeight={d.lineHeight} marginTop={d.marginTop} transition={section.transition} transitionParentid={section.id} transitionTime={addTime} />
        ) 
      })} 
      </>
    )
  }

  const contentChild = (section: any, content: any) => {
    return (
      <Transition parentId={props.id} variant={section.transition} addTime={addTime += (section.transitionTime || 500)}>
        <Grid>{content.child}</Grid>
      </Transition>
    )
  }

  const layoutTopBottom = (section: any, content: any) => {
    return (
      <>
        {(!content.captionPosition || content.captionPosition === 'top') && contentCaption(section, content)}
        {contentChild(section, content)}
        {content.captionPosition === 'bottom' && contentCaption(section, content)}
      </>
    )
  }

  const layoutLeftRight = (section: any, content: any) => {
    return (
      <Grid container direction={content.captionPosition === 'left'? 'row' : 'row-reverse'} columnSpacing={2} alignItems='center' justifyContent='center'>   
        <Grid item xs={12} md={3} textAlign='right' mb={3}>
          {contentCaption(section, content)}
        </Grid>
        <Grid item xs={12} md={9}>
          {contentChild(section, content)}
        </Grid>
      </Grid>
    )
  }

  const child = (section: any) => {
    const contentLength = section.content?.filter((d: any) => (d.caption || d.child)).length;
    return (
      <Transition parentId={section.id} variant={section.transition} trigger={section.transitionTrigger}>
        <Grid container spacing={contentLength <= 1? 0 : (isMobile? 2: 5)} padding={!isMobile || contentLength <= 1? 0 : 1}>
          {section.content?.map((d: any, i:number) => {
            if (!d.hide)
            return(
              <Grid key={i} item xs={12} md={d.columnSize}>
                {d.captionPosition === 'left' || d.captionPosition === 'right'? layoutLeftRight(section, d) : layoutTopBottom(section, d)}
              </Grid>
            )
          })}
        </Grid>
      </Transition>
    )
  }

  const sectionUpdate = () => {
    //d0: prev data, d1: next data
    var d0: any;
    var sections = props.section;
    
    return sections?.filter((d: any) => d && !d.hide)
      .map((d: any, i: number, d1: any) => {
        if (d.trapezoid?.includes('top')) d.colorOther = d0?.color;
        else if (d.trapezoid?.includes('bottom')) d.colorOther = d1[i+1]?.color;
        d0 = d;
        return d; 
      });
  }

  React.useEffect(()=>{
    setSection(sectionUpdate())
  }, [props.section])

  return (
    <>
    {section?.map((d: any, i: number) => {
      addTime = 0;
      return (
        <Trapezoid key={i} id={d.id} position={d.trapezoid} color={d.color} colorOther={d.colorOther} fullWidth={!!d.background || (!d.background && d.fullWidth)} disableMargin={d.disableMargin}>
          {d.background? 
          <ParallaxScroll background={d.background} backgroundElement={d.background?.element}> {d.fullWidth? child(d) : <Container>{child(d)}</Container>} </ParallaxScroll>
          : child(d)
          }
        </Trapezoid>
      )
    })}
    </>
  )
  
}