import AppService from '../../services/app.service';

export default class MedialibraryModel {
    id: number;
    name: string;
    description: string;
    alt: string;
    url: string;
    ext: string;
    height: number;
    width: number;
    size: number;
    files: any;
    ref: string;
    field: string;
    refid: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.name;
        this.description = dataRow.caption;
        this.alt = dataRow.alt;
        this.url = dataRow.url;
        this.ext = dataRow.ext;
        this.height = dataRow.height;
        this.width = dataRow.width;
        this.size = dataRow.size;
        this.files= dataRow.files;
        this.ref= dataRow.ref;
        this.field= dataRow.field;
        this.refid= dataRow.refid;
    } 

    public static endPoint: string = 'upload';
    public static endPoint2: string = 'upload/files/';
    public static endPointFilter = '?populate=*&sort=name&filters[alternativeText]='+AppService.appId();
    public static url: string = '/medialibrary';
    public static caption: string = 'Media Library';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        alt:  '',
        url:  '',
        ext:  '',
        height:  0,
        width: 0,
        size: 0,
        files: null,
        ref: '',
        field: '',
        refid: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'url', caption: '', type:'media' },
    ]

    public static columnPicklist = [
        { name: 'description', caption: 'Description' },
        { name: 'url', caption: '', type:'media' },
    ]

}