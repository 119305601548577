import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PersonalinfoList from './personalinfo-list.component';
import PersonalinfoCreate from './personalinfo-create.component';
import PersonalinfoView from './personalinfo-view.component';
import PersonalinfoEdit from './personalinfo-edit.component';
import PersonalinfoCopy from './personalinfo-copy.component';
import PersonalinfoDelete from './personalinfo-delete.component';

interface Props {
  root?: string;
}

export const PersonalinfoRouteCustomer: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={`${props.root||''}/customer/create`} render={(params)=> <PersonalinfoCreate {...params} key={params.history.location.key} root={props.root} caption='Create Customer' personalinfotypeType='customer' />} />    
      <Route path={`${props.root||''}/customer/view`} render={(params)=> <PersonalinfoView {...params} key={params.history.location.key} root={props.root} caption='View Customer' personalinfotypeType='customer' />} />        
      <Route path={`${props.root||''}/customer/edit`} render={(params)=> <PersonalinfoEdit {...params} key={params.history.location.key} root={props.root} caption='Edit Customer' personalinfotypeType='customer' />} />        
      <Route path={`${props.root||''}/customer/copy`} render={(params)=> <PersonalinfoCopy {...params} key={params.history.location.key} root={props.root} caption='Duplicate Customer' personalinfotypeType='customer' />} />     
      <Route path={`${props.root||''}/customer/delete`} render={(params)=> <PersonalinfoDelete {...params} key={params.history.location.key} root={props.root} caption='Delete Customer'/>} />        
      <Route path={(props.root||'')+'/customer'} render={(params)=> <PersonalinfoList {...params} key={params.history.location.key} root={props.root} caption='Customer' personalinfotypeType='customer' />} /> 
    </Switch>
  );
};
