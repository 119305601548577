import React from 'react';
import { History } from 'history';
import { Grid } from '@mui/material';
import { Button, Dialog, Rating, Text } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './product-model';  //editable..
import ProductPicklistCard from './product-picklistcard.component';
import AppService from '../../services/app.service';
import TransactiondetailCreateByUser from '../transactiondetail/transactiondetail-create-byuser.component';
import ProducttypeModel from '../producttype/producttype-model';
import UserModel from '../user/user-model';
import UserAuthSignin from '../user/user-auth-signin.component';

interface IProps { 
    history: History;
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    openDialog: boolean;
}

export default class ProductViewByuser extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            openDialog: false,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
        document.getElementById('Top')?.scrollIntoView(false)
    }

    
    private soldWords = () => {
        return (
            'Sudah '+ProducttypeModel.optionType.filter((d: any) => d.name===this.state.dataRow.producttypeType)?.[0]?.description + ' 250+'
        )
    }

    private UserSignin() {
        return (
        <Dialog open={this.state.openDialog} onClose={()=>this.setState({openDialog: false})}>
            <UserAuthSignin history={this.props.history} onSave={()=>this.setState({openDialog: false})} disableBackButton disableAutoBack/>
        </Dialog>
        )
    }

    private onClick = () => {
        this.setState({ openDialog: true });
    }

    render() {
        
        return (
            //editable..
            <>
            <Grid id='Top'/>
            <BaseView history={this.props.history} caption={this.state.dataRow.name}
                model={Model} getDataRow={this.getDataRow}> 
                
                {this.UserSignin()}
                
                <Grid container alignContent='center' spacing={3} pt={5}>
                    <Grid item xs={12} md={3}>
                        <img src={this.state.dataRow.imageUrl} width='100%' height='auto' alt='img' style={{ borderRadius: 10}}/>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Text label={this.state.dataRow.name} size='large' bold marginTop={3} />
                        <Text label={this.soldWords()} />
                        <Rating name='rating' value={4} max={5}/>    
                        <Text label={this.state.dataRow.description} marginTop={3} sx={{marginBottom: 3}} />

                    </Grid>
                    <Grid item xs={12} md={4}>
                        {this.state.dataRow.pricesale>0 &&
                        <Text label={AppService.currencySymbol + ' ' + AppService.strToMoney(this.state.dataRow.pricesale.toString())} size='extralarge' bold displayBlock />
                        }
                        {this.state.dataRow.qty>0 &&
                        <Text label={'Masih tersedia ' + this.state.dataRow.qty.toString() + ' '+ this.state.dataRow.unit} icon='shopping_cart' iconSize='small' sx={{marginBottom: 5}} />
                        }
                        {!UserModel.isSignin()?
                        <Button label='Silahkan Login untuk lanjut transaksi' variant='outlined' textCapitalize onClick={this.onClick}/>
                        :
                        this.state.dataRow.id>0 && this.state.dataRow.pricesale>0 && this.state.dataRow.qty>0 &&
                        <TransactiondetailCreateByUser history={this.props.history} productId={this.state.dataRow.id}/> 
                        }
                    </Grid>
                 
                <Grid item mt={5} xs={12} md={12} borderTop={1} borderColor='silver'>
                    <Text label='Ulasan' size='large' color='primary.main' />
                    <Text label='Belum ada ulasan.' sx={{marginBottom: 8}} />
                </Grid>
                <Grid item mt={5} xs={12} md={12} borderTop={1} borderColor='silver'>
                    <ProductPicklistCard history={this.props.history} caption='Produk Lainnya' pricetype='pricesale' cardCount={6} swipe='horizontal' getDataRow={this.getDataRow}/>  
                </Grid>
                </Grid>    
            </BaseView>
            </>
        );
    }
}