import * as React from 'react';
import {Fade, Grow, Slide, Zoom, useScrollTrigger } from '@mui/material';

interface Props {
  id?: string;
  parentId?: string;
  variant?: 'hideOnScroll'|'slide'|'fade'|'zoom'|'grow'|'growLeft';
  addTime?: number;
  direction?:  'up'|'down'|'left'|'right' ;
  trigger?: boolean;
  disableTransition?: boolean;
  window?: () => Window;
  children: React.ReactElement;
}


export const Transition: React.FunctionComponent<Props> = (props) => {
  
  const [trigger, setTrigger] = React.useState(true);
  const hideOnScrollTrigger = useScrollTrigger({
    target: props.window ? props.window() : undefined,
  });

  const onScroll = React.useCallback((e: Event) => { 
    const parent = document.getElementById(props.parentId || '') ;
    const parentPos = parent?.getBoundingClientRect();
    setTrigger(parentPos? parentPos.top < window.innerHeight-50 : true)
  }, [props.parentId]);

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [onScroll])

  const hideOnScroll = () => {
    return (
      <Slide id={props.id} appear={false} direction={props.direction || 'up'}  in={!hideOnScrollTrigger} >
        {props.children}
      </Slide>
    )
  }   

  const slide = () => {
    return (
      <Slide id={props.id} direction={props.direction || 'up'} timeout={1000+(props.addTime||0)} in={props.trigger || trigger} mountOnEnter unmountOnExit>
        {props.children}
      </Slide>
    )
  }  
  
  const fade = () => {
    return (
      <Fade id={props.id} timeout={1000+(props.addTime||0)} in={props.trigger || trigger} mountOnEnter unmountOnExit>
        {props.children}
      </Fade>
    )
  }  
  
  const zoom = () => {
    return (
      <Zoom id={props.id} timeout={1000+(props.addTime||0)} in={props.trigger || trigger} mountOnEnter unmountOnExit>
        {props.children}
      </Zoom>
    )
  } 

  const grow = (from?: string) => {
    return (
      <Grow id={props.id} style={{ transformOrigin: from==='left'? '0 0 0': '' }} timeout={1000+(props.addTime||0)} in={props.trigger || trigger} mountOnEnter unmountOnExit>
        {props.children}
      </Grow>
    )
  }

  switch (props.variant) {
    case 'hideOnScroll': return (hideOnScroll());
    case 'slide': return slide();
    case 'fade': return fade();
    case 'zoom': return zoom();
    case 'grow': return grow('');
    case 'growLeft': return grow('left');
    default : return <>{props.children}</>;
  }

}