import React from 'react';
import { History } from 'history';
import { Grid } from '@mui/material';
import { FormHeader, Icon, Layout, Logo } from '../../components';
import UserModel from '../user/user-model';
import MenuPicklistCard from '../menu/menu-picklistcard.component';
import MenugroupPicklistCard from '../menugroup/menugroup-picklistcard.component';
import ApplayoutRoutes from './applayout-routes.component';
import AppService from '../../services/app.service';

interface IProps { 
  history: History;
  root?: string;
  caption?: string;
  sidebarRight?: boolean;
}

interface IState {
  historyState: any;
  sidebarCollapse: boolean;
}

export default class ApplayoutDashboard extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      historyState: null,
      sidebarCollapse: false,
    }
  }

  private historyState: any = this.props.history.location.state;
  private isMobile: boolean = AppService.isMobile();
  private isRootPath = () => this.props.history.location.pathname === this.props.root;

  private background = () => {
    return AppService.background({svgName: this.isRootPath()? 'waveAnimate' : ''});
  }
  private getDataRowMenugroup = (data: any) => {
    this.setState({ historyState: {menugroupId: data?.id, menugroupCaption: data?.name }});
  }
  
  private onClickSidebarCollapse = () => {
    this.setState({ sidebarCollapse: !this.state.sidebarCollapse });
    localStorage.setItem('sidebarCollapse', (!this.state.sidebarCollapse).toString());
  }

  public componentDidMount() {
    if (this.isMobile) localStorage.removeItem('sidebarCollapse');
    this.setState({historyState: this.historyState, sidebarCollapse: localStorage.getItem('sidebarCollapse') === 'true'});     
  }

  public componentDidUpdate() {
    AppService.historyState('historyLength', this.props.history.length);   
    AppService.historyState('historyLengthRoot', this.props.history.length, false, true);
    this.historyState = AppService.historyState();
    if (this.state.historyState !== this.historyState) this.setState({historyState: this.historyState});
  }

  render() {
    return (
      <Grid bgcolor={this.isMobile? 'background.default' :'background.paper'}>
        <Grid container justifyContent={this.props.sidebarRight? 'right' : 'left'}>
          <Grid container justifyContent='space-between' width={this.state.sidebarCollapse? 130 : 410} position='fixed' p={2}>
            <Logo label={this.state.sidebarCollapse? ' ' : ''} />
            {this.isMobile?
              this.isRootPath() && <FormHeader history={this.props.history} label={this.props.caption || 'Hai '+UserModel.getUserCaption()}/>
              : <Grid alignContent='right' sx={{cursor: 'pointer'}} onClick={this.onClickSidebarCollapse}><Icon name={this.state.sidebarCollapse? 'unfold_more' : 'unfold_less'} color='primary.main' rotate={90}/></Grid>
            }
          </Grid>
        </Grid>

        <Layout fixWidth={this.state.sidebarCollapse? 130 : 410} direction={this.props.sidebarRight? 'row-reverse' : 'row'} content={[
          {caption: '', disableCard: true, hide: this.isMobile && !this.isRootPath(), child: 
            <Grid width='100%' mt={15} paddingLeft={1}>
              {this.state.historyState?.menugroupId?
              <MenuPicklistCard history={this.props.history} root={this.props.root} caption={this.state.sidebarCollapse? ' ' : this.state.historyState?.menugroupCaption} menugroupId={this.state.historyState.menugroupId} cardCount={this.state.sidebarCollapse? 1 : 2} cardIconOnly={this.state.sidebarCollapse}/>
              : <MenugroupPicklistCard history={this.props.history} root={this.props.root} caption=' ' getDataRow={this.getDataRowMenugroup} cardCount={1} cardIconOnly={this.state.sidebarCollapse}/>
              }
            </Grid>
          },

          {caption: '', disableCard: true, hide: this.isMobile && this.isRootPath(), child: 
            <Grid width='100%' minHeight='98vh' borderLeft={this.isMobile? 0 : 1} borderTop={this.isMobile? 0 : 1} borderColor='white' sx={{ ...this.background(), backgroundColor: 'background.default', borderTopLeftRadius: this.isMobile || !this.props.sidebarRight? 50 : 0, borderBottomLeftRadius: this.isMobile || !this.props.sidebarRight? 50 : 0, borderTopRightRadius: this.isMobile || this.props.sidebarRight? 50 : 0, borderBottomRightRadius: this.isMobile || this.props.sidebarRight? 50 : 0}} p={this.isMobile? 0 : 5} mt={this.isMobile? 10: 1} >
                {this.isRootPath()?
                  <FormHeader history={this.props.history} label={this.props.caption || 'Hai '+UserModel.getUserCaption()} margin={2}/>
                : <ApplayoutRoutes history={this.props.history} root={this.props.root}/>
                }
                
            </Grid>
          },
        ]}
        />         
      </Grid>
    );
  }
}