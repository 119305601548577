import * as React from 'react';
import { Grid } from '@mui/material';

interface Props {
  id?: string;
}

export const Spinner: React.FunctionComponent<Props> = (props) => {
  const spinner1 = 'spinner-grow spinner-grow-sm text-muted';
  const [spinner2, setSpinner2] = React.useState('');
  const [spinner3, setSpinner3] = React.useState('');

  setTimeout(() => { setSpinner2(spinner1)}, 200);
  setTimeout(() => { setSpinner3(spinner1)}, 400);
  
  return (
    <Grid p={1}>
      <Grid className={spinner1} bgcolor='primary.light' sx={{animationDelay:'0.0s', animationDuration: '1s'}} mr={1}/>
      <Grid className={spinner2} bgcolor='primary.main' sx={{animationDelay:'0.2s', animationDuration: '1s'}} mr={1}/>
      <Grid className={spinner3} bgcolor='primary.dark' sx={{animationDelay:'0.4s', animationDuration: '1s'}} mr={1}/>
    </Grid>
  )
    
};



