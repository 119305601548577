import React from 'react';
import { History } from 'history';
import { Grid } from '@mui/material';
import { Card, Input, InputEmail, InputFile } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './user-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import PersonalinfoEditByuser from '../personalinfo/personalinfo-edit-byuser.component';
import BaseView from '../base/base-view.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class UserAuthProfileEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        var email = this.state.dataRow.email;
        if (fieldName === 'useremail') email = Model.getEmailApp(value);

        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, email: email,
            }
        };
        this.setState(nextState);
    }


    render() {
        return (
            //editable..
            <BaseView history={this.props.history} caption='Change Profile'
                model={Model} getDataRow={this.getDataRow} disableCard> 

                <Grid container direction='row' spacing={1}>
                <Card color='primary.light' gradientColor='white'>
                <BaseEdit history={this.props.history}
                    model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError} disableBackButton disableAutoBack disableCard>                                    
                    <Grid container direction='row' spacing={2}>
                        <Grid item xs={12} md={4} >
                        <InputFile name='imageUrl' label='Avatar' value={this.state.dataRow.imageUrl} type='image' getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onChange} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Input name='name' label='User Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                            <InputEmail name='useremail' label='User Email' value={this.state.dataRow.useremail} readOnly/>
                            
                        </Grid>
                    </Grid>
                </BaseEdit>
                {this.state.dataRow.personalinfo &&
                <PersonalinfoEditByuser history={this.props.history} id={this.state.dataRow.personalinfo} />
                }
                </Card>
                </Grid>
            </BaseView>
        );
    }
}