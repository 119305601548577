import * as React from 'react';
import {decode as decodeBase64} from 'base-64'; 
import WebcamReact from 'react-webcam';
import { Grid, IconButton } from '@mui/material';
import { Icon, Transition } from '.';


interface Props {
  id?: string;
  audio?: boolean;
  shotTimeout?: number;
  hideCam?: boolean;
  stop?: boolean;
  height?: number;
  width?: number;
  getImage?: (data: any) => void;
  getPermission?: (data: any) => void;
}

export const Webcam: React.FunctionComponent<Props> = (props) => {

    const videoConstraints = { aspectRatio: 0.6666666667, facingMode: 'user', height: props.height || 100, width: props.width || 100 };
    const [img, setImg] = React.useState<string|null>(null);
    const webcamRef = React.useRef<WebcamReact>(null);
    
    const captureImage = React.useCallback(() => {   

      const image = webcamRef.current?.getScreenshot()||null;
      setImg(image);

      const convertToUint8 = (data: string) => {
        const decodeResult = decodeBase64(data);
        var n = decodeResult.length;
        const u8arr = new Uint8Array(n);
        while(n--){ u8arr[n] = decodeResult.charCodeAt(n); }
        return u8arr;
      }

      const imgSplit = { 
        value : image,
        mime  : image?.split(',')[0].split(':')[1].split(';')[0],
        bytes : image?.split(',')[1],
        uint8 : convertToUint8(image?.split(',')[1] || '')
      };
      props.getImage?.(imgSplit);
    }, [props, webcamRef]);
    
    const displayCam = () => {
      if (props.stop) return ;
      
      props.shotTimeout && setTimeout(captureImage, props.shotTimeout)
      return (
        <Grid id={props.id} container sx={props.hideCam ? {position: 'fixed', left: -(props.width || 100)}: {}}>
          {img?
            <Transition variant='zoom' addTime={-900} trigger={img !== null}>
              <img src={img} alt="screenshot" />
            </Transition>
          : 
            
            <WebcamReact
              audio={props.audio || false}
              muted={false}
              mirrored={true}
              ref={webcamRef}
              screenshotFormat="image/png"
              videoConstraints={videoConstraints}
              width={props.width}
              height={props.height}
              
            />
          }
          <Grid  sx={{ position: 'absolute', backgroundColor:'primary.main', borderRadius:'50%'}} >
            <IconButton color='default' onClick={img? () => setImg(null) : captureImage}>
                <Icon name={img? 'photo_camera_outlined' : 'photo_camera'}/>
            </IconButton>
          </Grid>
        </Grid>
      );
    }

    const getPermission = () => {
      props.getPermission?.(null);
      navigator.mediaDevices?.enumerateDevices?.().then((response) => { 
        if (!response[0].deviceId) {
          navigator.mediaDevices.getUserMedia({ video: true })
          .then(response => {
              response?.getTracks().forEach((d: any) => {d.stop();  props.getPermission?.(true); }) 
          })
          .catch( error => { props.getPermission?.(false); })
        }
        else  props.getPermission?.(true); 
      });
    }
    
    React.useEffect( () =>  {
      if (props.getPermission) getPermission();
    }, [props.getPermission]);

    return (
      <>
      {displayCam()}
      </>
    )
};
