import React from 'react';
import { History } from 'history';
import { Input, InputFile, InputNumber, InputMultiline, InputSelect, InputSwitch, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseView from '../base/base-view.component';
import Model from './report-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import OwnerPicklist from '../owner/owner-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class ReportCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private historyState: any = this.props.history.location.state;

    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };
    
    private getDataRowParent = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'parent': data?.id || null, 'parentCaption': data?.name || ''  }  });
    };
    
    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{files: data, ref: Model.endPointMedia, field:'image'}}});
    }
    ;
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    };
    
    private onChange(fieldName: string, value: string) { 
        var  option = this.state.dataRow.option;
        if (fieldName==='type' && !this.state.dataRow.parent) option = Model.option[value];        

        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, option: option,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..      
            <>     
            {this.historyState?.id>0 &&
            <BaseView history={this.props.history} caption=''
                model={Model} getDataRow={this.getDataRowParent}/>
            }        
            <BaseCreate history={this.props.history} caption={this.state.dataRow.parent && 'Create '+Model.caption+' Section'}
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataError={this.getDataError} disableAutoBack>              
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                } 
                {this.historyState?.id>0 &&
                <Input name='parentCaption' label='Parent' value={this.state.dataRow.parentCaption} readOnly/>
                }
                <Input name='title' label='Title' value={this.state.dataRow.title} error={this.state.dataError?.title} onChange={this.onChange} />
                <InputMultiline name='content' label='Content' value={this.state.dataRow.content} error={this.state.dataError?.content} onChange={this.onChange} />
                <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} error={this.state.dataError?.seq} onChange={this.onChange} />
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={this.state.dataRow.parent? Model.optionTypeSection : Model.optionType} error={this.state.dataError?.type} onChange={this.onChange} />
                <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onChange} />
                <Input name='imagetitle' label='Image Title' value={this.state.dataRow.imagetitle} error={this.state.dataError?.imagetitle} onChange={this.onChange} />

            </BaseCreate>
            </>
        );
    }     
     
}