import React from 'react';
import { History } from 'history';
import { InputMoney, InputMultiline, InputNumber, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseView from '../base/base-view.component';
import Model from './transactiondetail-model';  //editable..
import TransactionModel from '../transaction/transaction-model';
import ProductModel from '../product/product-model';
import ProductPicklist from '../product/product-picklist.component';
import ProductList from '../product/product-list.component';
                
interface IProps { 
    history: History;
    transactionId?: number;
    productId?: number;
    productQty?: number;
    save?: boolean;
    onSave?: (data: any) => void;
    disableView?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactiondetailCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        
        this.onChange = this.onChange.bind(this);
    }
    
    private historyState: any = this.props.history.location.state;
    private validations = [...Model.validations, {}];

    private getDataRowTransaction = (data:any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 
            'transaction': data?.id || null, 
            'transactiontype': data?.transactiontype || null, 
            'transactiontypePricetype': data?.transactiontypePricetype || null}  
        });
        
    }
    
    private getDataRowProduct = (data: any) => {
        const price = this.state.dataRow.transactiontypePricetype==='pricesale'? data?.pricesale : data?.pricebase;
        this.setState({ dataRow: { ...this.state.dataRow, 'product': data?.id || null, 'productCaption': data?.name || '', 
            'price': price, 'value': price * this.state.dataRow.qty, 'productLength': data?.product.length }  
        });
        this.validations.splice(1, 1, { name: 'qty', maxValue: data?.qty });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onSave = (data: any) => {
        this.props.onSave?.(data)
    }
    
    private onChange(fieldName: string, value: string) { 
        var  valueField = this.state.dataRow.value;
        if (fieldName==='price') valueField= this.state.dataRow.qty * Number(value)
        else if (fieldName==='qty') valueField= this.state.dataRow.price * Number(value)
        
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, value: valueField,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(): void {        
        this.getDataRowTransaction({'id': this.props.transactionId || this.historyState?.id});
        if (this.props.productQty)
            this.setState({ dataRow: { ...this.state.dataRow, 'qty': this.props.productQty}})
    }

    render() {
        return (
            //editable..
            <>
            {(this.props.transactionId || this.historyState?.id>0) &&
            <BaseView history={this.props.history} id={this.props.transactionId} caption=''
                model={TransactionModel} getDataRow={this.getDataRowTransaction}/>
            }
            {(this.props.productId) &&
            <BaseView history={this.props.history} id={this.props.productId} caption=''
                model={ProductModel} getDataRow={this.getDataRowProduct}/>
            }
            <BaseCreate history={this.props.history} caption={this.props.disableView? ' ' : ''} buttonCaption={this.props.disableView? ' ' : ''} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} validations={this.validations} save={this.props.save && this.state.dataRow.transaction} onSave={this.onSave} disableAutoBack={this.props.disableView} disableBackButton={this.props.disableView} disableCard={this.props.disableView}>               

                {!this.props.disableView &&
                <>
                <Picklist name='product' label='Product' value={this.state.dataRow.product} caption={this.state.dataRow.productCaption} error={this.state.dataError?.product} onChange={this.onChange} >
                    <ProductPicklist currentId={this.state.dataRow.product} pricetype={this.state.dataRow.transactiontypePricetype} getDataRow={this.getDataRowProduct}/>
                </Picklist>                 
                <InputMoney name='price' label='Price' value={this.state.dataRow.price} error={this.state.dataError?.price} onChange={this.onChange} />
                <InputNumber name='qty' label='Qty' value={this.state.dataRow.qty} error={this.state.dataError?.qty} onChange={this.onChange} />
                <InputMoney name='value' label='Total' value={this.state.dataRow.price * this.state.dataRow.qty} error={this.state.dataError?.value} onChange={this.onChange} />
                <InputMultiline name='note' label='Note' value={this.state.dataRow.note} error={this.state.dataError?.note} onChange={this.onChange} />
                {this.state.dataRow.productLength>0 &&
                    <ProductList history={this.props.history} caption='Sub Product' parentId={this.state.dataRow.product} actions={[]} disableBackButton/>
                }    
                </>
                }
            </BaseCreate>
            </>
            
        );
    }     
     
}