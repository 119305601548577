import React from 'react';
import { History } from 'history';
import { Input, InputFile, InputMultiline, InputNumber, InputSelect, InputSwitch } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './question-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class QuestionCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private getDataRow = (data:any) => {
        this.setState({dataRow:  {...data, id: null, image: null, imageUrl: ''}});
    } 

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{files: data, ref: Model.endPointMedia, field:'image'}}});
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }


    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/> 
                <Input name='title' label='Title' value={this.state.dataRow.title} error={this.state.dataError?.title} onChange={this.onChange} />
                <InputMultiline name='content' label='Content' value={this.state.dataRow.content} error={this.state.dataError?.content} onChange={this.onChange} />
                <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onChange} />
                <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} error={this.state.dataError?.seq} onChange={this.onChange} />
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} error={this.state.dataError?.type} onChange={this.onChange} />
                
            </BaseCopy>
        );
    }
}