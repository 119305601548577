import * as React from 'react';
import { History } from 'history';
import { Grid } from '@mui/material';
import { Text } from '.';
import AppService from '../services/app.service';

interface Props {
  history?: History;
  id?: string;
  label?: string;
  color?: string;
  margin?: number;
  thin?: boolean;
  disableBackButton?: boolean;
  children?: React.ReactNode;
}

export const FormHeader: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();

  const onClick = () => {
    props.history?.goBack();
  }

  return (
    <Grid id={props.id} container mt={props.margin || 1} mb={props.margin || (props.label?.trim()==='' || isMobile? 1 : 3)}>
      <Grid item sx={{cursor: 'pointer', pointerEvents: (props.disableBackButton? 'none' : 'auto')}} onClick={onClick}>
        <Text label={props.label} color= {props.color || 'text.primary'} size={!isMobile && props.thin? 'medium' : 'large'} lineHeight={1.2} icon={props.disableBackButton? '' : 'west'} />
      </Grid>
      <Grid item xs md textAlign='right'>
        {props.children}
      </Grid>
    </Grid>
  );
};
