import * as React from 'react';
import {Icon as IconMui} from '@mui/material';

interface Props {
  id?: string;
  name: string;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  color?: string;
  marginRight?: boolean;
  rotate?: number;
}

export const Icon: React.FunctionComponent<Props> = (props) => {
  const iconSize = {'extrasmall': 16, 'small': 24, 'medium': 36, 'large': 48, 'extralarge': 56};
  const url = props.name.includes('icons8')? `https://img.icons8.com/3d-fluency/94/${props.name.split(':')[1]}.png` : '';
  
  return (
    <> 
    {url?
      <img src={url} height={ iconSize[props.size || 'medium']} width={ iconSize[props.size || 'medium']} alt={props.name} style={{marginRight: 10}} />
    : <IconMui id={props.id || props.name} sx={{ fontSize: iconSize[props.size || 'medium'], color: props.color, verticalAlign: 'middle', marginRight: props.marginRight? 1 : 0, transform: 'rotate('+props.rotate+'deg)',}}>{props.name.toLowerCase()}</IconMui>
    }</>
  )
  
}