import * as React from 'react';
import { Grid, Switch as SwitchMui } from '@mui/material';
import { Chip, Text } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  name: string;
  label?: string;
  value: string;
  defaultTrue: string;
  defaultFalse: string;
  readOnly?: boolean;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
}

export const InputSwitch: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const [value, setValue] = React.useState('');
  const [isTrue, setIsTrue] = React.useState(false);
  const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked?  props.defaultTrue : props.defaultFalse);
    props.onChange?.(props.name, e.target.checked?  props.defaultTrue : props.defaultFalse);
  };

  React.useEffect(() => {
    setValue(props.value);
    setIsTrue(value === props.defaultTrue);

  },[props.value, value])

  return (
    <Grid id={props.id || props.name} mt={3} mb={3}>
        <Text label={props.label} size='extrasmall' color='text.secondary' marginTop={1} sx={{mb: 1}} />
        {props.readOnly?
          <Chip label={AppService.strCapitalize(value)} color={isTrue? 'primary' : 'secondary'}/>
        : <>
          <SwitchMui
            checked={isTrue}
            disabled={props.readOnly}
            onChange={onChangeSwitch}
          /> 
          {AppService.strCapitalize(value)}
          </>
        }
        <Text label={props.error} size='extrasmall' color='error'/>
    </Grid>
  )
};