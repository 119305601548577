import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputNumber, InputMoney, InputSwitch, Picklist, InputFile } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './product-model';  //editable..
import ProductChecklist from './product-checklist.component';
import AmounttypeChecklist from '../amounttype/amounttype-checklist.component';
import AppService from '../../services/app.service';
interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class ProductView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} readOnly/>
                }
                <Picklist name='producttype' label='Product Type' value={this.state.dataRow.producttype} caption={this.state.dataRow.producttypeCaption} readOnly/> 
                <Input name='unit' label='Unit' value={this.state.dataRow.unit} readOnly/>
                <InputNumber name='qty' label='Qty' value={this.state.dataRow.qty} readOnly/>
                <InputNumber name='qtymin' label='Qty Min' value={this.state.dataRow.qtymin} readOnly/>
                <InputNumber name='qtymax' label='Qty Max' value={this.state.dataRow.qtymax} readOnly/>
                <InputMoney name='pricebase' label='Price Base' value={this.state.dataRow.pricebase} readOnly/>
                <InputMoney name='margin' label='Margin' value={this.state.dataRow.margin} readOnly/>
                <Picklist name='amounttype' label='Addional Value List' value={this.state.dataRow.amounttype} caption={this.state.dataRow.amounttypeCaption} disableAutoClose>
                    <AmounttypeChecklist history={this.props.history} currentId={this.state.dataRow.amounttype} readOnly disableBackButton/>
                </Picklist>
                <InputMoney name='totalamount' label='Total Addional Values' value={this.state.dataRow.totalamount} readOnly/>
                <InputMoney name='pricesale' label='Price Sale' value={this.state.dataRow.pricesale} readOnly/>
                <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' readOnly/>
                <Picklist name='product' label='Sub Product List' value='' caption={this.state.dataRow.productCaption} readOnly={this.state.dataRow.product? false: true} disableAutoClose>
                    <ProductChecklist history={this.props.history} currentId={this.state.dataRow.product} readOnly disableBackButton/>
                </Picklist>  
                <Picklist name='journalgroup' label='Journal group' value={this.state.dataRow.journalgroup} caption={this.state.dataRow.journalgroupCaption} readOnly/>
                {this.state.dataRow.producttypeType==='events' &&
                <Picklist name='event' label='Event' value={this.state.dataRow.event} caption={this.state.dataRow.eventCaption} readOnly/>
                }
            </BaseView>
        );
    }
}