import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputFile, InputMultiline, InputSelect, InputSwitch, Picklist } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './message-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import AppService from '../../services/app.service';
import OwnerPicklist from '../owner/owner-picklist.component';

interface IProps { 
    history: History;
    id?: number;
    caption?: string;
    successCaption?: string;
    astemplate?: string;
    dataRow?: any;
    save?: boolean;
    onSave?: (data: any) => void;
    getDataError?: (data: any) => void;
    disableView?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
    save: boolean;
}

export default class MessageCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: {...Model.defaultValue(), astemplate: this.props.astemplate || 'no', sent: this.props.astemplate==='yes'? 'no' : 'yes', },
            dataError: null,
            dataRowMedia: null,
            save: false,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data, save: false});
        this.props.getDataError?.(data)
    }

    private getFiles = (data:any) => {
        this.setState({
            dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow: {id: this.state.dataRow.attachmentId, files: data, ref: Model.endPointMedia, field:'attachments'}},
            dataRow: {...this.state.dataRow, 'attachments': data}, 
        });
    }

    private onSave = (data: any) => {
        this.setState({save: false});
        this.props.onSave?.(data)
    }
    
    private onChange(fieldName: string, value: string) {
        var  html = this.state.dataRow.html;
        if (fieldName==='text') html = this.state.dataRow.text.replaceAll('\n','<br/>');

        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, html: html,
            }
        };
        this.setState(nextState);
    }

    public componentDidUpdate() {
        if (this.props.save && this.props.save!==this.state.save && this.props.dataRow) {
            const dataRowMedia = this.props.dataRow.attachments?.name? {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow: {id: this.props.dataRow.attachmentId, files: this.props.dataRow.attachments, ref: Model.endPointMedia, field:'attachments'}} : null;
            this.setState({ dataRow: this.props.dataRow, dataRowMedia: dataRowMedia, save: true });
        }
    }

    render() {

        return (
            //editable..
            <BaseCopy history={this.props.history} id={this.props.id} caption={this.props.disableView? ' ' : this.props.caption} buttonCaption={this.props.disableView? ' ' : ''} successCaption={this.props.successCaption}
                endPoint={Model.endPoint} model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError} save={this.state.save} onSave={this.onSave} disableAutoBack={this.props.disableView} disableBackButton={this.props.disableView} disableCard={this.props.disableView}>              
                {!this.props.disableView && 
                <>
                    <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                    <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                    <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                    {AppService.isAppFullVersion() &&
                    <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                        <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                    </Picklist>
                    }
                    <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} error={this.state.dataError?.type} onChange={this.onChange} />
                    {this.props.astemplate!=='yes' &&
                    <InputSwitch name='sent' label='Send Now?' value={this.state.dataRow.sent} defaultTrue='yes' defaultFalse='no' error={this.state.dataError?.sent} onChange={this.onChange}/>
                    }
                    {this.state.dataRow.type==='email'?
                    <>
                    <InputEmail name='to' label='To' value={this.state.dataRow.to} error={this.state.dataError?.to} onChange={this.onChange} />
                    <InputEmail name='cc' label='Cc' value={this.state.dataRow.cc} error={this.state.dataError?.cc} onChange={this.onChange} />
                    <InputEmail name='bcc' label='Bcc' value={this.state.dataRow.bcc} error={this.state.dataError?.bcc} onChange={this.onChange} />
                    <InputEmail name='replyto' label='Reply to' value={this.state.dataRow.replyto} error={this.state.dataError?.replyto} onChange={this.onChange} />
                    </>
                    : 
                    <Input name='to' label='To' value={this.state.dataRow.to} error={this.state.dataError?.to} onChange={this.onChange} />
                    }
                    <Input name='subject' label='Subject' value={this.state.dataRow.subject} error={this.state.dataError?.subject} onChange={this.onChange} />
                    <InputMultiline name='text' label='Text' value={this.state.dataRow.text} error={this.state.dataError?.text} onChange={this.onChange} />
                    <InputFile name='attachmentUrl' label='Attachment' value={this.state.dataRow.attachmentUrl} getFiles={this.getFiles} encode error={this.state.dataError?.attachments} onChange={this.onChange} />
                </>
                }
            </BaseCopy>
        );
    }
}