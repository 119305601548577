import React, {Suspense} from 'react';
import { History } from 'history';
import { AppBar, Button, Card, Dialog, Logo, PageSection, Table } from '../../components';
import AppModel from '../app/app-model';
import OwnerModel from '../owner/owner-model';
import UserModel from '../user/user-model';
import BaseView from '../base/base-view.component';
import UserAuthSignin from '../user/user-auth-signin.component';
import EventPicklistCard from '../event/event-picklistcard.component';
import ProductgroupListProduct from '../productgroup/productgroup-list-product.component';
import AppService from '../../services/app.service';

interface IProps {
  history: History;
  section?: {
    type: string,
    name: string,
    content: Array<any>,
  }[]
}

interface IState {
  transitionTrigger: boolean;
  dataRowApp: AppModel;
  dataRowOwner: OwnerModel;
  dataRowUser: UserModel;
  dataSetSection: Array<any>;
  eventLength: number;
  openDialog: boolean;
}

export default class ApplayoutHome extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      transitionTrigger: true,
      dataRowApp: AppModel.defaultValue(),
      dataRowOwner: OwnerModel.defaultValue(),
      dataRowUser: UserModel.defaultValue(),
      dataSetSection: [],
      eventLength: 0,
      openDialog: false,
    }
  }

  
  private historyState: any = this.props.history.location.state;
  private isMobile: boolean = AppService.isMobile();
  private imageDefault = process.env.PUBLIC_URL + '/assets/appbg.png';

  private getDataRowApp = (data:any) => {
    this.setState({dataRowApp: data})
  }

  private getDataRowOwner = (data:any) => {
    this.setState({dataRowOwner: data})
  }

  private getDataRowUser = (data:any) => {
      this.setState({dataRowUser: data})
      UserModel.setUserImage(data?.imageUrl || '')
  }

  private getDataRowEvent = (data: any) => {
    AppService.historyState('sectionId', 'sectionEvent');
    this.props.history.push('/event', {id: data.id});
  };

  private getDataSetEvent = (data: any, pageInfo: any) => {
    this.setState({ eventLength: pageInfo.total})
  };

  private getDataRowProduct = (data: any) => {
    AppService.historyState('sectionId', 'sectionProduct');
    this.props.history.push('/product', {id: data.id});
  };

  private getDataSetSection = (data: any) => {
    this.setState({dataSetSection: data})
  };

  private menus() {
    return ([
      {caption: UserModel.isSignin()? 'Event' : '', section: 'sectionEvent', icon: 'event', badge: this.state.eventLength}, 
      {caption: 'Produk', section: 'sectionProduct', icon: 'inventory'}, 
      {caption: 'Konselor', section: 'sectionMentor', icon: 'face_3'}, 
      {caption: UserModel.isSignin()? 'Dashboard' : '', url: '/dashboard', icon: 'widgets'}
    ])
  };

  private home() {
    const Component = React.lazy(() => import(`./applayout-home0${AppService.appId()}.component`).catch(()=>{return ({default: ()=>{}})} ));
    
    return (
      <Suspense> <Component history={this.props.history} getDataSetSection={this.getDataSetSection} /> </Suspense>
    )
  }

  private UserSignin() {
    return (
      <Dialog open={this.state.openDialog} onClose={()=>this.setState({openDialog: false})}>
        <UserAuthSignin history={this.props.history} root='/' onSave={()=>this.setState({openDialog: false})} disableBackButton disableAutoBack/>
      </Dialog>
    )
  }
  
  private mergeSection(section: any, sectionExt: any, deep?: boolean) {
    const isObject = (object: any) => (typeof object === 'object' && object!==null);
    const readObject = (d: any, x: any) => {
      Object.keys(d).map((d1: any) => {
        if (x?.[d1]) {
          var dd1 = d[d1];
          if (isObject(dd1) && isObject(x[d1]))
            dd1 = this.mergeSection(dd1, x[d1], true);
          else if (!Array.isArray(dd1) && !Array.isArray(x[d1]))
            dd1 = x[d1];
          d = Object.assign({}, d, {[d1]: dd1});
        }
        return;
      })
      return d;
    }
    
    if (!sectionExt || sectionExt.length === 0) return;
    
    if (!Array.isArray(section) || !Array.isArray(sectionExt))
      return (Object.keys(section).filter((d1: any) => isObject(section[d1])).length>0)?
        readObject(section, sectionExt) : Object.assign({}, section, sectionExt);
    
    return section?.map((d: any, i: number) => {
        const dx: any = sectionExt?.filter((d1: any) => d.id === d1.id)?.[0];
        if (deep || (!deep && dx)) return readObject(d, dx)
      })
      .sort((a: any, b: any) => (a.seq+1 || a.id) > (b.seq+1 || b.id)? 1 : -1);
  }
  
  private pageSection() {
    return  [
      {seq: 0, id: 'sectionHeader', color: 'background.paper', transition: 'grow', transitionTrigger: true, trapezoid: 'bottomLeft', disableMargin: true, 
      background: {image: this.imageDefault, size: 'cover'},
      content: [
        {id: 0, caption: [
          {id: 0, text:'', color: 'primary.main', size: 'superlarge', bold: true, textToHtml: true, lineHeight: 1, marginTop: this.isMobile? 15 : 30}, 
          {id: 1, text:'', color: 'primary.main', size: 'medium', textToHtml: true, lineHeight: 1, marginTop: this.isMobile? 2 : 5},
          ]},
        {id: 1, child: <Button variant='contained' label='' onClick={()=>document.getElementById('product')?.scrollIntoView(false)}/> },
        {id: 2, caption: [{text: '', marginTop: this.isMobile? 0 : 10}]},
        {id: 3, child:
          <Card borderWidth={0} borderRadius={this.isMobile? 5 : 30} padding={this.isMobile? 0 : 5}>
            <Table header={null} dataSet={null} cardCount={3} cardView cardTextAlign='center' cardBorderWidth={0} swipe='horizontal' isReady readOnly />
          </Card>
          },
        {id: 4, caption: [{text: '', marginTop: this.isMobile? 20 : 30}]},
      ]},

      {seq: 1, id: 'sectionEvent', color: 'primary.light', transition: 'growLeft', transitionTrigger: this.state.transitionTrigger, hide: !UserModel.isSignin(), 
      content: [
        {id: 0, child: 
        <>
        {UserModel.isSignin() && 
          <Card background={AppService.background({svgName: 'waveAnimate'})}>
            <EventPicklistCard history={this.props.history} caption='' getDataRow={this.getDataRowEvent} getDataSet={this.getDataSetEvent}/>
          </Card>
        }
        </>}
      ]},

      {seq: 2, id: 'sectionProduct', color: 'white', transition: 'growLeft', transitionTrigger: this.state.transitionTrigger,
      content: [
        {id: 0, child: <ProductgroupListProduct history={this.props.history} getDataRow={this.getDataRowProduct} />},
      ]},

      {seq: 3, id: 'sectionMentor', color: 'primary.light', transition: 'grow', transitionTrigger: this.state.transitionTrigger,
      content: [
        {id: 0, caption: [
          {id: 0, text: '', size: 'superlarge', textToHtml: true, lineHeight: this.isMobile? 1.2 : 1.1, marginTop: 2}, 
          ], 
          captionPosition: 'right',  
          child: <Table header={null} dataSet={null} cardCount={3} cardSpacing={1} cardView swipe='horizontal' isReady readOnly />
          }
      ]},
      
      {seq: 999, id: 'sectionFooter', color: 'secondary.light', transition: 'growLeft', transitionTrigger: this.state.transitionTrigger, transitionTime: 200, trapezoid: 'topLeft', fullWidth: true, 
      content: [
        {id: 0, caption: [
            {text: this.state.dataRowApp.description, size: 'small', color:'primary.main', marginTop: 2}, 
            {text: this.state.dataRowOwner.description, size: 'small', bold: true, marginTop: 5}, 
            {text: this.state.dataRowOwner.address, size: 'extrasmall'},
            ], 
          captionPosition: 'bottom',
          columnSize: 4, 
          child: <Logo/>
          },  
        {id: 1, caption: [
            {text: 'Hubungi Kami', size: 'medium', bold: true}, 
            {text: 'Call Center', size: 'extrasmall', marginTop: 2}, {text: this.state.dataRowOwner.phone, color:'primary.dark', size: 'extrasmall'}, 
            {text: 'Email', size: 'extrasmall', marginTop: 2}, {text: this.state.dataRowOwner.email, color:'primary.dark', size: 'extrasmall'},
            {text: 'Instagram', size: 'extrasmall', marginTop: 2}, {text: '', size: 'extrasmall', color:'primary.dark'}, 
            {text: 'Facebook', size: 'extrasmall', marginTop: 2}, {text: '', size: 'extrasmall', color:'primary.dark'},
            ],
          columnSize: 2,
          }, 
        {id: 2, caption: [
            {text: 'Kebijakan', size: 'medium', bold: true}, 
            {text: 'Kebijakan Pribadi', size: 'extrasmall', marginTop: 2}, {text: '', size: 'extrasmall', color:'primary.dark'}, 
            {text: 'Syarat & Ketentuan', size: 'extrasmall', marginTop: 2}, {text: '', size: 'extrasmall', color:'primary.dark'},
            {text: 'Sering ditanyakan', size: 'extrasmall', marginTop: 2}, {text: '', size: 'extrasmall', color:'primary.dark'},
            ],
          columnSize: 2, 
          },
        {id:3, caption: [{text: '', marginTop: 5}]},
      ]}  
    ]
  }

  private onClickAppBar = () =>{
    setTimeout(() => this.setState({ transitionTrigger: true}), 100);
  }

  private onScrollEndAppBar = () =>{
    this.setState({ transitionTrigger: false })
  }

  private onClickAvatar = () =>{
    if (UserModel.isSignin()) this.props.history.push('/user/profile')
    else this.setState({openDialog: true});
  }

  public componentDidMount() {
    //Clear The History First, Before Back To Home
    if (this.props.history.length>3){
      this.props.history.go(-this.props.history.length+2);
      this.props.history.replace('/');
    }
  }

  public componentDidUpdate() {
    document.getElementById(this.historyState?.sectionId)?.scrollIntoView(true);
    AppService.historyState('sectionId', ' ');    
  }

  public render(): React.ReactNode {  

    return (
      <AppBar
        history= {this.props.history}
        color= 'background.paper'
        logo= {<Logo/>}
        menu= {this.menus()}
        avatar= {{name:this.state.dataRowUser.name, imageUrl:this.state.dataRowUser.imageUrl, onClick: this.onClickAvatar}}
        onClick= {this.onClickAppBar}
        onScrollEnd= {this.onScrollEndAppBar}
      >
        <BaseView id={+AppService.appId()} caption='' 
          model={AppModel} getDataRow={this.getDataRowApp}/>
        
        <BaseView id={+OwnerModel.getOwner()} caption='' 
          model={OwnerModel} getDataRow={this.getDataRowOwner}/>

        {UserModel.isSignin() &&
          <BaseView id={+UserModel.getUser()} caption='' 
            model={UserModel} getDataRow={this.getDataRowUser}/>
        }

        {this.UserSignin()}
        {this.state.dataSetSection.length===0 && this.home()}
        <PageSection section = {this.mergeSection(this.pageSection(), this.state.dataSetSection)} />

      </AppBar>
    )
  }
}