import * as React from 'react';
import { CircularProgress, Grid, Slider, Typography } from '@mui/material';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  varian?: 'bar'|'pie';
  data: {
    value: number,
    caption?: string,
  }[];
  color?: any;
  colorCaption?: any;
  size?: number;
}

export const Chart: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const size = props.size || 350;
  const sizeMobile = size;// * 70/100;
  const radius = size/2;
  const totalValue = props.data.reduce( (total, data) => { return total + data.value; }, 0);

  const chartPie = () =>{
    var pie: React.ReactNode = '';
    var pieText: React.ReactNode = '';
    var valueAccum = 0;

    const getAxisY = (degree: number, radius: number) => {
      return Math.abs(Math.round(Math.cos(Math.PI * degree/180) * radius + radius/5) /2 - radius);
    }
    
    const getAxisX = (degree: number, radius: number) => {
      return Math.round(Math.sin(Math.PI * degree/180) * radius - radius/5) /2 + radius;
    }
    
    props.data.sort((a: any, b: any) => (a.value > b.value)? 1 : -1);
    props.data.map((d, i) => {
      if (d.value>0) {
        const value = d.value/totalValue*100;
        const degree = Math.round((valueAccum + (value/2))/100*360);
        const top = getAxisY(degree, radius)-i*10;
        const left = getAxisX(degree, radius)-i*5;
        valueAccum = valueAccum + value;
        pie = <>{pie}<CircularProgress variant='determinate' size={isMobile? sizeMobile: size} value={valueAccum} thickness={22} sx={{ color: props?.color, opacity: 0.2, position: 'absolute'}}/></>     
        pieText = <>{pieText}<Typography position='relative' color={props.colorCaption || 'whitesmoke'} height={10} left={Math.round(left)} top={Math.round(top)} sx={{typography: {xs: 'body2', md: 'subtitle1'}, lineHeight: {xs: 1, md: 1}}}>{d.caption}<br/>{value.toFixed(+value.toString().split('.')[1]>0? 1 : 0)} % </Typography></>
      }
    });

    return (
        <Grid sx={{borderRadius: '50%', height:  {xs: `${sizeMobile}px`, md: `${size}px`}, width: {xs: `${sizeMobile}px`, md: `${size}px`}}}>
          <Grid position='absolute'> {pie} </Grid>
          <Grid position='absolute'> {pieText} </Grid>
        </Grid>
    )
  }
  
  const chartBar = () =>{
    var bar: React.ReactNode = '';
    var barText: React.ReactNode = '';
    var valueMax =Math.ceil(props.data.reduce( (max, data) => { return data.value>max? data.value: max}, 0) /10) *10;
    var increment = valueMax/10 || 2;
    var marks : any = [];

    for (let i = increment; i <= valueMax; i+=increment) marks.push({value: i, label: i});
    props.data.map(data => {
      const value = data.value/totalValue*100;
      barText = <Typography sx={{typography: {xs: 'body2', md: 'subtitle1'}}}>{value.toFixed(+value.toString().split('.')[1]>0? 1 : 0)}% </Typography>
      bar = <>{bar}<Grid color={props.colorCaption || 'gray'} textAlign='left'>{data.caption}<Grid bgcolor={props.color} color={props.colorCaption || 'whitesmoke'} height={{xs:20, md:30}} p={0} sx={{width: `${Math.round(data.value/valueMax*100)}%`, borderRadius: 5, borderTopRightRadius: 50, borderBottomRightRadius: 50, opacity:0.5}} pl={2} mb={1}>{barText}</Grid></Grid></>
    })

    return (
      <Grid item xs={12} md={12} margin={2} padding={2} border={1} borderColor='silver' borderRadius={2}>
        {bar}
        <Slider step={1} max={valueMax} marks={marks} disabled
          sx={{
            '.MuiSlider-thumb': {height: 0, width: 0},
            '.MuiSlider-rail': {color: props.color || 'primary.main'},
            '.MuiSlider-mark': {height: 10, width: 10, borderRadius: 2, color: {color: props.color || 'primary.main'} }
          }}
        />
      </Grid>  
    )
  }

  switch (props.varian){
    case 'pie': return chartPie();
    case 'bar': return chartBar();
    default: return chartPie();      
  }
};


