import ApiService from "../../services/api.service";

export default class QuestionoptionModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    question: any;
    questionCaption: string;
    title: string;
    content: string;
    image: any;
    imageId: number;
    imageUrl: string;
    seq: number;
    answer: string;
    value: number;
    eventresult: any;
    eventresultCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.question = dataRow.attributes.question?.data?.id || null;
        this.questionCaption = dataRow.attributes.question?.data?.attributes.name || dataRow.attributes.question?.data?.id;
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.image = dataRow.attributes.image?.data?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ((this.image.attributes.url.includes('http')? '' : ApiService.svrUrl)+this.image.attributes.url) : '';
        this.seq = dataRow.attributes.seq;
        this.answer = dataRow.attributes.answer;
        this.value = dataRow.attributes.value;
        this.eventresult = dataRow.attributes.eventresult.data || null;
        this.eventresultCaption = this.eventresult?.length===0? '' : this.eventresult?.[0]?.attributes.name + (this.eventresult?.length>1? ', ...': '');
        
    } 

    public static endPoint: string = 'question-options';
    public static endPointMedia: string = 'api::question-option.question-option';
    public static endPointFilter: string = '?populate=*&sort=seq&filters[question]=';
    public static url: string = '/questionoption';
    public static caption: string = 'Question Option';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        question: null,
        questionCaption: '',
        title: '',
        content: '',
        image: null,
        imageId: 0,
        imageUrl: '',
        seq: 0,
        answer: '',
        value: 0,
        eventresult: [],
        eventresultCaption: '',   
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'value', caption: 'Value' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'title', caption: '', type: 'caption' },
        { name: 'content', caption: '' },
        { name: 'imageUrl', caption: '', type:'media' },
    ]
}