import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputSelect, InputSwitch, Picklist } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './eventtype-model';  //editable..
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class EventtypeDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} readOnly/>
                }                 
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} error={this.state.dataError?.type} readOnly/>
                <Picklist name='messageinvitation' label='Message of Send Invitation' value={this.state.dataRow.messageinvitation} caption={this.state.dataRow.messageinvitationCaption} readOnly/>
                <Picklist name='messageresult' label='Message of Send Result' value={this.state.dataRow.messageresult} caption={this.state.dataRow.messageresultCaption} readOnly/>
                <Picklist name='reportgroup' label='Report Group' value={this.state.dataRow.reportgroup} caption={this.state.dataRow.reportgroupCaption} readOnly/>
                <InputSelect name='viewstyle' label='View Style' value={this.state.dataRow.viewstyle} options={Model.optionViewstyle} readOnly/>
                <InputSwitch name='camera' label='Use Camera' value={this.state.dataRow.camera} defaultTrue='yes' defaultFalse='no' readOnly/>
                <InputSwitch name='extratime' label='Allow Extra Time' value={this.state.dataRow.extratime} defaultTrue='yes' defaultFalse='no' readOnly/>
                               
            </BaseDelete>
        );
    }
}