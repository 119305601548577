import * as React from 'react';
import { History } from 'history';
import { Grid  } from '@mui/material';
import { Card, Pagination, ProgressBar, Spinner, Text } from '../../components';
import Model from './eventsection-model'; //editable..
import EventquestionModel from '../event/event-model';
import BaseList from '../base/base-list.component';
import EventCreateByuser from '../event/event-create-byuser.component';
import QuestionListByuser from '../question/question-list-byuser.component';
import AppService from '../../services/app.service';

interface IProps {  
  history: History;
  root?: string;
  parentId?: number;
  eventParentId?: number;
  eventId: number;
  viewStyle: string;
  getDataRow?: (data: any) => void;
  
}

interface IState {
  dataSet: Array<Model>;
  dataRowEventquestion: EventquestionModel;
  pageInfo: any;
  pageSize: number;
  page: number;
  isReady: boolean;
}

export default class EventsectionListByuser extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    
    this.state = {
      dataSet: [],
      dataRowEventquestion: EventquestionModel.defaultValue(),
      pageInfo: null,
      pageSize: 20,
      page: 1,
      isReady: false,
    }
  }

  private historyState: any = this.props.history.location.state;

  private getDataSet = (data:any, pageInfo: any) => {
    var finish = data?.length>0 && data?.filter((d: any) => (d?.eventEnddate===null)).length===0;
    
    this.setState({dataSet: data, pageInfo: pageInfo, isReady: true});
    this.props.getDataRow?.({'total':pageInfo.total, 'finish': finish});
    document.getElementById('Top')?.scrollIntoView(false);
  }

  private onChangePage = async(e: React.ChangeEvent<unknown>, value: number) => {
    await AppService.historyState('eventsectionPage', value);
    this.setState({page: value}); 
  };

  public componentDidMount() {
    if (this.historyState?.eventsectionPage) this.setState({page: this.historyState?.eventsectionPage})
  }

  public render(): React.ReactNode {
    var isNext: boolean = true;
    
    return (
      <Grid container id='Top' justifyContent='center' spacing={1} m={0} mt={1}>
        {this.props.parentId?
          <BaseList history={this.props.history} parentId={this.props.parentId} caption=' ' endPointFilter={Model.endPointFilter4(this.props.eventId || 0)+this.props.parentId} model={Model} page={this.state.page} pageSize={this.state.pageSize} getDataSet={this.getDataSet} disableView/>
          : <BaseList history={this.props.history} caption=' ' endPointFilter={Model.endPointFilter5(this.props.eventId || 0)+this.props.eventParentId} model={Model} page={this.state.page} pageSize={this.state.pageSize} getDataSet={this.getDataSet} disableView/>
        }
        
        {!this.state.isReady && <Spinner/>}
        {this.state.dataSet.map((d: any) => { 
          if (d.id>0 && isNext) {
            if (this.props.viewStyle.includes('onebyone') && (!d.eventEnddate)) isNext = false;

            if (!(d.questionLength===1 && d.eventEnddate))
            return (
                <Card key={d.id} caption={this.props.viewStyle.includes('editable')? d.name : d.title}>
                  <Grid container={!this.props.viewStyle.includes('editable')} justifyContent='center'>
                    {d.questionLength>1 &&
                      <ProgressBar value={d.eventEnddate? d.questionLength : d.eventquestionLengthAnswered} totalValue={d.questionLength} />
                    }
                    {d.eventsectionLength>0 &&
                      <ProgressBar value={d.eventEnddate? d.eventsectionLength : d.eventLengthEnddate+(d.eventLength>d.eventLengthEnddate? 0.5 : 0)} totalValue={d.eventsectionLength} />
                    }
                    {!d.eventEnddate &&
                    <>
                    {!this.props.viewStyle.includes('editable') &&
                    <Text label={d.content} size='extrasmall' marginTop={3} textToHtml sx={{marhinBottom: 3}} />
                    }
                    {d.questionLength>0 &&
                    <QuestionListByuser history={this.props.history} eventsectionId={d.id} viewStyle={d.viewstyle} wrap readOnly/>                
                    }
                    <EventCreateByuser history={this.props.history} parentId={this.props.eventId} eventsectionId={d.id} disableView={!this.props.viewStyle?.includes('editable')}/>
                    </>
                    }
                  </Grid>
                </Card>
            )
          }
          return null;
        })}

        <Pagination pageCount={this.state.pageInfo?.pageCount} page={this.state.page} pageInfoCaption='Hal.' prevCaption='Sebelumnya' nextCaption='Berikutnya' disablePageButtons onChange={this.onChangePage}/>
      </Grid>
    );
  }
}
