import OwnerModel from '../owner/owner-model';
import UserModel from '../user/user-model';
import PersonalinfoModel from '../personalinfo/personalinfo-model';
import ApiService from '../../services/api.service';

export default class EventModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    parent: any;
    parentCaption: string;
    eventtype: any;
    eventtypeCaption: string;
    eventtypeType: string;
    customer: any;
    customerCaption: string;
    customerEmail: string;
    participant: any;
    participant0: any;
    participantCaption: string;
    participantLength: number;
    participantEmail: string;
    eventsection: any;
    eventsectionId: any;
    eventsectionCaption: string;
    event: any;
    eventLength: number;
    eventLengthEnddate: number;
    eventquestion: any;
    eventquestionLength: number;
    eventquestionLengthAnswered: number;
    title: string;
    content: string;
    startdate: any;
    enddate: any;
    image: any;
    imageId: number;
    imageUrl: string;    
    astemplate: string;
    progress: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = EventModel.optionStatus.filter((d: any) => d.name === this.status)?.[0]?.caption|| 'Active';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.parent = dataRow.attributes.parent?.data?.id || null;
        this.parentCaption = dataRow.attributes.parent?.data?.attributes.name || dataRow.attributes.parent?.data?.id;
        this.eventtype = dataRow.attributes.eventtype?.data?.id || null;
        this.eventtypeCaption = dataRow.attributes.eventtype?.data?.attributes.name || dataRow.attributes.eventtype?.data?.id;
        this.eventtypeType = dataRow.attributes.eventtype?.data?.attributes.type || '';
        this.customer = dataRow.attributes.customer?.data?.id || null;
        this.customerCaption = dataRow.attributes.customer?.data?.attributes.name || dataRow.attributes.customer?.data?.id;
        this.customerEmail = dataRow.attributes.customer?.data?.attributes.email;
        this.participant = dataRow.attributes.participant?.data?.map((d: any) => new PersonalinfoModel(d)) || null;
        this.participant0 = this.participant?.[0] || null;
        this.participantCaption = this.participant?.length===0? '' : this.participant0?.name + (this.participant?.length>1? ', ...' : '');
        this.participantLength = this.participant?.length || 0;
        this.participantEmail = this.participant0?.email || '';
        this.eventsection = dataRow.attributes.eventsection?.data || null;
        this.eventsectionId = this.parent? this.eventsection?.[0]?.id : null;
        this.eventsectionCaption = this.eventsection?.length===0? '' : this.eventsection?.[0]?.attributes.name + (this.eventsection?.length>1? ', ...' : '');
        this.event = dataRow.attributes.event?.data || null;
        this.eventLength = this.event?.length || 0;
        this.eventLengthEnddate =  this.event?.filter((d: any) => d.attributes.enddate !== null).length|| 0;
        this.eventquestion = dataRow.attributes.eventquestion?.data || null;
        this.eventquestionLength = this.eventquestion?.length || 0;
        this.eventquestionLengthAnswered =  this.eventquestion?.filter((d: any) => d.attributes.answer || d.attributes.questionoption?.data.length>0).length|| 0;
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.startdate = dataRow.attributes.startdate;
        this.enddate = dataRow.attributes.enddate;
        this.image = dataRow.attributes.image?.data?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ((this.image.attributes.url.includes('http')? '' : ApiService.svrUrl)+this.image.attributes.url) : '';
        this.astemplate = dataRow.attributes.astemplate;
        this.progress = (!this.parent && this.startdate && this.eventLength>0 && this.eventLength===this.eventLengthEnddate && this.eventLength===this.participantLength) || (this.parent && this.enddate)? 'Done' : this.eventLength>0 && this.startdate? 'On Progress' : 'Not Started';
        
    } 

    public static endPoint: string = 'events' 
    public static endPointMedia: string = 'api::event.event';
    public static endPointFilter = (astemplate?: string)=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&sort=name&filters[owner]='+owner+'&filters[parent][id][$null]=true&filters[astemplate]='+(astemplate||'no'));
    } 
    public static endPointFilter2: string = '?populate=*&sort=name&filters[parent]=';
    public static endPointFilter3: string = '?populate[owner][sort]=id'+
                                            '&populate[parent][sort]=id'+
                                            '&populate[eventsection][sort]=id'+
                                            '&populate[customer][sort]=id'+
                                            '&populate[participant][sort]=id'+
                                            '&populate[event][sort]=id'+
                                            '&filters[parent]=';
    public static endPointFilter4: string = '?populate[owner][sort]=id'+
                                            '&populate[parent][sort]=id'+
                                            '&populate[eventsection][sort]=id'+
                                            '&populate[eventquestion][populate]=questionoption'+
                                            '&populate[customer][sort]=id'+
                                            '&populate[participant][sort]=id'+
                                            '&populate[eventtype][sort]=id'+
                                            '&populate[event][sort]=id'+
                                            '&populate[image][sort]=id'+
                                            '&filters[id]=';
    public static endPointFilter5 = (personalinfoType?: string) => {
        const owner = OwnerModel.getOwner();
        const user = UserModel.getUser();
        return ('?populate=*&sort=name&filters[owner]='+owner+'&filters['+(personalinfoType || 'participant')+'][user][id]='+user+'&filters[parent][id][$null]=true');
    }
    public static endPointFilter6 = (parentId: number) => {
        const user = UserModel.getUser();
        return ('?populate=*&sort=name&filters[participant][user][id]='+user+'&filters[parent][id]='+parentId+'&filters[eventsection]=');
    }
    public static endPointFilter7 = (parentId: number) => {
        const user = UserModel.getUser();
        return ('?populate=*&sort=name&filters[participant][user][id]='+user+'&filters[parent][id]='+parentId+'&filters[eventsection][id][$null]=true');
    }
    public static endPointFilter8 = (participantId?: number) => {
        return ('?populate[eventtype][sort]=id&populate[customer][sort]=id&populate[participant][populate]=user'+(participantId? '&populate[participant][filters][id]='+participantId:'')+'&filters[id]=');
    }
    public static endPointFilter9 = () => {
        return ('?populate[eventtype][sort]=id&filters[transactiondetail]=');
    }
    public static url: string = '/event';
    public static caption: string = 'Event';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),    
        parent: null,
        parentCaption: '',
        eventtype: null,
        eventtypeCaption: '',
        eventtypeType: '',
        customer: null,
        customerCaption: '',
        customerEmail: '',
        participant: [],
        participant0: null,
        participantCaption: '',
        participantLength: 0,
        participantEmail: '',
        eventsection: [],
        eventsectionId: null,
        eventsectionCaption: '',
        event: [],
        eventLength: 0,
        eventLengthEnddate: 0,
        eventquestion: [],
        eventquestionLength: 0,
        eventquestionLengthAnswered: 0,
        title: '',
        content: '',
        startdate: null,
        enddate: null,
        image: null,
        imageId: 0,
        imageUrl: '',
        astemplate: 'yes',
        progress: 'Not Started',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
        { name: 'eventtype', required: true },
        { name: 'startdate', required: true },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'progress', caption: 'Status', type: 'state', default: 'Done'},
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
        { name: 'startdate', caption: 'Start Date', type: 'date' },
    ]

    public static columnPicklistCard = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
        { name: 'startdate', caption: 'Start Date', type: 'datetime' },
        { name: 'statusCaption', caption: 'State', type: 'state', default: 'Active' },
    ]

    public static optionStatus = [
        { name: 'active', caption: 'Active' },
        { name: 'inactive', caption: 'Inactive' },
        { name: 'extratime', caption: 'Extra Time' },
    ]
}