import * as React from 'react';
import { History } from 'history';
import Model from './product-model';
import BasePicklistCard from '../base/base-picklistcard.component';

interface IProps { 
  history: History;
  caption?: string;
  producttypeType?: string;
  currentId?: number;
  pricetype?: string;
  productgroupId?: number;
  cardCount?: number;
  pageSize?: number;
  swipe?: string;
  cardNowrap?: boolean;
  getDataRow?: (data: any) => void;
  disableSearch?: boolean;
}

interface IState {
}

export default class ProductPicklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  private header: any = Model.columnPicklistCard.filter((d: any) => d.name !== (this.props.pricetype === 'pricebase'? 'pricesale' : 'pricebase'));

  private getDataRow = (data: any) => {
    this.props.getDataRow?.(data)
  };

  public render(): React.ReactNode {
    return (
      <BasePicklistCard history={this.props.history} id={this.props.productgroupId} caption={this.props.caption} endPointFilter={Model.endPointFilter(undefined, this.props.producttypeType, this.props.productgroupId)} model={Model} currentId={this.props.currentId} getDataRow={this.getDataRow} header={this.header} pageSize={this.props.pageSize} searchField={this.props.disableSearch? ' ' : ''} cardCount={this.props.cardCount} cardNowrap={this.props.cardNowrap} swipe={this.props.swipe} autoLoad disableBackButton disableCard/>
    );
  }
}
