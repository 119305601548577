import * as React from 'react';
import { Chip as ChipMui } from '@mui/material';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  label?: string;
  size?: 'small'|'medium';
  color?: 'primary'|'secondary'|'info'|'warning'|'error';
  sx?: any;
}

export const Chip: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const color = {primary: 'white', secondary: 'text.primary', info: 'text.primary', warning: 'white', error: 'white'};
  const bgColor = {primary: 'primary.light', secondary: 'background.paper', info: 'info.main', warning: 'warning.main', error: 'error.main'};
  
  return (
    <ChipMui 
      id={props.id}
      label={props.label}
      size={props.size || (isMobile? 'small' : 'medium')}
      sx={{bgcolor: bgColor[props.color || 'primary'], color: color[props.color || 'primary'], fontSize: {xs: 10, md: 14}, ...props.sx}}
    />
  );
};
