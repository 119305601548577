import ApiService from '../../services/api.service';
import OwnerModel from '../owner/owner-model';

export default class PersonalinfoModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    personalinfotype: any;
    personalinfotypeId: any;
    personalinfotypeCaption: string;
    personalinfotypeType: any;
    phone: string;
    email: any;
    birthplace: any;
    birthplaceCaption: string;
    birthdate: any;
    gender: string;
    image: any;
    imageId: number;
    imageUrl: string;
    division: any;
    divisionCaption: string;
    schedule: any;
    scheduleCaption: string;
    user: any;
    userCaption: string;
    userEmail: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.personalinfotype = dataRow.attributes.personalinfotype?.data;
        this.personalinfotypeId = this.personalinfotype?.[0]?.id;
        this.personalinfotypeCaption = this.personalinfotype?.[0]?.attributes.name || this.personalinfotype?.[0]?.id;
        this.personalinfotypeType = this.personalinfotype?.[0]?.attributes.type || null;
        this.phone = dataRow.attributes.phone;
        this.email = dataRow.attributes.email;
        this.birthplace = dataRow.attributes.birthplace?.data?.id || null;
        this.birthplaceCaption = dataRow.attributes.birthplace?.data?.attributes.name || dataRow.attributes.birthplace?.data?.id;
        this.birthdate = dataRow.attributes.birthdate;
        this.gender = dataRow.attributes.gender;
        this.image = dataRow.attributes.image?.data?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ((this.image.attributes.url.includes('http')? '' : ApiService.svrUrl)+this.image.attributes.url) : '';
        this.division = dataRow.attributes.division?.data?.id || null;
        this.divisionCaption = dataRow.attributes.division?.data?.attributes.name || dataRow.attributes.division?.data?.id;
        this.schedule = dataRow.attributes.schedule?.data || null;
        this.scheduleCaption = this.schedule?.length===0? '' : this.schedule?.[0]?.attributes.name + (this.schedule?.length>1? ', ...': '');      
        this.user = dataRow.attributes.user?.data?.id || null;
        this.userCaption = dataRow.attributes.user?.data?.attributes.name || dataRow.attributes.user?.data?.id;
        this.userEmail = dataRow.attributes.user?.data?.attributes.useremail;
        
    } 

    public static endPoint: string = 'personal-infos';
    public static endPointMedia: string = 'api::personal-info.personal-info';
    public static endPointFilter = (personalinfotypeType?: string)=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&sort=name&filters[owner]='+owner+(personalinfotypeType? '&filters[personalinfotype][type]='+personalinfotypeType : ''));
    } 
    public static endPointFilter2 = (personalinfotypeType?: string)=>{
        return ('?populate[owner][sort]=id'+
            '&populate[personalinfotype]'+(personalinfotypeType? '[filters][type][$eq]='+personalinfotypeType : '[sort]=id')+
            '&populate[image][sort]=id'+
            '&populate[user][sort]=id'+
            '&populate[schedule][sort]=id'+
            '&populate[division][sort]=id'+
            '&filters[id]='
    );
    }
    public static url: string = '/personalinfo';
    public static caption: string = 'Personal Info';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        personalinfotype: [],   
        personalinfotypeId: null,   
        personalinfotypeCaption: '',   
        personalinfotypeType: null,   
        phone: '',
        email: null,
        birthplace: null,
        birthplaceCaption: '',   
        birthdate: null,
        gender: 'male',
        image: null,
        imageId: 0,
        imageUrl: '',
        division: null,
        divisionCaption: '',
        schedule: [],
        scheduleCaption: '',
        user: null,
        userCaption: '',
        userEmail: '',
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
        { name: 'personalinfotype', required: true },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionGender = [
        { name: 'male', caption: 'Male' },
        { name: 'female', caption: 'Female' },
    ]
}