import * as React from 'react';
import { Grid } from '@mui/material';
import { Circle, Text } from '.';

interface Props {
  id?: string;
  caption?: string;
  color?: any;
  backgroundColor?: any;
  gradientColor?: any;
  borderWidth?: number;
  width?: number;
  children?: React.ReactNode;
  
}

export const CardTicket: React.FunctionComponent<Props> = (props) => {
  const width = props.width || 800;
  const height= width/1.5;
  const circleSize = width/10;
  const circleSmallSize = circleSize/2;
  const borderWidth = props.borderWidth || 2;
  const color = props.color || 'white';
  const background = props.gradientColor? 'linear-gradient(90deg, '+props.gradientColor+', transparent)' : '';

  return (
    <Grid id='id' height={height-borderWidth-1} width={width} border={borderWidth} borderColor={color} borderRadius={5} sx={{background: background,backgroundColor: color}} margin={1}>
      <Grid container alignItems='center' justifyContent='space-between' height={height} width={width} left={-borderWidth+1} top={-borderWidth} position='relative'>
        <Circle position='right' color={props.backgroundColor} size={circleSize} borderWidth={borderWidth} borderColor={color}/>
        <Text label={props.caption} bold sx={{transform: 'rotate(-90deg)',}}/>
        <Grid container direction='column' alignItems='center' justifyContent='space-between' height={height-borderWidth} width={circleSmallSize} top={-1}  position='relative'>
          <Circle position='bottom' color={props.backgroundColor} size={circleSmallSize} borderWidth={borderWidth} borderColor={color}/>
          <Grid height={height - circleSize - borderWidth*10} marginTop={borderWidth} marginBottom={borderWidth} borderColor={color} sx={{borderStyle: 'dashed'}}/>
          <Circle position='top' color={props.backgroundColor} size={circleSmallSize} borderWidth={borderWidth} borderColor={color}/>
        </Grid>
        <Grid width={(width-circleSize-circleSmallSize)*60/100}>
          {props.children}
        </Grid>
        <Circle position='left' color={props.backgroundColor} size={circleSize} borderWidth={borderWidth} borderColor={color}/>
      </Grid>      
    </Grid>
  )
};


