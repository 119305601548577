import * as React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Icon } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  name: string;
  label?: string;
  placeHolder?: string;
  value?: string;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  autoSelect?: boolean;
  box?: boolean;
  color?: string;
  readOnly?: boolean;
  onBlur?: (fieldName?: string, value?: string) => void;
  onChange?: (fieldName: string, value: string) => void;
  onClick?: () => void;
  onEndTyping?: (fieldName?: string, value?: string) => void;
  error?: string;
}

export const InputSearch: React.FunctionComponent<Props> = (props) => {
  const [endTyping, setEndTyping] = React.useState<any>(null);
  const isMobile = AppService.isMobile();

  const fontSize = () => {
    const sizes = {'extrasmall': 16, 'small': 18, 'medium': 24, 'large': 36, 'extralarge': 48};
    const size = sizes[props.size || 'small'];
    return {xs: size-2, md: size}
  }
  
  const onBlur = (props: Props) => (e: React.FocusEvent<HTMLInputElement>) => { 
    props.onBlur?.(e.target.name, e.target.value);
  };

  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, e.target.value);
   
    if (props.onEndTyping) {
      clearTimeout(endTyping);
      setEndTyping(setTimeout(() => props.onEndTyping?.(e.target.name, e.target.value), 500));
    }
  };
  
  const onClick = () => {
    props.onClick?.();
  };

  
  return (
    <TextField
      margin='normal'
      id={props.id || props.name}
      name={props.name}
      label={props.label}
      type='input'
      value={props.value?.toString()}
      placeholder={props.placeHolder || 'Search..'}
      inputProps={{spellCheck: false}}
      InputProps={{
        readOnly: props.readOnly || false,
        sx: {borderColor: 'primary.main', fontSize: {xs: '16px', md: '18px'}, borderRadius: 20, height: isMobile? 35 : 50, bgcolor: props.color,},
        endAdornment: 
          <InputAdornment position='end'>
            <IconButton onClick={onClick} size='large' style={{padding: 0}}>
              <Icon name='search' size={isMobile? 'small' : 'medium'} color='primary.main'/>
            </IconButton>
          </InputAdornment>,
      }}
      InputLabelProps={{
        sx: {fontSize: fontSize()},
      }}
      sx={{
        '.MuiOutlinedInput-root:hover': { fieldset: { borderColor: 'primary.main' } }
      }}
      fullWidth
      helperText={props.error}
      error= {props.error? true: false}
      onBlur={onBlur(props)}
      onChange={onChange(props)}
      onFocus={e => {props.autoSelect && e.target.select();}}
    />
  )
};