import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PersonalinfoList from './personalinfo-list.component';
import PersonalinfoCreate from './personalinfo-create.component';
import PersonalinfoView from './personalinfo-view.component';
import PersonalinfoEdit from './personalinfo-edit.component';
import PersonalinfoCopy from './personalinfo-copy.component';
import PersonalinfoDelete from './personalinfo-delete.component';

interface Props {
  root?: string;
}

export const PersonalinfoRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/personalinfo/create'} component={PersonalinfoCreate} />
      <Route path={`${props.root||''}/personalinfo/view`} render={(params)=> <PersonalinfoView {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/personalinfo/edit`} render={(params)=> <PersonalinfoEdit {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={(props.root||'')+'/personalinfo/copy'} component={PersonalinfoCopy} />
      <Route path={`${props.root||''}/personalinfo/delete`} render={(params)=> <PersonalinfoDelete {...params} key={params.history.location.key} root={props.root}/>} />        
      <Route path={(props.root||'')+'/personalinfo'} render={(params)=> <PersonalinfoList {...params} key={params.history.location.key} root={props.root} />} /> 
    </Switch>
  );
};
