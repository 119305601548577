import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProductgroupList from './productgroup-list.component';
import ProductgroupCreate from './productgroup-create.component';
import ProductgroupView from './productgroup-view.component';
import ProductgroupEdit from './productgroup-edit.component';
import ProductgroupCopy from './productgroup-copy.component';
import ProductgroupDelete from './productgroup-delete.component';

interface Props {
  root?: string;
}

export const ProductgroupRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/productgroup/create'} component={ProductgroupCreate} />
      <Route path={(props.root||'')+'/productgroup/view'} component={ProductgroupView} />
      <Route path={(props.root||'')+'/productgroup/edit'} component={ProductgroupEdit} />
      <Route path={(props.root||'')+'/productgroup/copy'} component={ProductgroupCopy} />
      <Route path={(props.root||'')+'/productgroup/delete'} component={ProductgroupDelete} />
      <Route path={(props.root||'')+'/productgroup'} render={(params)=> <ProductgroupList {...params} root={props.root} />} /> 
    </Switch>
  );
};
