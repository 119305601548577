import { renderToStaticMarkup } from 'react-dom/server';
import BackgroundSvg from '../assets/background.svg';

export default class AppService {

//Apps
    public static appId() {
        return `${process.env.REACT_APP_APP_ID}` ;  
    } 

    public static isAppFullVersion() {
        return this.appId() === '1';
    }
    
    public static isMobile() {
        return window.screen.width < 485;//window.matchMedia && window.matchMedia('(max-width: 485px)').matches;
    }
    
    public static isMac() {
        return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    }

    public static historyState(fieldName?: string, value?: any, isCheckZero?: boolean, isCheckExist?: boolean) {
        var historyState: any = window.history.state;
        
        if (!historyState) return;
        if (fieldName && (value || (isCheckZero && value>0)) 
            && (historyState.state?.[fieldName] !== value)
            && (!isCheckExist || (isCheckExist && !historyState.state?.[fieldName])))
        window.history.replaceState({...historyState, state: {...historyState.state, [fieldName]: value}}, '');
        
        return window.history.state.state;
    }

    public static getUrlQuery(history: any, name: string) {
        if (!history?.location.search) return;
        var urlQuery : any = history.location.search.substring(1).split('&');
        return urlQuery.map((d: any) => {return {[d.split('=')[0]]: d.split('=')[1]};})
                .find((d: any)=>{return d?.[name]})[name];
    }

//Date
    public static optionDay = [
        { name: 'mon', caption: 'Senin' },
        { name: 'tue', caption: 'Selasa' },
        { name: 'wed', caption: 'Rabu' },
        { name: 'thu', caption: 'Kamis' },
        { name: 'fri', caption: 'Jumat' },
        { name: 'sat', caption: 'Sabtu' },
        { name: 'sun', caption: 'Minggu' },
    ]

    public static optionMonth = [
        { name: 'jan', caption: 'Januari', captionshort: 'Jan' },
        { name: 'feb', caption: 'February', captionshort: 'Feb' },
        { name: 'mar', caption: 'Maret', captionshort: 'Mar' },
        { name: 'apr', caption: 'April', captionshort: 'Apr' },
        { name: 'may', caption: 'May', captionshort: 'Mei' },
        { name: 'jun', caption: 'Juni', captionshort: 'Jun' },
        { name: 'jul', caption: 'Juli' , captionshort: 'Jul'},
        { name: 'agu', caption: 'Agustus', captionshort: 'Agu' },
        { name: 'sep', caption: 'September', captionshort: 'Sep'},
        { name: 'oct', caption: 'Oktober', captionshort: 'Okt' },
        { name: 'nov', caption: 'November', captionshort: 'Nov' },
        { name: 'dec', caption: 'Desember', captionshort: 'Des' },
    ]

    public static days(fieldName: string) {
        return this.optionDay.reduce((r: any, d: any) =>  {r.push(d[fieldName]); return r}, [] );
    }

    public static months(fieldName: string) {
        return this.optionMonth.reduce((r: any, d: any) =>  {r.push(d[fieldName]); return r}, [] );
    }

    public static dateCurrent() {
        const date = new Date();
        return date.toLocaleDateString('en-CA') + ' ' + date.toTimeString().substring(0,8)
    }

    public static dateISO(date: string, addHours?: number, addMinutes?: number, addSeconds?: number){
        var date1 = new Date(date) //convert to local time 
        
        if (addHours) date1.setHours(date1.getHours() + addHours);
        if (addMinutes) date1.setMinutes(date1.getMinutes() + addMinutes);
        if (addSeconds) date1.setSeconds(date1.getSeconds() + addSeconds);
        return date1.toLocaleDateString('en-CA') + ' ' + date1.toTimeString().substring(0,8)
    }
    
    public static dateFormat(date: string, format?: string) {
        if (!date) return '';
        
        date = this.dateISO(date); 
        const month = this.months('value');
        const mon = this.months('captionshort');
        const y = date.substring(0,4);
        const m = date.substring(5,7);
        const d = date.substring(8,10);
        const t = date.substring(11,19);
        const h = date.substring(11,13);
        const n = date.substring(14,16);
        const s = date.substring(17,19);
        
        var fmt = format || 'd-m-y';
        fmt = fmt
        .replace('month', '???')
        .replace('mon', '??')
        .replace('m', '?')
        .replace('y',y)
        .replace('d',d)
        .replace('t',t)
        .replace('h',h)
        .replace('n',n)
        .replace('s',s)
        .replace('???', month[+m-1])
        .replace('??', mon[+m-1])
        .replace('?', m)

        return fmt;
    }

    public static dateDiff(from: string, to?: string) {
        var diff =  (to? Date.parse(to) : Date.now())- Date.parse(from); 
        var dateDiff = new Date(diff);
        var diffYear = dateDiff.getFullYear() - 1970;
        var diffMonth = dateDiff.getMonth();
        var diffYearRounded = diffYear + (diffMonth>6? 1 : 0);

        return isNaN(diff) ? {} : {
            milisecond: dateDiff.getMilliseconds(),
            second:  dateDiff.getSeconds(),
            minute: dateDiff.getMinutes(),
            hour: dateDiff.getHours(),
            day: dateDiff.getDate(),
            month: diffMonth,
            year: diffYear,
            yearrounded: diffYearRounded,
        }
    }

//Currency & Number
    public static thousandSeparator = '.';
    public static decimalSeparator = ',';
    public static currencySymbol = 'Rp';
        
    public static strToNumber(value: string) {
        return value? (Number(value).toString().replace(/[^0-9,-]/g, _ => '')+'') : '0';
    }

    public static strToMoney(value: string) {
        let i = 0;
        const pattern = '###.###.###.###.###'.replaceAll('.', this.thousandSeparator);
        const v = this.strToNumber(value);
        const x = (v.length + Math.floor((v.length-1) / 3));
        const patternNew = pattern.substr(pattern.length - x, x);
        return patternNew.replace(/#/g, _ => v[i++] || '');
    }

//String
    public static strCapitalize(value: string) {
        return value?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    public static strPluralize(value: string) {
        return value.slice(-1)==='y'? value.substring(0,value.length-1)+'ies' : (value + ( value.slice(-1)==='z'? 'zes': value.slice(-1)==='s'? 'es' : 's'));
    }

    public static emailMasking(value: string) {
        return value?.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);
    }

    public static replaceValue(text: string, value: any) {        
        if (value && text) Object.keys(value).forEach((key)=> text = text?.replaceAll('['+key+']', value[key]) );
        return text;
    }
    
    public static randomId(length = 6) {
        return Math.random().toString(36).substring(2, length+2);
    };

    public static svgToString(svg: React.ReactElement) {
        return svg? `data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(svg))}` : '';
    };


//Media    
    public static background (sx: any) {
        const svgName = sx.svgName? `${BackgroundSvg}#${sx.svgName}` : '';
        
        return {
            backgroundSize: sx.size && isNaN(+sx.size)? sx.size : `${sx.size || '100'}%`,
            backgroundImage: `url('${svgName || this.svgToString(sx.svg) || sx.image}')`,   
            backgroundRepeat: 'no-repeat',
            backgroundPosition: sx.position || 'center',
        }
    }

//Array    
    public static JSONParse(data: string) {
        data = data.replace("'",'"');
        var result;
        try { result = JSON.parse(data)} catch (e) { result = JSON.parse(JSON.stringify(data))}
        return result;
    }
}