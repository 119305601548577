
export default class AddressModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    type: string;
    typeCaption: string;
    street: string;
    region: any;
    regionCaption: string;
    map: string;
    seq: number;
    personalinfo: any;
    personalinfoCaption: string;

    constructor(dataRow:any) { 
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.type = dataRow.attributes.type || 'general';
        this.typeCaption = AddressModel.optionType.filter((d: any) => d.name === this.type)?.[0]?.caption || 'General';
        this.street = dataRow.attributes.street;
        this.region = dataRow.attributes.region?.data?.id || null;
        this.regionCaption = dataRow.attributes.region?.data?.attributes.description || dataRow.attributes.region?.data?.id;
        this.map = dataRow.attributes.map;
        this.seq = dataRow.attributes.seq;
        this.personalinfo = dataRow.attributes.personalinfo?.data?.id || null;
        this.personalinfoCaption = dataRow.attributes.personalinfo?.data?.attributes.name || dataRow.attributes.personalinfo?.data?.id; 
        
    } 

    public static endPoint: string = 'addresses';
    public static endPointFilter: string = '?populate=*&sort=name&filters[personalinfo]=';
    public static endPointFilter2: string = '?populate[personalinfo][sort]=id'+
                                            '&populate[region][populate]=parent'+
                                            '&filters[personalinfo]=';

    public static url: string = '/address';
    public static caption: string = 'Address';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',   
        type: 'general',
        typeCaption: 'General',
        street: '',
        region: null,
        regionCaption: '',
        map: '',
        seq: 0,
        personalinfo: null,
        personalinfoCaption: '',
        
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'typeCaption', caption: 'Type' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'typeCaption', caption: 'Type' },
    ]

    public static optionType = [
        { name: 'general', caption: 'General' },
        { name: 'home', caption: 'Home' },
        { name: 'office', caption: 'Office' },
    ]
}