import React from 'react';
import { History } from 'history';
import { Button, InputEmail, InputPassword, Text } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
    root?: string;
    caption?: string;
    onSave?: (data: any) => void;
    disableAutoBack?: boolean;
    disableBackButton?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
}


export default class UserAuthSignin extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }

    private onSave = async(data: any) => {
        if (data.id) Model.setUser(data.id, data.name, data.token);
        this.props.onSave?.(data);
    };

    private onChange(fieldName: string, value: string) { 
        var identifier = this.state.dataRow.identifier;
        if (fieldName === 'useremail') identifier = Model.getEmailApp(value);

        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, identifier: identifier,
            }
        };
        this.setState(nextState);
    }
    render() {
        return (
            //editable..
            <>
            <BaseCreate history={this.props.history} caption={this.props.caption || Model.caption + ' Sign in'} buttonCaption='Login' successCaption='Signed in'
                endPoint={Model.endPointAuth+'/local'} model={Model} url={this.props.root} dataRow={this.state.dataRow} validations={Model.validationSignin} getDataError={this.getDataError} buttonOnBottom onSave={this.onSave} disableBackButton={this.props.disableBackButton} disableAutoBack={this.props.disableAutoBack}>              
                <InputEmail name='useremail' label='Email' value={this.state.dataRow.useremail} error={this.state.dataError?.identifier || this.state.dataError?.[0]} onChange={this.onChange} />
                <InputPassword name='password' label='Password' value={this.state.dataRow.password} error={this.state.dataError?.password} onChange={this.onChange} />
                <Button textCapitalize sx={{margin: -2, marginBottom: 1}} onClick={()=>{this.props.history.push('/user/forgotpassword')}} label='Forget Password?' />
            </BaseCreate>
            
            <Text label='Do not have an account?' size='extrasmall' displayBlock sx={{mt: 3, mb:3}}>
                <Button textCapitalize onClick={()=>{this.props.history.push('/user/signup')}} label='Register Now' />
            </Text>
            </>
        );
    }     
     
}