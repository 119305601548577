import * as React from 'react';
import * as toastr from 'toastr';
import { Form, Table } from '../../components';
import ApiService from '../../services/api.service';
import BaseValidate from './base-validate.component';
import BaseCreate from './base-create.component';
import AppService from '../../services/app.service';

interface IProps { 
  history?: any;
  id?: any;
  caption?: string;
  captionColor?: string;
  captionThin?: boolean;
  endPoint?: string;
  model: any;
  defaultValue?: any;
  dataSet?: Array<any>;
  getDataError?: (data: any) => void;
  onSave?: (data: any) => void;
  validations?: any;
  page?: number;
  pageSize?: number;
  searchField?: string;
  disableBackButton?: boolean;
  disableCard?: boolean;
  disableUploadButton?: boolean;
  children?: React.ReactNode;
}

interface IState {
  dataSetFromFile: any;
  dataSetAll: Array<any>;
  dataSet: Array<any>;
  dataRow: any,
  header: Array<any>;
  page: number;
  pageSize: number;
  pageCount: number;
  pageName: string;
  actions: any;
  saveOrder: number;
  searchText: string;
  isSearch: boolean;
  isValidateError: boolean;
  isReady: boolean;
  isError: boolean;
}

export default class BaseUpload extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataSetFromFile: this.props.dataSet,
      dataSetAll: [],
      dataSet: [],
      dataRow: null,
      header: this.props.model.columnUpload ||  this.props.model.columns,
      page: this.props.page || 1,
      pageSize: this.props.pageSize || 10,
      pageCount: 0,
      pageName: this.props.model.url.replace('/','') + (this.props.id||'') + 'Page',
      actions: [{id: 'upload', caption: 'Upload'}],
      saveOrder: -1,
      searchText: '',
      isSearch: this.props.model.columns?.findIndex((d: { name: string; }) => d.name === (this.props.searchField || 'name'))===1,
      isValidateError: false,
      isReady: false,
      isError: false,
    };
    
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onClickUpload = this.onClickUpload.bind(this);
  }
  
  private historyState: any = this.props.history?.location.state;
  private isLoading: boolean = false;

  private loadDataFromFile (data: any) {
    var isValidateError: boolean = false;
    var dataSet = new Array<typeof this.props.model>();
    (data || []).forEach((dataRow: any, i: number) => {
      dataRow = {...this.props.model.defaultValue(), ...dataRow, ...this.props.defaultValue};
      dataSet.push(new this.props.model(this.props.model.endPointAuth? dataRow : {attributes: dataRow})); 
      var validateError = BaseValidate.validate(dataSet[i], this.props.validations || this.props.model.validations);
      
      if (validateError) {
        dataSet[i]['validate']='error';
        dataSet[i]['error']=validateError[Object.keys(validateError).toString()];
        isValidateError = true;
      }
    });

    this.setState({ dataSetAll: dataSet, isValidateError: isValidateError });
    return dataSet;
  }

  private loadData (data: any, page: number, searchText?: string) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.setState({ isReady: false});
    
    var dataSet = new Array<typeof this.props.model>();
    const pageCount = Math.ceil((data.length||1)/this.state.pageSize);    
    (data || []).slice((page-1)*this.state.pageSize, page*this.state.pageSize).forEach((dataRow: any, i: number) => {
      dataSet.push(dataRow); 
    });
    
    this.setState({ dataSet: dataSet, pageCount: pageCount, isReady: true });
    this.isLoading = false;
  }

  private getDataSet = (data: any) => {
    this.setState({ dataSetFromFile: data})
    data = this.loadDataFromFile(data);
    this.loadData(data, this.state.page);
  }

  private getDataError = async(data: any) => {
    if (data) {
      this.state.dataSetAll[this.state.saveOrder]['validate'] = 'error';
      this.state.dataSetAll[this.state.saveOrder]['error'] = data?.[0];
        
      var saveOrder = this.state.saveOrder+1;
      if (saveOrder >= this.state.dataSetAll.length) saveOrder = -1;
      await this.setState({saveOrder: saveOrder});
      await this.props.getDataError?.(data);
    }
  }

  private onClickUpload() { 
    this.setState({saveOrder: 0});
  }

  private onChangePage (e: React.ChangeEvent<unknown>, value: number) {
    AppService.historyState(this.state.pageName, value);
    this.setState({page: value});
    this.loadData(this.state.dataSetAll, value, this.state.searchText);
  };
  
  private onChangeSearch(fieldName: string, value: string) { 
    this.setState({searchText: value});
    this.loadData(this.state.dataSetAll, this.state.page, value);
  }
  
  private onClickSearch() { 
    this.loadData(this.state.dataSetAll, this.state.page, this.state.searchText);
  }

  private onSave = async(data: any) => {
    const saveData = {...this.state.dataSetFromFile?.[this.state.saveOrder], ...data};
    await this.props.onSave?.(saveData);
    var saveOrder = this.state.saveOrder+1;
    if (saveOrder >= this.state.dataSetAll.length) saveOrder = -1;
    await this.setState({saveOrder: saveOrder});
  }

  private table(){
    return <Table header={this.state.header} dataSet={this.state.dataSet} getDataRow={this.getDataSet} page={this.state.page} pageCount={this.state.pageCount} actions={this.props.disableUploadButton? null : this.state.actions} searchText={this.state.searchText} isValidateError={this.state.isValidateError} isSearch={this.state.isSearch} isLoading={this.isLoading} isReady={this.state.isReady} isError={this.state.isError} onChangePage={this.onChangePage} onChangeSearch={this.onChangeSearch} onClickSearch={this.onClickSearch} onClickUpload={this.onClickUpload} readOnly />
  }
  
  public static uploadImage (refid: number, data: any) {
    if (data.dataRow.id>0)
        ApiService.delete(data.endPointDelete, data.dataRow.id).then(
            (rp) =>  {
                if (rp.Status) {toastr.success('Media deleted.'); }
                else { toastr.error(rp.Messages); }
            }
    );
    
    if (data.dataRow.files) {
        var formData = new FormData();
        formData.append('files', data.dataRow.files);
        formData.append('fileInfo', JSON.stringify(data.dataRow.files.fileInfo || {}));
        formData.append('ref', data.dataRow.ref);
        formData.append('field', data.dataRow.field);
        formData.append('refId', refid.toString());
        
        ApiService.create(data.endPoint, formData).then(
            (rp) =>  {
                if (rp.Status) { toastr.success('Media uploaded.'); return rp.Data.id}
                else { toastr.error(rp.Messages); }
            }
        );
    }
  }

  public componentDidMount() {
    const data = this.loadDataFromFile(this.state.dataSetFromFile)
    var page = this.historyState?.[this.state.pageName] || this.state.page;
    this.loadData(data, page);
    this.setState({page: page})
  }

  public componentDidUpdate() {
    if (this.props.page && (this.props.page !== this.state.page)) {
      this.setState({page: this.props.page})
      this.loadData(this.state.dataSetAll, this.props.page, this.state.searchText);
    }
  }
  
  public render(): React.ReactNode { 
    var dataRow = this.state.dataSetAll?.[this.state.saveOrder];
    
    return (
      <Form history={this.props.history} caption={this.props.caption || 'Upload '+ this.props.model.caption} captionColor={this.props.captionColor} captionThin={this.props.captionThin} buttonCaption=' ' disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>              
        {this.props.children}
        {this.table()}
        <BaseCreate caption=' ' buttonCaption=' '
          endPoint={this.props.endPoint} model={this.props.model} dataRow={dataRow} getDataError={this.getDataError} onSave={this.onSave} save={dataRow? true : false} disableAutoBack disableBackButton />
      </Form> 
    );
  }
}
