import React from 'react';
import * as toastr from 'toastr';
import {Form } from '../../components';
import ApiService from '../../services/api.service';

interface IProps { 
    history?: any;
    id?: number;
    caption?: string;
    captionColor?: string;
    buttonCaption?: string;
    successCaption?: string;
    endPointFilter?: string;
    model: any;
    getDataRow?: (data: any) => void;
    getDataError?: (data: any) => void;
    onDeleteBefore?: () => void;
    onDelete?: (data: any) => void;
    delete?: boolean;
    buttonOnBottom?: boolean;
    disableAutoBack?: boolean;
    disableBackButton?: boolean;
    disableCard?: boolean;
    children?: React.ReactNode;
}

interface IState {
}

export default class BaseDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    private id = () => this.props.id?.toString() || this.props.history?.location.state?.id || '';
    
    private isLoading: boolean = false;
    private isDeleting: boolean = false;
    
    private onDelete = async() => {
        await this.props.onDeleteBefore?.();
        
        ApiService.delete(this.props.model.endPoint+'/', this.id()).then(
            (rp) => {
                if (rp.Status) {                 
                    if (rp.Data) var data = new this.props.model(rp.Data); 
                    this.props.onDelete?.(data);
                    (!this.props.successCaption || this.props.successCaption!==' ') && toastr.success(this.props.successCaption || 'Data deleted.');
                    if (!this.props.disableAutoBack) {this.props.history?.goBack();}
                } 
                else { this.props.getDataError && rp.Errors? this.props.getDataError(rp.Errors) : toastr.error(rp.Messages); }
                this.isDeleting = false;
            }
        );
    }

    public componentDidMount() { 
        if (this.isLoading) return;
        this.isLoading = true;
        
        ApiService.get<typeof this.props.model>(this.props.model.endPoint+(this.props.endPointFilter||'?populate=*&filters[id]='), this.id()).then(
            (rp) => {
                if (rp.Status) { if (rp.Data.length>0) var data = new this.props.model(rp.Data[0]); this.props.getDataRow?.(data);} 
                else { toastr.error(rp.Messages); }
                this.isLoading = false;
            }
        );
    }

    public componentDidUpdate= async() => {
        if (this.props.delete) {
            if (this.isDeleting || this.isLoading) return;
            this.isDeleting = true;
            this.onDelete();
        }
    }

    render() {
        return(
            <Form history={this.props.history} caption={this.props.caption || 'Delete '+ this.props.model.caption} captionColor={this.props.captionColor} buttonCaption={this.props.buttonCaption || 'delete'} buttonColor='error' buttonOnBottom={this.props.buttonOnBottom} onSubmit={this.onDelete} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>              
                {this.props.children}
            </Form>
        );
    }     
}