import * as React from 'react';
import { History } from 'history';
import { CardMedia, Checkbox, Grid, useTheme } from '@mui/material';
import { ActionButton, Button, Card, Chip, Gallery, Icon, InputBulkUpload, InputSearch, Pagination, Spinner, Text } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  history?: History;
  header: any;
  dataSet: Array<any> | any;
  currentId?: any,
  getDataRow?: (data?: any) => void;
  page?:number;
  pageCount?: number; 
  actions?: any;
  searchText?: string;
  isSearch?: boolean;
  isValidateError?: boolean;
  isLoading?: boolean;
  isReady?: boolean;
  isError?: boolean;
  onChangePage?: (e: React.ChangeEvent<unknown>, value: number) => void;
  onChangeSearch?: (fieldName: string, value: string) => void;
  onChangeSwipe?: (e: React.ChangeEvent<unknown>, value: string) => void;
  onClickSearch?: () => void;
  onClickUpload?: () => void;
  onClickCreate?: (data: any) => void;
  onEndTypingSearch?: (fieldName?: string, value?: string) => void;
  cardCount?: number;
  cardBorderWidth?: number;
  cardBorderRadius?: number;
  cardBoxShadow?: number;
  cardPadding?: number;
  cardSpacing?: number;
  cardWidth?: number;
  cardAlign?: 'left'|'center'|'right';
  cardTextAlign?: 'left'|'center'|'right';
  cardNowrap?: boolean;
  cardIconOnly?: boolean;
  cardRightArrow?: boolean;
  swipe?: string;
  autoSelect?: boolean;
  checklistView?: boolean;
  cardView?: boolean;
  readOnly?: boolean;
  disablePaginationLabel?: boolean;
  
}

export const Table: React.FunctionComponent<Props> = (props) => {
  const palette = useTheme().palette;
  const isMobile = AppService.isMobile();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedId, setSelectedId] = React.useState<any>(props.currentId);
  const [hoverId, setHoverId] = React.useState<any>(-1);
  const [checkAll, setCheckAll] = React.useState<boolean>(false);
  const [isUpload, setIsUpload] = React.useState<boolean>(true);
  const [isReadyGallery, setIsReadyGallery] = React.useState<boolean>(false);
  const [cardWidth, setCardWidth] = React.useState(50);
  
  const findId = (id: number) => {
    return selectedId?.findIndex((d: { id: number}) => d.id === id);
  }

  const findAction = (action: string) => {
   return props.actions?.filter((d: any) => d.id === action)?.[0];
  }

  const onClick = (dataRow: any) => (e: React.MouseEvent<HTMLButtonElement>)=>{
    if (props.readOnly) return;
    props.getDataRow?.(dataRow);
    if (!props.actions || props.actions.length>1) setAnchorEl(e.currentTarget);
  };
  
  const onClickCard = (dataRow: any) =>{
    if (!props.readOnly)
    if (props.checklistView) {
      var idx = findId(dataRow.id);
      if (idx<0) selectedId?.unshift(dataRow);
      else selectedId.splice(idx, 1);
      props.getDataRow?.(selectedId);
    }
    else {
      setSelectedId(dataRow.id);
      props.getDataRow?.(dataRow);
    }
  };

  const onClickCheckAll = () =>{
    const selectedId = checkAll? [] : props.dataSet;
    setSelectedId(selectedId);
    props.getDataRow?.(selectedId);
    setCheckAll(!checkAll);
  }
  
  const onClickUpload = (e: React.MouseEvent<HTMLButtonElement>) =>{
    props.onClickUpload?.();
    setIsUpload(false);
  }

  const getDataSetUpload = (dataRow: any) => {
    setIsUpload(true);
    props.getDataRow?.(dataRow)
  }

  const getCardWidth = (value: number) => {
    setCardWidth(value);
  }

  const tableColumn = () => {
    return (
      <tr style={{color: palette.text.primary}}>
        {props.checklistView && <th > {!props.readOnly && props.dataSet.length>0 && <Checkbox sx={{padding: isMobile? 0.6 : 1.1, '& .MuiSvgIcon-root': {fontSize: isMobile? 20: 28}, margin: -0.5, marginBottom: isMobile? -0.5: -1.5}} checked={checkAll} onChange={onClickCheckAll}/>} </th>}
          
        {props.header.map((d: any, i: number) => {
          if (d.caption){
            switch (d.type) {
              case 'number':    
              case 'percentage':       
              case 'money': return <th key={i} className='text-end'> {d.caption}&nbsp;&nbsp;</th>          
              case 'state':
              case 'validate': return <th key={i} className='text-center'>{d.caption}</th>
              default : return <th key={i}>{d.caption}</th>
            }
          }
        })}
      </tr>
    )
  }

  const tableContent = () => {
    if (!props.isReady && !props.dataSet[0])
      return <tr><td className='text-center' colSpan={props.header.length+1}><Spinner/></td></tr>
    
    if (props.isError) 
      return <tr><td className='text-center' colSpan={props.header.length+1}><Chip label='Sorry.. Unable to load data, there is something error.' /></td></tr>

    if (!props.dataSet || props.dataSet?.length===0) 
      return <tr><td className='text-center' colSpan={props.header.length+1}><Chip label='no options' /></td></tr>
    
    return props.dataSet.map((d: any, i: number) => {
      return (
        <tr key={i} style={{color: palette.text.secondary, verticalAlign: 'middle', backgroundColor: d.id>0? (selectedId===d.id? palette.primary.light : hoverId===d.id? palette.background.paper : '') : ''}} onMouseEnter={() => setHoverId(d.id)} onMouseLeave={() => setHoverId(0)} >
          {props.checklistView && <td width={isMobile? 25 : 50} ><Checkbox id={i.toString()} sx={{padding: isMobile? 0.6 : 1.1, '& .MuiSvgIcon-root': {fontSize: isMobile? 20: 28}, margin: -0.5}} checked={findId(d.id)>=0} onChange={()=>onClickCard(d)}/> </td>}
          {props.header.map((d1: any, j: number) => {
            let isTrue = d[d1.name]===d1.default;
            if (j===0 && !props.checklistView && !props.readOnly)
              return <td key={j}><Button id={d?.id || null} sx={{textAlign: 'left', padding: 1, paddingLeft: 1, paddingRight: 1, borderRadius: 1}} onClick={onClick(d)} label={d[d1.name]}/></td>
            else
              switch (d1.type) {
                case 'state': return <td key={j} className='text-center'> <Chip label={d[d1.name]} color={isTrue? 'primary' : 'secondary'} /></td>                  
                case 'number': return <td key={j} className='text-end'> {d[d1.name]}&nbsp;&nbsp;</td> 
                case 'money': return <td key={j} className='text-end'> {AppService.strToMoney(d[d1.name])}&nbsp;&nbsp;</td> 
                case 'percentage': return <td key={j} className='text-end'> {d[d1.name]? d[d1.name]+'%' : ''}&nbsp;&nbsp;</td> 
                case 'date': return <td key={j}> {AppService.dateFormat(d[d1.name],'d mon y')}</td> 
                case 'time': return <td key={j}> {AppService.dateFormat(d[d1.name],'t')}</td> 
                case 'time2': return <td key={j}> {AppService.dateFormat(d[d1.name],'h:n')}</td> 
                case 'datetime': return <td key={j}> {AppService.dateFormat(d[d1.name],'d mon y, h:n')}</td> 
                case 'validate': return <td key={j} className='text-center'> <Chip label={AppService.strCapitalize(d[d1.name]||d1.default)} color={d[d1.name]? 'error' : 'secondary'}/></td>                  
                case 'error': return <td key={j} className='text-danger'> {d[d1.name]}</td> 
                default : return <td key={j}> {d[d1.name]?.length>100? d[d1.name].substring(0,100)+' ...':d[d1.name]} </td>
              }
          })}
        </tr>
      )
    });

  };

  const tableContentCardView = () => {
    if (!props.isReady && !props.dataSet[0])
      return <Spinner/>

    if (props.isError) 
      return <Chip label='Sorry.. Unable to load data, there is something error.' />

    if (!props.dataSet || props.dataSet?.length===0)
      return  <Grid key={0} item xs={12}> <Chip label='no options' /> </Grid>

    return props.dataSet.map((d: any, i: number) =>{
      let popOver: string = '';
      let cardMedia: React.ReactNode = '';
      let cardIcon: React.ReactNode = '';
      let cardContent: React.ReactNode = '';
      let cardSelected = props.checklistView? findId(d.id)>=0 : ((!selectedId) && props.autoSelect && (i===0)) || (selectedId===d.id);
      
      props.header.map((d1: any, j: number) => {
        let isTrue = d[d1.name]===d1.default;
        if (d[d1.name])
        if (d1.type==='state')
          cardContent = <>{cardContent} <Chip key={j} label={d[d1.name]} color={isTrue? 'primary' : 'secondary'} sx={{marginTop: 1, marginBottom: 1}} /> </>            
        else if (d1.type==='caption') {
          cardContent = <>{cardContent} <Grid container direction={props.cardTextAlign === 'center'? 'column':'row'} alignItems='center'>{cardIcon}<Grid item xs md><Text key={j} label={d[d1.name]} lineHeight={1} textAlign={d[d1.name].length===1? 'center' : props.cardTextAlign} bold/></Grid></Grid></>
          popOver = props.cardIconOnly? d[d1.name] : undefined;
        }
        else if (d1.type==='number')
          cardContent = <>{cardContent} <Text key={j} label={d[d1.name]} size='small' color='text.secondary' marginTop={cardContent? 1 : 0} /></>                
        else if (d1.type==='money')
          cardContent = <>{cardContent} <Text key={j} label={AppService.currencySymbol + ' ' + AppService.strToMoney(d[d1.name])} size='small' color='text.secondary' marginTop={cardContent? 1 : 0} > &nbsp;&nbsp;</Text></>                
        else if (d1.type==='percentage')
          cardContent = <>{cardContent} <Text key={j} label={d[d1.name]? d[d1.name]+'%' : ''} size='small' color='text.secondary' marginTop={cardContent? 1 : 0} /></>                
        else if (d1.type==='date')
          cardContent = <>{cardContent} <Text key={j} label={AppService.dateFormat(d[d1.name],'d mon y')} size='small' color='text.secondary' icon='calendar_month' displayBlock marginTop={cardContent? 1 : 0} /></>                
        else if (d1.type==='time')
          cardContent = <>{cardContent} <Text key={j} label={AppService.dateFormat(d[d1.name],'t')} size='small' color='text.secondary' icon='schedule' displayBlock marginTop={cardContent? 1 : 0} /></>                
        else if (d1.type==='time2')
          cardContent = <>{cardContent} <Text key={j} label={AppService.dateFormat(d[d1.name],'h:n')} size='small' color='text.secondary' icon='schedule' displayBlock marginTop={cardContent? 1 : 0} /></>                
        else if (d1.type==='datetime')
          cardContent = <>{cardContent} <Text key={j} label={AppService.dateFormat(d[d1.name],'d mon y, h:n')} size='small' color='text.secondary' icon='calendar_month' displayBlock marginTop={cardContent? 1 : 0} /></>                
        else if (d1.type==='icon')
          cardIcon = <Icon name={d[d1.name]} size={props.cardIconOnly || props.cardCount===1? 'medium' : 'large'} marginRight/>              
        else if (d1.type==='media')
          cardMedia = <CardMedia key={j} image={d[d1.name]} width='100%' height={cardWidth} component='img' sx={{objectFit: 'cover' }}/> 
        else 
          cardContent = <>{cardContent} <Text key={j} label={d[d1.name]?.length>100? d[d1.name].substring(0,100)+' ...':d[d1.name]} size='extrasmall' lineHeight={1} color='text.secondary' textAlign={props.cardTextAlign} marginTop={cardContent? 1 : 0} /></>
      })
      
      if (props.cardRightArrow) 
        cardContent = <Grid container alignItems='center' justifyContent='space-between'><Grid>{cardContent}</Grid><Icon name='navigate_next' /></Grid>
      
      return (
        <Grid key={i} item width={props.cardIconOnly? 80: cardWidth} height={props.cardIconOnly? 80: '100%'} sx={{cursor: 'pointer', pointerEvents: (props.readOnly? 'none' : 'auto')}} onClick={()=>onClickCard(d)}> 
          <Card selected={cardSelected} popOver={popOver} borderWidth={props.cardBorderWidth} borderRadius={props.cardIconOnly? 50: props.cardBorderRadius} boxShadow={props.cardBoxShadow || 1} padding={props.cardPadding} fullHeight media={cardMedia}>
            {props.cardIconOnly? cardIcon : cardContent }
          </Card>
        </Grid>
      )
    });

  };

  const table = () =>{
    const actionCreate = findAction('create');
    const actionUpload = findAction('upload');
    
    return (
      <>
      {((props.pageCount||0)>1 || props.searchText) && props.isSearch &&
        <InputSearch name='searchText' value={props.searchText} onChange={props.onChangeSearch} onClick={props.onClickSearch} onEndTyping={props.onEndTypingSearch} readOnly={props.isLoading}/>
      }
      {actionCreate &&
        <Button variant='outlined' startIcon='add' onClick={()=>{props.onClickCreate?.(actionCreate)}} label='Add New' /> 
      }
      {actionUpload &&
        <InputBulkUpload name='upload' label='Select file' getDataSet={getDataSetUpload} buttonView/>
      }
      
      {props.cardView ?
        <Gallery dataLength={props.dataSet?.length} cardCount={props.cardCount} cardBorderWidth={props.cardBorderWidth} spacing={props.cardSpacing} justifyContent={props.cardIconOnly? 'center' : props.cardAlign} isReady={isReadyGallery} nowrap={props.cardNowrap} swipe={props.swipe} getCardWidth={getCardWidth} onChange={props.onChangeSwipe}>
          {tableContentCardView()}
        </Gallery>
      :
        <Text size='extrasmall'>
          <table className='table table-sm mt-3'><thead>{tableColumn()}</thead><tbody>{tableContent()}</tbody></table>
        </Text>
      }
      </>
    )
  }

  React.useEffect(()=>{ 

    if (!props.checklistView && props.currentId && props.currentId!== +selectedId) setSelectedId(props.currentId);
    if (props.checklistView && props.currentId && props.currentId!==selectedId) setSelectedId(props.currentId);
    if (props.checklistView) setCheckAll(false);
    setIsReadyGallery(!props.isLoading && props.isReady && (props.pageCount||0)>1? true : false)

  }, [props.page, props.isLoading, props.isReady, props.currentId, props.checklistView, selectedId])

  return (
    <>
    {table()}
    
    <Pagination pageCount={props.pageCount} page={props.page} disablePageLabel={props.disablePaginationLabel} disablePageButtons={props.cardIconOnly} onChange={props.onChangePage}/>
    
    {findAction('upload') && !props.isValidateError && props.isReady && isUpload &&
      <Button variant='contained' sx={{marginTop: 2, marginBottom: 3}} startIcon='cloud_upload' onClick={onClickUpload} label='Upload' /> 
    } 
    {props.actions &&
      <ActionButton history={props.history} anchorEl={anchorEl} action= {props.actions} onClose={()=>setAnchorEl(null)} />      
    }
    </>
  )
};
