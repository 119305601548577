import React from 'react';
import { History } from 'history';
import BaseCreate from '../base/base-create.component'; 
import Model from './eventquestion-model';  //editable..


interface IProps { 
    history: History;
    caption?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class EventquestionCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable.. 
            <BaseCreate history={this.props.history} caption={this.props.caption} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} >
                
            </BaseCreate>
        );
    }     
     
}