import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputSelect, InputNumber, InputSwitch } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './region-model';  //editable..

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class RegionCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
     
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        const typeCaption = Model.optionType.filter((d: any) => d.name === this.state.dataRow.type)?.[0]?.caption;

        return (
            //editable..
            <BaseCopy history={this.props.history} caption={'Copy '+typeCaption}
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} readOnly/>
                {this.state.dataRow.type === 'village' &&
                <>
                <InputNumber name='zipcode' label='Zip Code' value={this.state.dataRow.zipcode} error={this.state.dataError?.zipcode} onChange={this.onChange} />
                <Input name='code' label='Region Code' value={this.state.dataRow.code} error={this.state.dataError?.code} onChange={this.onChange} />
                <InputNumber name='latitude' label='Latitude' value={this.state.dataRow.latitude} error={this.state.dataError?.latitude} onChange={this.onChange} />
                <InputNumber name='longitude' label='Longitude' value={this.state.dataRow.longitude} error={this.state.dataError?.longitude} onChange={this.onChange} />
                <InputNumber name='elevation' label='Elevation' value={this.state.dataRow.elevation} error={this.state.dataError?.elevation} onChange={this.onChange} />
                </>
                }
                {this.state.dataRow.parent &&
                <Input name='parentCaption' label='Parent' value={this.state.dataRow.parentCaption} readOnly/>
                }             
            </BaseCopy>
        );
    }
}