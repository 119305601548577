import ApiService from "../../services/api.service";

export default class CivilnumberModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    type: string;
    typeCaption: string;
    seq: number;
    image: any;
    imageId: number;
    imageUrl: string;    
    personalinfo: any;
    personalinfoCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.type = dataRow.attributes.type || 'ktp';
        this.typeCaption = CivilnumberModel.optionType.filter((d: any) => d.name === this.type)?.[0]?.caption || 'KTP';
        this.seq = dataRow.attributes.seq;
        this.personalinfo = dataRow.attributes.personalinfo?.data?.id || null;
        this.personalinfoCaption = dataRow.attributes.personalinfo?.data?.attributes.name || dataRow.attributes.personalinfo?.data?.id; 
        this.image = dataRow.attributes.image?.data?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ((this.image.attributes.url.includes('http')? '' : ApiService.svrUrl)+this.image.attributes.url) : '';
        
    } 

    public static endPoint: string = 'civil-numbers';
    public static endPointMedia: string = 'api::civil-number.civil-number';
    public static endPointFilter: string = '?populate=*&sort=name&filters[personalinfo]=';
    public static url: string = '/civilnumber';
    public static caption: string = 'Civil Number';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',    
        type: 'ktp',
        typeCaption: 'KTP',
        seq: 0,
        personalinfo: null,
        personalinfoCaption: '',
        image: null,
        imageId: 0,
        imageUrl: '',
  
        }
    }
    
    public static columns = [
        { name: 'name', caption: 'Number' },
        { name: 'typeCaption', caption: 'Type' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Number', type: 'caption' },
        { name: 'typeCaption', caption: 'Type' },
    ]

    public static optionType = [
        { name: 'ktp', caption: 'KTP' },
        { name: 'kta', caption: 'KTA' },
        { name: 'sim', caption: 'SIM' },
        { name: 'npwp', caption: 'NPWP' },
    ]
}