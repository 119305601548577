import * as React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Text } from './text';

interface Props {
  id?: string;
  label?: string;
  url?: string;
  logoUrl?: string;
  titleColor?: any;
}

export const Logo : React.FunctionComponent<Props> = (props) => {

  return (
    <Link id={props.id} to={props.url || '/'} replace style={{textDecoration: 'none', color:'inherit'}}>
      <Grid container alignItems='center'>
        <img src={props.logoUrl || (document.querySelector("link[rel~='icon']") as HTMLAnchorElement).href} alt='logo' height={50} width={50}/>
        <Text label={props.label || document.title} color={props.titleColor || 'primary.main'} bold sx={{marginLeft: 2}} />
      </Grid>
    </Link>
  );
};
