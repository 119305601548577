import * as React from 'react';
import { Grid, Rating as RatingMui} from '@mui/material';
import { Icon, Text } from '.';

interface Props {
  id?: string;
  name: string;
  label?: string;
  value: number;
  max: number;
  size?: 'small'|'medium'|'large'|'extralarge';
  sx?: any;
  fullWidth?: boolean;
  selectedOnly?: boolean;
  readOnly?: boolean;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
                    
}

export const Rating: React.FunctionComponent<Props> = (props) => {
  const [value, setValue] = React.useState(0);
  const sxFullWidth = props.fullWidth? {width: '100%', justifyContent: 'space-between'} : {};
  const onChange = (e: React.SyntheticEvent<Element, Event>, newValue: number|null) => { 
    props.onChange?.(props.name, newValue?.toString()||'0');
    setValue(newValue || 0);
  };

  return (
    <Grid id={props.id || props.name}>
      <Text label={props.label} size='extrasmall' color='text.secondary' marginTop={1} sx={{mb: 1}} />
      <RatingMui
        value={value || props.value}
        max={props.max}
        sx={{...props.sx, ...sxFullWidth}}
        icon={<Icon name='star_icon' color='primary.light' size={props.size} />} 
        emptyIcon={<Icon name='star_icon' color='background.paper' size={props.size} />} 
        highlightSelectedOnly={props.selectedOnly}
        readOnly = {props.readOnly}
        onChange={onChange}
      /> 
      <Text label={props.error} size='extrasmall' color='error' />
    </Grid>
  )
};


