import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import CoaList from './coa-list.component';
import CoaCreate from './coa-create.component';
import CoaView from './coa-view.component';
import CoaEdit from './coa-edit.component';
import CoaCopy from './coa-copy.component';
import CoaDelete from './coa-delete.component';

interface Props {
  root?: string;
}

export const CoaRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/coa/create'} component={CoaCreate} />
      <Route path={`${props.root||''}/coa/view`} render={(params)=> <CoaView {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/coa/edit`} render={(params)=> <CoaEdit {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/coa/copy`} component={CoaCopy} />
      <Route path={`${props.root||''}/coa/delete`} render={(params)=> <CoaDelete {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={(props.root||'')+'/coa'} render={(params)=> <CoaList {...params} root={props.root} />} /> 
    </Switch>
  );
};
