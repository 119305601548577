import * as React from 'react';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Grid, IconButton, TextField } from '@mui/material';
import { Icon, Text } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  name: string;
  label?: string;
  placeHolder?: string;
  value: string;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  box?: boolean;
  color?: string;
  readOnly?: boolean;
  clearValue?: boolean;
  disableSecond?: boolean;
  onChange?: (fieldName: string, value: any) => void;
  error?: string;
}

export const InputTime: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const [open, setOpen] = React.useState<boolean>();
  const [value, setValue] = React.useState<any>(null);
  
  const fontSize = () => {
    const sizes = {'extrasmall': 16, 'small': 18, 'medium': 24, 'large': 36, 'extralarge': 48};
    const size = sizes[props.size || 'small'];
    return {xs: size-2, md: size}
  }

  const onClickClear = () => {
    if (isMobile) setOpen(false);
    setValue(null);
    props.onChange?.(props.name, null);
  };

  const onClick = () => {setOpen(true);};
  const onClose = () => {setOpen(undefined)};

  React.useEffect(() => {
    setValue(props.value);
    
  },[props.value])

  return (
    <Grid id={props.id || props.name}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        
        <TimePicker
          value={value}
          inputFormat={'HH:mm' + (props.disableSecond? '' : ':ss')}
          ampm={false}
          closeOnSelect={true}
          disableMaskedInput
          disableOpenPicker = {props.readOnly}
          open={isMobile? (open? true:false) : undefined}
          readOnly={props.readOnly}
          showToolbar={false}
          views={props.disableSecond? ['hours','minutes'] : ['hours','minutes','seconds']}
          componentsProps={{
            actionBar: {actions: ['accept','cancel']},
          }}
          onChange={(newValue) => {
            setValue(newValue || null);
            props.onChange?.(props.name, newValue || null);
          }}     
          onClose={onClose}
          InputProps={{
            startAdornment: !props.readOnly &&
              <>
              {props.value &&
                <IconButton onClick={onClickClear} style={{order: 1, padding: 0, marginBottom: -2}}>
                  <Icon name='clear' size='small' color='primary.main'/>
                </IconButton>
              }
              {isMobile &&
                <IconButton onClick={onClick} style={{order: 1, padding: 0, marginBottom: -2}}>
                  <Icon name='schedule' size='small' color='primary.main'/>
                </IconButton>
              }
              </>
          }}
          InputAdornmentProps={{
            position: 'end',
            sx: {order: 2, marginLeft: 0.2, marginRight: 1.4,
              '.MuiIconButton-root': { padding: 0 },
              '.MuiSvgIcon-root': {margin: 0, color: 'primary.main' },
            }
          }}
          PopperProps={{
            sx: {
              '.MuiPaper-root': { padding: 5 },
              '.MuiPickersDay-dayWithMargin': { }
            },
          }}
          renderInput={(params) => 
            <TextField  {...params}      
              margin='normal'           
              label={props.label}     
              variant={props.box? undefined : 'standard'}
              inputProps={{ ...params.inputProps, readOnly: true }}
              InputProps={{ ...params.InputProps,
                sx: {fontSize: fontSize(), bgcolor: props.color, borderRadius: 3},
              }}
              fullWidth
            /> 
          }
        />
        
      </LocalizationProvider>
      <Text label={props.error} size='extrasmall' color='error' />
    </Grid>
  )
};