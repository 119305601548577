import * as React from 'react';
import { Grid } from '@mui/material';

interface Props {
  id?: string;
  label?: number;
  color?: any;
  colorText?: any;
  size?: number;
}

export const CircleNumber: React.FunctionComponent<Props> = (props) => {
  let color = props.color || 'primary.main';
  let colorText = props.colorText || 'white';
  let size = props.size || 50;
  let sizeMobile = size*80/100;

  return (  
    <Grid container sx={{width: {xs:sizeMobile, md:size}, height: {xs:sizeMobile, md:size}}} bgcolor={color} color={colorText} borderRadius={50} alignItems='center' justifyContent='center' mr={2}> {props.label} </Grid>
  );
};
