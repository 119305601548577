import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputMultiline, InputSelect, Layout, InputFile, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './event-model';  //editable..
import EventtypeModel from '../eventtype/eventtype-model';
import EventsectionChecklist from '../eventsection/eventsection-checklist.component';
import PersonalinfoChecklist from '../personalinfo/personalinfo-checklist.component';
import EventList from './event-list.component';
import EventquestionList from '../eventquestion/eventquestion-list.component';
import ReportList from '../report/report-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    root?: string;
    caption?: string;
    astemplate?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowEventtype: EventtypeModel;
}

export default class EventView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowEventtype: EventtypeModel.defaultValue(),
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowEventtype = (data:any) => {
        this.setState({dataRowEventtype:  data});
    }

    render() {
        return(
            //editable..
            <BaseView history={this.props.history} caption={this.state.dataRow.parent && 'View Event Section'}
                model={Model} getDataRow={this.getDataRow} disableCard>   
                {this.state.dataRow.eventtype &&
                <BaseView id={this.state.dataRow.eventtype} caption='' 
                    model={EventtypeModel} getDataRow={this.getDataRowEventtype}/>
                }

                <Layout history={this.props.history} content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} readOnly />
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                        {this.state.dataRow.parent &&
                        <Input name='parentCaption' label='Parent' value={this.state.dataRow.parentCaption} readOnly/>
                        }
                        {!this.state.dataRow.parent &&
                        <Picklist name='eventtype' label='Type' value={this.state.dataRow.eventtype} caption={this.state.dataRow.eventtypeCaption} readOnly/>
                        }
                        <Picklist name='eventsection' label='Section' value='' caption={this.state.dataRow.eventsectionCaption} readOnly={this.state.dataRow.parent} disableAutoClose>
                            <EventsectionChecklist history={this.props.history} currentId={this.state.dataRow.eventsection} readOnly disableBackButton/>
                        </Picklist>                         
                        {!this.state.dataRow.parent &&
                        <Picklist name='customer' label='Customer' value={this.state.dataRow.customer} caption={this.state.dataRow.customerCaption} readOnly/>
                        }
                        <Picklist name='participant' label={'Participant' + (this.state.dataRow.participantLength===1? ' Name' : 's')} value='' caption={this.state.dataRow.participantCaption} readOnly={this.state.dataRow.parent} disableAutoClose>
                            <PersonalinfoChecklist history={this.props.history} caption='Selected Participant' currentId={this.state.dataRow.participant} readOnly disableBackButton/>
                        </Picklist> 
                        <Input name='title' label='Title' value={this.state.dataRow.title} readOnly/>
                        <InputMultiline name='content' label='Content' value={this.state.dataRow.content} readOnly/>
                        <InputDate name='startdate' label='Start Date' value={this.state.dataRow.startdate} readOnly/>
                        <InputDate name='enddate' label='End Date' value={this.state.dataRow.enddate} readOnly/>
                        <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' readOnly/>                  
                    </>
                    },

                    {caption: 'Sections', child: 
                    this.state.dataRow.id>0 &&
                        <EventList history={this.props.history} root={this.props.root} caption='Event Section' parentId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/>
                    },

                    {caption: 'Questions', child: 
                    this.state.dataRow.id>0 &&
                        <EventquestionList history={this.props.history} root={this.props.root} caption='Question' eventId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/>
                    },

                    {caption: 'Results', hide: this.state.dataRow.id>0 && (!this.state.dataRow.parent || this.state.dataRow.eventsectionId), child:
                    this.state.dataRow.id>0 && this.state.dataRowEventtype.reportgroup>0 &&
                        <ReportList history={this.props.history} root={this.props.root} caption='Event Result' previewId={this.state.dataRow.id} reportgroupId={this.state.dataRowEventtype.reportgroup} actions={['preview']} disableBackButton disableCard/>
                    },
                ]}
                />

            </BaseView>
        )
    }
}