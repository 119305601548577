import * as React from 'react';
import { History } from 'history';
import { Grid } from '@mui/material';
import { Card, Swipe, Tabs } from '.';
import AppService from '../services/app.service';

interface Props {
  history?: History;
  id?: string;
  direction?: 'row'|'row-reverse'|'column'|'column-reverse';
  content: {
    caption: string, 
    hide?: boolean,
    leftPosition?: boolean,
    disableCard?: boolean,
    child?: React.ReactNode,
  }[];
  reverseChild?: boolean;
  fixWidth?: number;
}

export const Layout: React.FunctionComponent<Props> = (props) => {
  const historyState: any = props.history?.location.state;
  const isMobile = AppService.isMobile();
  const isColumn = props.direction ==='column';
  const [isMove, setIsMove] = React.useState(false);
  const content = props.content.filter((d: any) => !d.hide);
  const [tabIdx, setTabIdx] = React.useState(isMobile? ((!props.fixWidth && historyState.tab) || 0) : -1);
  const [fixWidth, setFixWidth] = React.useState(props.fixWidth || 0);
  const getFixWidthLeft = () =>  +(fixWidth / (window.innerWidth/12)).toFixed(2);
  const [fixWidthLeft, setFixWidthLeft] = React.useState(getFixWidthLeft());

  const getTabIndex = (index: number) => {
    setTabIdx(index);
    AppService.historyState('tab', index, true); 
  };
  
  const onChangeSwipe = (e: React.ChangeEvent<unknown>, value: string) => {
    var tabIdxNew = tabIdx;
    
    setIsMove(value.startsWith('move'));
    if (value.startsWith('end') && isMove) {
      if (value.endsWith('left') && tabIdx < content.length-1) tabIdxNew = tabIdx + 1;
      else if (value.endsWith('right') && tabIdx > 0) tabIdxNew = tabIdx - 1;
      if (isMobile && tabIdxNew !== tabIdx) getTabIndex(tabIdxNew);
      setIsMove(false);
    }
  }
  const onResize = React.useCallback((e: UIEvent) => {setFixWidthLeft(getFixWidthLeft())}, []);  

  React.useEffect(() => {
    setFixWidth(props.fixWidth || 0);
    setFixWidthLeft(getFixWidthLeft());
    window.addEventListener('resize', onResize);
    
    return () => {
      window.addEventListener('resize', onResize);
    }
    
  },[props.fixWidth, getFixWidthLeft(), tabIdx])

  return (
    <Swipe onChange={onChangeSwipe}>
      {!fixWidth && tabIdx>=0 && <Tabs id={props.id} value={tabIdx} tab={content} getTabIndex={getTabIndex}/>}
      <Grid container direction={props.direction} columnSpacing={1} height={fixWidth? '100vh' : ''}>
        <Grid item container xs={12} md={fixWidth? fixWidthLeft : 4} spacing={1} alignContent='flex-start' maxHeight={fixWidth? '100vh' : ''} sx={{scrollbarWidth: 'none', overflowY: 'scroll'}}>
          {content?.map((d, i) => {
            if (i===0 && [-1,i].includes(tabIdx) && !d.hide)
            return (
              <Grid key={i} item container minHeight={isMobile? '100vh' : ''} >
                {d.disableCard? d.child : <Card> {d.child} </Card>}
              </Grid>
            )
          })}
        </Grid>
        
        <Grid item container direction={props.reverseChild? 'column-reverse' : 'column'} xs={isColumn? 0: 12} md={isColumn? 0: (fixWidth? 12-fixWidthLeft : 8)} maxHeight={fixWidth? '100vh' : ''} spacing={1} justifyContent='start' sx={{scrollbarWidth: 'none', overflowY: 'scroll'}} >
          {content?.map((d, i) => {
            if (i>0 && [-1,i].includes(tabIdx) && !d.hide)
            return (
              <Grid key={i} item container minHeight={isMobile? '100vh' : ''}>
                {d.disableCard? d.child : <Card> {d.child} </Card>}
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Swipe>
  );
}
