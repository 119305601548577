import * as React from 'react';
import { Chip, Grid, PaginationItem, Pagination as PaginationMui } from '@mui/material';
import { Button, Icon } from '.';

interface Props {
  id?: string;
  page?: number;
  pageCount?: number;
  pageInfoCaption?: string;
  size?: 'small'|'medium'|'large';
  prevCaption?: string;
  nextCaption?: string;
  disablePageLabel?: boolean;
  disablePageButtons?: boolean;

  onChange?: (e: React.ChangeEvent<unknown>, value: number) => void;
}

export const Pagination: React.FunctionComponent<Props> = (props) => {
  const pageCount = props.pageCount || 0;

  return (
    <Grid container alignItems='center' justifyContent={props.disablePageLabel? 'center' : ''} mt={2}>
      {props.pageInfoCaption && pageCount>1 &&
        <Chip label= {props.pageInfoCaption +' '+ props.page+ ' / ' + props.pageCount}/>
      }
      {pageCount>1 &&
      <PaginationMui 
        count={props.pageCount}
        page={props.page}
        color='primary'
        size={props.size}
        hidePrevButton={props.disablePageLabel}
        hideNextButton={props.disablePageLabel}
        onChange={props.onChange}
        renderItem={(item) =>
          <PaginationItem
            {...item}
            page={props.disablePageLabel? '' : item.page}
            component='div'
            components={{
              previous: ()=> props.prevCaption? <Button variant='contained' label={props.prevCaption} startIcon='arrow_back'/>:<Icon name='arrow_back_ios' size='extrasmall'/>,
              next: ()=> props.nextCaption? <Button variant='contained' label={props.nextCaption} endIcon='arrow_forward'/>:<Icon name='arrow_forward_ios' size='extrasmall'/>,
            }}
            sx={{adding: 0, bgcolor: 'primary.light', height: props.disablePageLabel? 7 : -1}}
            hidden={props.disablePageButtons? (props.prevCaption && props.nextCaption? item.disabled : false) || item.type==='page' : false}
          />
        }
      />
      }
    </Grid>
  );
  
};
