import * as React from 'react';
import { InputAdornment, TextField } from '@mui/material';

interface Props {
  id?: string;
  name: string;
  label?: string;
  placeHolder?: string;
  value: string;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  autoSelect?: boolean;
  box?: boolean;
  color?: string;
  readOnly?: boolean;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
}

export const InputColor: React.FunctionComponent<Props> = (props) => {
  const [value, setValue] = React.useState<string>('#34b1fd');

  const fontSize = () => {
    const sizes = {'extrasmall': 16, 'small': 18, 'medium': 24, 'large': 36, 'extralarge': 48};
    const size = sizes[props.size || 'small'];
    return {xs: size-2, md: size}
  }

  const onBlur = (props: Props) => (e: React.FocusEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, value);
  };

  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, e.target.value);
  };
  
  React.useEffect(() => {  
    if (/^#[0-9A-F]{6}$/i.test(props.value)) setValue(props.value);
  },[props.value])

  return (
    <TextField
      id={props.id || props.name}
      name={props.name}
      label={props.label}
      type='input'
      value={props.value || value}
      placeholder={props.placeHolder}
      inputProps={{spellCheck: false}}
      InputProps={{
        readOnly: props.readOnly || false,
        sx: {fontSize: fontSize(), bgcolor: props.color, borderRadius: 3},
        startAdornment: 
          <InputAdornment position='start'>
            <input name={props.name} type='color' value={value} onChange={onChange(props)}/>
          </InputAdornment>,
      }}
      InputLabelProps={{
        sx: {fontSize: fontSize()}
      }}
      margin='normal'
      variant={props.box? undefined : 'standard'}
      fullWidth
      helperText={props.error}
      error= {props.error? true: false}
      onBlur={onBlur(props)}
      onChange={onChange(props)}
    />
  )

  
};