import React from 'react';
import { History } from 'history';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
    successCaption?: string;
    useremail?: string;
    save?: boolean;
    getDataRow?: (data: any) => void;
    getDataError?: (data: any) => void;
}

interface IState {
    dataRow: Model;
    dataError: any;
    save: boolean;
}

export default class UserAuthGetToken extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            save: false,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private getDataError = (data: any) => {
        this.props.getDataError?.(data)
        this.setState({dataError: data});
    }

    private onSave = (data: any) => {
        this.setState({save: false});
        this.props.getDataRow?.(data)
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }
    public componentDidMount(){
        const dataRowTemp: any = Model.defaultValueGetToken;
        this.setState({dataRow: dataRowTemp})
    }

    public componentDidUpdate = async()=>{
        if (this.props.save && this.props.save!==this.state.save && this.props.useremail)
            await this.setState({ dataRow: { ...this.state.dataRow, 'useremail': this.props.useremail, 'email': Model.getEmailApp(this.props.useremail)}, save: true});
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption=' ' buttonCaption=' ' successCaption={this.props.successCaption || 'Token created.'}
                endPoint={Model.endPointAuth+'/get-token'} model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} save={this.state.save} onSave={this.onSave} disableAutoBack disableBackButton/>
        );
    }     
     
}