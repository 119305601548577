import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Text, Themes } from './components';

import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'toastr/build/toastr.min.css'; 
import './App.css'

import Model from './pages/app/app-model'; 
import OwnerModel from './pages/owner/owner-model';  
import UserModel from './pages/user/user-model';
import BaseView from './pages/base/base-view.component'; 
import AppService from './services/app.service';
import ApiService from './services/api.service';
import ApplayoutRoutes from './pages/applayout/applayout-routes.component';

interface IProps { 
}

interface IState {
  dataRow: Model;
  dataError: any;
}


export default class App extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
        dataRow: Model.defaultValue(),
        dataError: null,
    }
  }

  private getDataRow = (data:any) => {
    this.setState({dataRow:  data});
    OwnerModel.setOwner(data?.owner, data?.ownerCaption);
    document.title = data?.name;  
    const favicon = document.querySelector("link[rel~='icon']") as HTMLAnchorElement; 
    if (favicon) favicon.href = data?.imageUrl;
    if (!UserModel.isSignin()) UserModel.removeUser();
  }

  private getDataError = (data: any) => {
    this.setState({dataError: data});
    if (data && !data?.[0]?.includes('Server')) UserModel.removeUser();
  }

  render() {
    return (
      <>
        <BaseView id={+AppService.appId()} caption=' '
          model={Model} getDataRow={this.getDataRow} getDataError={this.getDataError}/>

        {this.state.dataError?.[0] &&  <Text label={this.state.dataError[0] +'. Please reload in a moment.'} color='red' icon='gpp_bad' sx={{padding: 5}}/> }

        {(this.state.dataRow.id>0 || !ApiService.apiUrl) &&
          <Themes colorPrimary={this.state.dataRow.colorprimary} colorSecondary={this.state.dataRow.colorsecondary}>
            <BrowserRouter>
              <Switch>   
                <Route path='*' render={(params)=> <ApplayoutRoutes {...params} />} />
              </Switch>
            </BrowserRouter>
          </Themes>
        }
        </> 
    );
  }
}