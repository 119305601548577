import QuestionModel from '../question/question-model';

export default class EventquestionModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    events: any;
    event: any;
    eventCaption: string;
    eventsection: any;
    questions: any;
    question: any;
    questionCaption: string;
    questionType: string;
    questionTypeCaption: string;
    questionTitle: string;
    questionoption: any;
    questionoptionCaption: string;
    questionoptionLength: number;
    answer: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.events = dataRow.attributes.event?.data || null;
        this.event = this.events?.id || null;
        this.eventCaption = this.events?.[0]?.attributes.name || this.event;
        this.eventsection = this.events?.[0]?.attributes.eventsection?.data?.[0]?.id || null;
        this.questions = dataRow.attributes.question?.data || [];
        this.question = this.questions?.id || null;
        this.questionCaption = this.questions?.attributes.name || this.questions?.id;
        this.questionType = this.questions?.attributes.type || '';
        this.questionTypeCaption = QuestionModel.optionType.filter((d: any) => d.name === this.questionType)?.[0]?.caption|| 'Yes or No';
        this.questionTitle = this.questions?.attributes.title || '';
        this.questionoption = dataRow.attributes.questionoption?.data || [];
        this.questionoptionCaption = '';
        this.questionoptionLength = this.questionoption?.length || 0;
        this.answer = dataRow.attributes.answer;
    } 

    public static endPoint: string = 'event-questions';
    public static endPointFilter: string = '?populate[event][populate]=eventsection&populate[question][populate]=image&populate[questionoption][sort]=id&filters[id]=';
    public static endPointFilter2: string = '?populate=*&sort=name&filters[event]=';
    public static endPointFilter3: string = '?populate=*&sort=name&filters[question]=';
    public static url: string = '/eventquestion';
    public static caption: string = 'Event Question';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active', 
        events: null,
        event: null,
        eventCaption: '',
        eventsection: null,
        questions: null,
        question: null,
        questionCaption: '',
        questionType: '',
        questionTypeCaption: '',
        questionTitle: '',
        questionoption: [],
        questionoptionCaption: '',
        questionoptionLength: 0,
        answer: '',
        }
    }
    
    public static columns = [
        { name: 'questionCaption', caption: 'Name' },
        { name: 'questionTitle', caption: 'Title' },
        { name: 'questionTypeCaption', caption: 'Type' },
    ]

    public static columnPicklistUser = [
        { name: 'questionCaption', caption: 'Question' },
    ]

}