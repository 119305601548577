import React from 'react';
import AppService from '../../services/app.service';

interface IProps {
}

interface IState {
}

export default class BaseValidate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        
    }
    
    public static validate (dataRow: any, validations: any) {
        
        var dataError : any;
        validations?.map((d: any)=>{
            const validationName = d.name;
            const validationNameCapitalize  = AppService.strCapitalize(d.nameAlias || validationName);

            if (!dataError?.[validationName]) {
                if (d.required && ['', null].includes(dataRow[validationName])) {
                    if (!dataError) dataError = {};
                    dataError[validationName] =  validationNameCapitalize+ ' cannot be blank'
                }
                else if (d.minLength && dataRow[validationName]?.length < d.minLength) {
                    if (!dataError) dataError = {};
                    dataError[validationName] = validationNameCapitalize+ ' must be at least '+d.minLength+' characters';
                }
                else if (d.minValue && +dataRow[validationName] < d.minValue) {
                    if (!dataError) dataError = {};
                    dataError[validationName] = validationNameCapitalize+ ' must be at least '+d.minValue;
                }
                else if (d.maxValue && +dataRow[validationName] > d.maxValue) {
                    if (!dataError) dataError = {};
                    dataError[validationName] = validationNameCapitalize+ ' exceeds the maximum value ('+d.maxValue+')';
                }
            }
        })

        return dataError;
    } 
     
    render() {
        return <></>;
    }     
     
}