import * as React from 'react';
import { History } from 'history';
import Model from './menu-model';
import BasePicklistCard from '../base/base-picklistcard.component';
import AppService from '../../services/app.service';

interface IProps {
  history: History;
  root?: string;
  caption?: string;
  captionThin?: boolean;
  currentId?: number;
  menugroupId?: number;
  cardCount?: number;
  cardIconOnly?: boolean;
  autoLoad?: boolean;
  autoSelect?: boolean;
  getDataRow?: (data: any) => void;
}

interface IState {
  dataRow: Model;
  currentId: number;
  historyLengthRoot: number;
  menuPage: number;
}

export default class MenuPicklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { 
      dataRow: Model.defaultValue(),
      currentId : this.props.currentId || 0,
      historyLengthRoot: this.props.history.length,
      menuPage:  1,
    }
    
    this.onChangePage = this.onChangePage.bind(this);
  }

  private historyState: any = this.props.history.location.state;
  private isMobile: boolean = AppService.isMobile();
  private isRootPath = () => this.props.history.location.pathname === this.props.root;
  
  private getDataRow = async (data: any) => {
    var menuUrl = (this.props.root||'') + data.path.split(';')[0];

    this.historyState = AppService.historyState();
    if (!this.props.root || this.isRootPath()) 
      this.props.history.push(menuUrl, this.historyState)
    
    else {
      //Before Go to New Menu Url, Back to Root Page First for Clear The History, 
      var go = this.historyState.historyLength - this.state.historyLengthRoot - 2;
      if (go>0) this.props.history.go(-go);
      setTimeout(() => this.props.history.replace(menuUrl, {...this.historyState, historyLength: this.state.historyLengthRoot+1}), 1);
    }
    
    this.setState({dataRow: data, currentId: data.id})
    this.props.getDataRow?.(data);
  };

  private onChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    AppService.historyState('menuPage', value);
    this.setState({menuPage: value})
  }

  public componentDidMount() {
    if (this.props.root){

      //Back to Previous Page First, When User Hit New Url
      if (!this.isRootPath() && !this.historyState) this.props.history.goBack();

      //Update currentId, menuPage & historyLengthRoot Base on Current History States
      if (this.historyState?.menuId) this.setState({currentId: this.historyState?.menuId})
      if (this.historyState?.menuPage) this.setState({menuPage: this.historyState?.menuPage})
      if (this.historyState?.historyLengthRoot) this.setState({historyLengthRoot: this.historyState?.historyLengthRoot})
    }
  }

  public componentDidUpdate() {
    if (this.props.root && !this.isRootPath()) {
      AppService.historyState('menuId', this.state.currentId, true);
      AppService.historyState('menuPage', this.state.menuPage, true, true);
      AppService.historyState('menugroupId', this.props.menugroupId, true, true);
    }
  }
  
  public render(): React.ReactNode {
    
    return (
      <BasePicklistCard caption={this.props.caption} captionThin={this.props.captionThin} endPointFilter={Model.endPointFilter2(this.props.menugroupId)} model={Model} currentId={ this.isRootPath()? -1 : this.state.currentId} getDataRow={this.getDataRow} page={this.state.menuPage} pageSize={this.isMobile? 6: 10} onChangePage={this.onChangePage} cardCount={this.props.cardCount || 2} cardBoxShadow={5} swipe={this.isMobile? 'vertical-full' : ''} cardIconOnly={this.props.cardIconOnly}  autoLoad={this.isMobile || this.props.autoLoad} autoSelect={this.props.autoSelect} disableBackButton disableCard/>
    );
  }
}
