import * as React from 'react';
import { History } from 'history';
import { Grid } from '@mui/material';
import { Text } from '../../components';
import Model from './productgroup-model';
import BaseList from '../base/base-list.component';
import ProductPicklistCard from '../product/product-picklistcard.component';
import AppService from '../../services/app.service';

interface IProps {  
  history: History;
  parentId?: number;
  getDataRow?: (data: any) => void;
}

interface IState {
  dataSet: Array<Model>;
}

export default class ProductgroupListProduct extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    
    this.state = {
      dataSet: [],
    }
  }

  private isMobile: boolean = AppService.isMobile();

  private getDataSet = (data: any) => {
    this.setState({ dataSet: data});
  };

  public render(): React.ReactNode {
    return (
      <>
                      
      <BaseList history={this.props.history} caption=' ' endPointFilter={Model.endPointFilter(this.props.parentId)} model={Model} getDataSet={this.getDataSet} disableView/>

      {this.state.dataSet?.map((d: any, i: number) => {
        return ( 
          <Grid key={i}>
            {d.productgroupLength>0?
            <>
              <Grid sx={{...AppService.background({svgName: 'blob', size: this.isMobile? 10 : 7, position: 'left'}) }} p={this.isMobile? 0 : 3} pl={0} mt={5}>
                <Text label={d.description} color='primary.main' bold/>
              </Grid>
              <ProductgroupListProduct history={this.props.history} parentId={d.id} getDataRow={this.props.getDataRow}/>
            </>
            :
            <ProductPicklistCard history={this.props.history} caption={d.description} pricetype='pricesale' productgroupId={d.id} cardCount={5} swipe='horizontal' getDataRow={this.props.getDataRow} disableSearch/>
            }
          </Grid>
        )
      })}    
      </>
    );
  }
}
