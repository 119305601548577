import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputNumber, InputSelect, InputSwitch, Picklist } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './address-model';  //editable..

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class AddressDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} readOnly/>
                <InputMultiline name='street' label='Street, RT, RW, No.' value={this.state.dataRow.street} readOnly/>
                <Picklist name='region' label='Kel/Desa, Kec, Kota, Provinsi' value={this.state.dataRow.region} caption={this.state.dataRow.regionCaption} readOnly/>
                <Input name='map' label='Map' value={this.state.dataRow.map} readOnly/>
                <InputNumber name='seq' label='Sequence' value={this.state.dataRow.seq} readOnly/>
                 
            </BaseDelete>
        );
    }
}