import * as React from 'react';
import { History } from 'history';
import {AppBar as AppBarMui, Badge, Grid, IconButton, Toolbar, Typography} from '@mui/material';
import { Icon, InputFile, Text, Transition } from '.';
import AppService from '../services/app.service';

interface Props {
  history?: History;
  color?: any;
  logo: React.ReactNode;
  avatar?:{
    name?: string,
    imageUrl?: string,
    onClick?: () => void,
  }
  menu?: {
    caption: string, 
    url?: string,
    section?: string,
    icon?: string,
    badge?: number,
    readOnly?: boolean,
  }[];
  onClick?: () => void;
  onScrollEnd?: () => void;
  menuColor?: any;
  window?: () => Window;
  children: React.ReactNode;
}


export const AppBar: React.FunctionComponent<Props> = (props) => {
  const [isMobile, setIsMobile] = React.useState(AppService.isMobile());
  const [isOnTop, setIsOnTop] = React.useState(true);
  const menuColor = props.menuColor || 'text.primary';
  const isWebSignin = !isMobile && props.avatar?.name;

  const logo = () => {
    return (
      <Grid sx={{flexGrow: 1}}> {props.logo} </Grid>
    )
  }

  const menu = () => {
    if (props.menu)
    return (
      <>
      {props.menu?.map((d, i) => {
        if (d.caption!=='')
        return (
          <Grid key={i} sx={{cursor: 'pointer', pointerEvents: (d.readOnly? 'none' : 'auto')}} onClick={()=>onClick(d)}>
            <Badge color='warning' badgeContent={d.badge && <Text label={d.badge?.toString()} color='background.default' size='supersmall'/>} anchorOrigin={{vertical: 'top', horizontal: 'left'}} sx={{marginLeft: 1}}>
              <Text label={d.caption} size={isMobile? 'extrasmall' : 'medium'} lineHeight={1} color={menuColor} textAlign='center' icon={d.icon} iconSize='small' sx={{marginRight: 3}}/>
            </Badge>
          </Grid>
        )
      })}
      </>
    );
  }

  const avatar = () => {
    if (props.avatar)
    return (
      <Grid>
      { isWebSignin && 
      <Typography variant='overline' color={props.color} bgcolor={menuColor} borderRadius={20} padding={2} paddingRight={6} marginRight={-6}>
        {props.avatar.name}
      </Typography>
      }
      <IconButton size='large' onClick={props.avatar.onClick} sx={{padding:0}}>
        {props.avatar.imageUrl? <InputFile name='imageUrl' value={props.avatar.imageUrl ||''} type='image' maxHeight={50} circle readOnly/>
        : <Icon name='account_circle' size='large' color={isWebSignin? props.color : menuColor}/>
        }
      </IconButton>
      </Grid>
    )
  }
  
  const onClick = async (menu: any) => {
    await props.onClick?.();
    if (menu.section) {
      document.getElementById(menu.section)?.scrollIntoView(true);
      await props.onScrollEnd?.();
    }
    if (menu.url) props.history?.push(menu.url);
  };

  const onResize = React.useCallback((e: UIEvent) => {setIsMobile(AppService.isMobile())}, []);  
  const onScroll = React.useCallback((e: Event) => {setIsOnTop(window.scrollY<100)}, []);  

  React.useEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', onScroll);

    return () => {
      window.addEventListener('resize', onResize);
      window.addEventListener('scroll', onScroll);
    }
    
  },[onResize, onScroll])

  return (
    <>
    
    {isMobile?
    <>
      <Transition variant='hideOnScroll' >
        <AppBarMui sx={{ top: 'auto', bottom: 0}}>
          <Toolbar sx={{backgroundColor : props.color, justifyContent:'space-around', padding: 1}}>
            {menu()}
            {avatar()}
          </Toolbar>
        </AppBarMui>
      </Transition>
      <Toolbar sx={{backgroundColor : props.color || 'primary.light', padding:1}}> {logo()} </Toolbar>
    </>

    : //Web view
    <>
      <Transition variant='hideOnScroll' direction='down'>
        <AppBarMui elevation={isOnTop? 0:4} sx={{background : isOnTop? 'transparent' : ''}}>
          <Toolbar sx={{backgroundColor : isOnTop? '' : props.color}}>
            {logo()}
            {menu()}
            {avatar()}
          </Toolbar>
        </AppBarMui>
      </Transition>
    </>
    }
    
     {props.children}
    </>
  );
}