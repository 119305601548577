import React from 'react';
import { History } from 'history';
import { Grid } from '@mui/material';
import { Button, InputMoney, InputNumber } from '../../components';
import BaseCreateEdit from '../base/base-create-edit.component';
import BaseView from '../base/base-view.component';
import BaseList from '../base/base-list.component';
import Model from './transactiondetail-model';  //editable..
import UserModel from '../user/user-model';
import ProductModel from '../product/product-model';
import TransactionModel from '../transaction/transaction-model';
import TransactionCreate from '../transaction/transaction-create.component';
                
interface IProps { 
    history: History;
    productId: number;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowTransaction: any;
    save?: boolean;
}

export default class TransactiondetailCreateByUser extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowTransaction: null,
            save: false,
        }
        
        this.onChange = this.onChange.bind(this);
    }
    
    private validations : any = Model.validations;

    private getDataRow = (data:any) => {
        if (data) this.setState({ dataRow: data});
    }
    
    private getDataRowUser = (data:any) => {
        if (this.state.dataRowTransaction?.id===0)
            this.setState({ dataRowTransaction: { ...this.state.dataRowTransaction, 'customer': data?.personalinfo} });
    }
    
    private getDataRowProduct = (data: any) => {
        const price = this.state.dataRow.transactiontypePricetype==='pricesale'? data?.pricesale : data?.pricebase;
        this.setState({ dataRow: { ...this.state.dataRow, 'product': data?.id || null, 'productCaption': data?.name || '', 
            'price': price, 'value': price * this.state.dataRow.qty, 'productLength': data?.product.length },
        });
        if (this.state.dataRowTransaction?.id===0)
            this.setState({ dataRowTransaction: { ...this.state.dataRowTransaction, 'name': data?.name, 'description': data?.name} });
        this.validations.splice(1, 1, { name: 'qty', maxValue: data?.qty });
    };

    private getDataSetTransaction = (data:any) => {
        this.setState({ 
            dataRowTransaction: data?.[0] || TransactionModel.defaultValue(),
            dataRow: { ...this.state.dataRow, 'transaction': data[0]?.id}
        });
    }

    private getDataError = (data: any) => {
        this.setState({ dataError: data});
    };

    private onClickButton = () => {
        this.setState({ save: true});
    };

    private OnSave = (data: any) => {
        if (this.state.save) {
            this.setState({ save: false});
            this.props.history.push('/transactionsales/edit', {id: this.state.dataRow.transaction});
        }
    };
    
    private OnSaveTransaction = (data: any) => {
        this.getDataSetTransaction([data])
    };

    private onChange(fieldName: string, value: string) { 
        var  valueField = this.state.dataRow.value;
        if (fieldName==='price') valueField= this.state.dataRow.qty * Number(value)
        else if (fieldName==='qty') valueField= this.state.dataRow.price * Number(value)

        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, value: valueField,
            }
        };
        this.setState(nextState);
    }
    
    render() {
        
        return (
            //editable..
            <Grid sx={{justifyContent: {xs: 'center', md: 'left'}}}>

            <BaseList history={this.props.history} caption=' ' 
                endPointFilter={TransactionModel.endPointFilter2()} model={TransactionModel} getDataSet={this.getDataSetTransaction} disableView/>
            
            <BaseView id={+UserModel.getUser()} caption='' 
                model={UserModel} getDataRow={this.getDataRowUser}/>

            <BaseView id={this.props.productId} caption=''
                model={ProductModel} getDataRow={this.getDataRowProduct}/>
            
            {this.state.dataRowTransaction?.id === 0 && this.state.dataRowTransaction?.customer>0 && this.state.dataRowTransaction?.name && !this.state.dataRowTransaction?.transactiontype &&
            <TransactionCreate history={this.props.history}  caption=' ' transactiontypeType='sales' dataRow={this.state.dataRowTransaction} getDataError={this.getDataError} save onSave={this.OnSaveTransaction} disableView />
            }

            {this.state.dataRowTransaction?.id>0 && this.props.productId>0 &&
            <BaseCreateEdit id={this.props.productId} caption=' ' buttonCaption='Masukan keranjang'
                model={Model} endPointFilter={Model.endPointFilter+this.state.dataRowTransaction.id+'&filters[product]='} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError} validations={this.validations} 
                save={this.state.save} delete={+this.state.dataRow.qty===0} onSave={this.OnSave} buttonOnBottom disableAutoBack disableBackButton >
                
                <InputNumber name='qty' label='Jumlah pesanan' value={this.state.dataRow.qty} size='medium' box error={this.state.dataError?.qty} onChange={this.onChange} />
                <InputMoney name='value' label='Sub total' value={this.state.dataRow.price * this.state.dataRow.qty} size='medium' error={this.state.dataError?.value} onChange={this.onChange} readOnly/>
                <Button variant='contained' sx={{ marginTop: 3, marginRight: 1, margindisplay: 'inline'}} onClick={this.onClickButton} label='Pesan Sekarang' />    
        
            </BaseCreateEdit>
            }

            </Grid>
            
        );
    }     
     
}