import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputEmail, InputFile, InputMultiline, InputSelect, Layout, InputSwitch, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './personalinfo-model';  //editable..
import AddressList from '../address/address-list.component';
import CivilnumberList from '../civilnumber/civilnumber-list.component';

interface IProps { 
    history: History;
    root?: string;
    id?: number;
    caption?: string;
    disableBackButton?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class PersonalinfoViewByuser extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} id={this.props.id} caption={this.props.caption} 
                model={Model} getDataRow={this.getDataRow} disableBackButton disableCard>              
                
                <Layout history={this.props.history} direction='column' content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                        <Picklist name='personalinfotype' label='Type' value={this.state.dataRow.personalinfotype} caption={this.state.dataRow.personalinfotypeCaption} readOnly/>
                        <Input name='phone' label='Phone' value={this.state.dataRow.phone} readOnly/>
                        <InputEmail name='email' label='Email' value={this.state.dataRow.email} readOnly/>
                        <Picklist name='birthplace' label='Place of Birth' value={this.state.dataRow.birthplace} caption={this.state.dataRow.birthplaceCaption} readOnly/>
                        <InputDate name='birthdate' label='Date of Birth' value={this.state.dataRow.birthdate} disableTime readOnly/>
                        <InputSelect name='gender' label='Gender' value={this.state.dataRow.gender} options={Model.optionGender} readOnly/>
                        <InputFile name='imageUrl' label='Photo' value={this.state.dataRow.imageUrl} type='image' readOnly/>
                    </>
                    },

                    {caption: 'Addresses', child: 
                    this.state.dataRow.id>0 &&
                        <AddressList history={this.props.history} root={this.props.root} personalinfoId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/>
                    },

                    {caption: 'Civil Numbers', child: 
                    this.state.dataRow.id>0 &&
                        <CivilnumberList history={this.props.history} root={this.props.root} personalinfoId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/>
                    },

                    ]}
                />
            
            </BaseView>
        );
    }
}