import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputFile, InputMultiline, InputSelect, InputSwitch, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './message-model';  //editable..
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    caption?: string;
    astemplate?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class MessageView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    render() {
        return (
            //editable..
            <BaseView history={this.props.history} caption={this.props.caption} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} readOnly/>
                }
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} readOnly/>
                {this.props.astemplate!=='yes' &&
                <InputSwitch name='sent' label='Sent' value={this.state.dataRow.sent} defaultTrue='yes' defaultFalse='no' readOnly/>
                }
                {this.state.dataRow.type==='email'?
                <>
                <InputEmail name='to' label='To' value={this.state.dataRow.to} readOnly/>
                <InputEmail name='cc' label='Cc' value={this.state.dataRow.cc} readOnly/>
                <InputEmail name='bcc' label='Bcc' value={this.state.dataRow.bcc} readOnly/>
                <InputEmail name='replyto' label='Reply to' value={this.state.dataRow.replyto} readOnly/>
                </>
                : 
                <Input name='to' label='To' value={this.state.dataRow.to} readOnly/>
                }
                <Input name='subject' label='Subject' value={this.state.dataRow.subject} readOnly/>
                <InputMultiline name='text' label='Text' value={this.state.dataRow.text} readOnly/>
                <InputFile name='attachmentUrl' label='Attachment' value={this.state.dataRow.attachmentUrl} readOnly/>
           
            </BaseView>
        );
    }
}