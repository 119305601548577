import * as React from 'react';
import { Grid } from '@mui/material';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  factor?: number;
  height?: number;
  background?: any;
  backgroundElement?: React.ReactNode;
  children?: React.ReactNode;
}

export const ParallaxScroll: React.FunctionComponent<Props> = (props) => {;
  const [scrollTop, setScrollTop] = React.useState(0);
  
  const onScroll = React.useCallback((e: Event) => { 
    setScrollTop(window.scrollY * (props.factor || 0.2) || 0);
  }, [props.factor]);

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, )
  
  return (
    <Grid id={props.id} sx={{...AppService.background({...props.background}), transform: `translateY(${scrollTop}px)`, height: '100%'}}>
      <Grid position='relative' top={scrollTop} sx={{zIndex: 1}}>
        {props.backgroundElement}
      </Grid>
      <Grid position='relative' container sx={{zIndex: 2}}>
        {props.children}
      </Grid>
    </Grid>
  )
};
