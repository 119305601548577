import React from 'react';
import { History } from 'history';
import { Input, InputNumber, InputMultiline, InputFile, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './questionoption-model';  //editable..
import EventresultChecklist from '../eventresult/eventresult-checklist.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class QuestionoptionView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                <Input name='title' label='Title' value={this.state.dataRow.title} readOnly/>
                <InputMultiline name='content' label='Content' value={this.state.dataRow.content} readOnly/>
                <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' readOnly/>
                <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} readOnly/>
                <Input name='answer' label='Answer' value={this.state.dataRow.answer} readOnly/>
                <InputNumber name='value' label='Value' value={this.state.dataRow.value} readOnly/>
                <Picklist name='eventresult' label='Event Result List' value='' caption={this.state.dataRow.eventresultCaption} readOnly={this.state.dataRow.eventresult? false: true} disableAutoClose>
                    <EventresultChecklist history={this.props.history} currentId={this.state.dataRow.eventresult} readOnly disableBackButton/>
                </Picklist>                                   
            </BaseView>
        );
    }
}