import * as React from 'react';
import { History } from 'history';
import Model from './user-model';
import BaseChecklist from '../base/base-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
  history: History;
  caption?: string;
  getDataRow?: (data: any) => void;
  currentId: any;
  readOnly?: boolean;
  selectedOnly?: boolean;
  disableBackButton? : boolean;
}

interface IState {
}

export default class UserChecklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }



  public render(): React.ReactNode {
    return (
      <BaseChecklist history={this.props.history} caption={this.props.caption} endPointFilter={AppService.isAppFullVersion()?'':Model.endPointFilter()} model={Model} currentId={this.props.currentId} getDataRow={this.props.getDataRow} searchField='name' readOnly={this.props.readOnly} selectedOnly={this.props.selectedOnly} disableBackButton={this.props.disableBackButton}/>
    );
  }
}
