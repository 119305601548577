import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputNumber, InputSwitch, Picklist } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './productgroup-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import ProductChecklist from '../product/product-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class ProductgroupCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataRowProductgroup = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'productgroup': data || [], 'productgroupCaption': data?.length>0? (data[0].name)+ (data.length>1? ', ...': '') : ''}  });
    };
    
    private getDataRowProduct = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'product': data || [], 'productCaption': data?.length>0? (data[0].name)+ (data.length>1? ', ...': '') : ''}  });
    };
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <InputSwitch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                }
                <InputNumber name='seq' label='Sequence' value={this.state.dataRow.seq} error={this.state.dataError?.seq} onChange={this.onChange} />
                <Picklist name='productgroup' label='Sub Product Group List' value={this.state.dataRow.productgroup} caption={this.state.dataRow.productgroupCaption} disableAutoClose clearValue={this.getDataRowProductgroup} error={this.state.dataError?.productgroup} onChange={this.onChange}>
                    <ProductChecklist history={this.props.history} currentId={this.state.dataRow.productgroup} getDataRow={this.getDataRowProductgroup} disableBackButton/>
                </Picklist> 
                <Picklist name='product' label='Product' value={this.state.dataRow.product} caption={this.state.dataRow.productCaption} disableAutoClose clearValue={this.getDataRowProduct} error={this.state.dataError?.product} onChange={this.onChange}>
                    <ProductChecklist history={this.props.history} currentId={this.state.dataRow.product} getDataRow={this.getDataRowProduct} disableBackButton/>
                </Picklist> 

            </BaseCopy>
        );
    }
}