import * as React from 'react';
import { History } from 'history';
import Model from './owner-model';
import BaseChecklist from '../base/base-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
  history: History;
  readOnly?: boolean
  disableBackButton? : boolean
  currentId: any;
  getDataRow?: (data: any) => void;
}

interface IState {
}

export default class OwnerChecklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }



  public render(): React.ReactNode {
    return (
      <BaseChecklist history={this.props.history} model={Model} endPointFilter={AppService.isAppFullVersion()?'':Model.endPointFilter2()} currentId={this.props.currentId} getDataRow={this.props.getDataRow} readOnly={this.props.readOnly} disableBackButton={this.props.disableBackButton}/>
    );
  }
}
